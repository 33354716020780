export class CommonHelper {
  public static replaceCommawithDot(str:any) : any {
    if (str && typeof str === 'string' && str.indexOf(',') !== -1) {
      return Number(str.replace(',', '.'));
    }
    return str;
  }

  public static convertStringQuantityToNumber(input: string) : any {
    if (input) {
      return input === '' ? null : Number(this.replaceCommawithDot(input));
    }
  }

  public static getValidUrl(url:string):string {
    if (url) {
      return url.replace('%', '%25');
    }
  }

  public static arraysExactMatch<T>(arr1: T[], arr2: T[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = 0; i < arr1.length; i++) {
      if (!arr2.includes(arr1[i])) {
        return false;
      }
    }
    return true;
  }

  public static invertColor(rgb) {
    rgb = [].slice.call(arguments).join(',').replace(/rgb\(|\)|rgba\(|\)|\s/gi, '').split(',');
    for (let i = 0; i < rgb.length; i++) rgb[i] = (i === 3 ? 1 : 255) - rgb[i];
    return 'rgba(' + rgb[0] + ',' + rgb[1] + ',' + rgb[2] + ',1)';
  }

  public static isNumber = (n: any): boolean =>
    !isNaN(parseFloat(String(n))) && isFinite(Number(n));

  public static cropText(text: string, maxWidth: number): string {
    maxWidth = Math.abs(maxWidth);
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    context.font = '14px Arial';
    while (context.measureText(text).width > maxWidth) {
      text = text.slice(0, -1);
    }
    return text;
  }
}
