import { Component, Input, ViewChild, OnDestroy, HostListener, OnInit } from '@angular/core';
import { process, State, GroupDescriptor, DataResult } from '@progress/kendo-data-query';
import { SelectAllCheckboxState, PageSizeItem, CancelEvent } from '@progress/kendo-angular-grid';
import { AutoCompleteComponent } from '@progress/kendo-angular-dropdowns';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DialogCloseResult, DialogService } from '@progress/kendo-angular-dialog';
import { getDemmandTypeValues } from '@app/core/models/aot-demand-type.enum';
import { Demandline } from '@app/core/models/demand-line.model';
import { DemandSpecificationService } from '@app/core/services/http-services/aot/demand-specification.service';
import { UpdateSumUIQtyDTO, DeamndService, IDemandInputForActivitySelection } from '@app/core/services/http-services/gluelam/demand.service';
import { EnumToObject } from '@app/shared/helpers/transform-enum-to-object.helper';
import { AotDemandBulkUpdateComponent } from '../aot-demand-bulk-update/aot-demand-bulk-update.component';
import { IGlulamSequenceResult } from '@app/core/models/glulamsequenceDTO';
import { SVGIcon, warningTriangleIcon, fileExcelIcon } from '@progress/kendo-svg-icons';
import { getPlanningTypeValues } from '@app/core/models/aot-planning-type.enum';
import { BeamMaterialTypeService } from '@app/core/services/http-services/gluelam/beam-material-type.service';
import { BeamMaterialType } from '@app/core/models/beam-material-type-enum';
import { BeamMaterialTypeEnumHelper } from '@app/shared/helpers/beam-material-type-enum-helper';
import { IDemand, IDemandDTO } from '@app/core/models/demand-model';

marker('OrderViewTranslation.BEAM_MAIN_PRODUCT');
marker('OrderViewTranslation.BEAM_QUALITY');
marker('OrderViewTranslation.BEAM_PROFILE');
marker('OrderViewTranslation.BEAM_PLANED_PROD_DATE');
marker('OrderViewTranslation.Show_all');
marker('OrderViewTranslation.Material');
marker('Operative.AddAndGenerateSequenceErrorMsg');
marker('Operative.AutoGeneratePOForDemandsErrorMsg');
marker('Operative.AutoGeneratePOForDemandsInActiveErrorMsg');
marker('Operative.AutoGeneratePOForDemandsPOAlreadyExistErrorMsg');
marker('Operative.DemandProdOrderSequenceInProgress');

@Component({
  selector: 'app-demand-report',
  templateUrl: './aot-demand.component.html',
  styleUrls: ['./aot-demand.component.css'],
  providers: [
    EnumToObject
  ]
})
export class AotDemandComponent implements OnDestroy, OnInit {
  selectDimensionValue: number;
  selectRowCount = 0;
  groups: GroupDescriptor[];
  saveInProgress = false;
  interval;
  showPlanned = true;
  showOnlyWithNoGluPlan = true;
  removeDemandInProgress = false;
  currentSelectedRows: Demandline[];
  beamOrderRowIds: number[] = [];
  loading = true;
  beamOrderLinesGridData: DataResult;
  beamOrderLines: Demandline[];
  lastUpdated: Date;
  demandTypes = getDemmandTypeValues();
  planningTypes = getPlanningTypeValues();
  beamMaterialTypes: { key: BeamMaterialType, text: string }[];
  selectedIDs = [];
  selectAllState: SelectAllCheckboxState = 'unchecked';

  state: State = {
    skip: 0,
    take: 50,
    filter: {
      logic: 'and',
      filters: [
        { field: 'isActive', value: true, operator: 'eq' }
      ]
    },
    sort: [{
      field: 'latestProductionDate',
      dir: 'asc'
    }]
  };

  pageSizes: PageSizeItem[] = [
    { text: '50', value: 50 },
    { text: '100', value: 100 },
    { text: '150', value: 150 },
    { text: '200', value: 200 },
    { text: 'All', value: 0 }
  ];
  //Icons
  warningTriangleIcon: SVGIcon = warningTriangleIcon;
  fileExcelIcon: SVGIcon = fileExcelIcon

  @Input() private readonly uiQty: UpdateSumUIQtyDTO[] = [];

  @ViewChild('autocomplete', { static: false })
  autocomplete: AutoCompleteComponent;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly beamOrderRowService: DeamndService,
    private readonly notificationService: AppNotificationService,
    private readonly dialogService: DialogService,
    private readonly demandSpecificationService: DemandSpecificationService,
    ) { }

  async ngOnInit() {
    this.beamOrderRowService.getBeamOrderRows.pipe(takeUntil(this.destroy$)).subscribe(d => this.onRowsUppdated(d));
    this.beamMaterialTypes = BeamMaterialTypeEnumHelper.getBeamMaterialTypeEnumTranslations();
    this.loadDemands();
  }

  loadDemands() {
    this.beamOrderRowService.query(this.state, this.showPlanned, this.showOnlyWithNoGluPlan);
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: FocusEvent): void {
    event.stopPropagation();
    event.preventDefault();
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    clearInterval(this.interval);
    event.stopPropagation();
    event.preventDefault();
  }

  createPO() {
    if (this.validate()) {
      const demandInputForActivitySelection: IDemandInputForActivitySelection[] = [];

      this.beamOrderRowIds.forEach((id, index) => {
        const demand = this.beamOrderLinesGridData.data.find(y => y.BeamOrderRowID === id);
        const d: IDemandInputForActivitySelection = {
          beamOrderRowID: demand.BeamOrderRowID,
          orderId: demand.orderID,
          orderRowId: demand.orderRowID,
          materialIndex: demand.materialIndex,
          currentPosition: (index + 1),
          totalCount: this.beamOrderRowIds.length
        };
        demandInputForActivitySelection.push(d);
      });

      demandInputForActivitySelection.reverse().forEach(input => {

        const dr = this.beamOrderRowService.openDialogForGlulamSeq(input);

        dr.result.pipe(takeUntil(this.destroy$)).subscribe((data: IGlulamSequenceResult) => {
          this.beamOrderRowService.handlePopupResult(data, dr, input, null);
        });
      });
    }
  }

  loadData(demands: Demandline[]) {
    demands.forEach(d => {
      d.QtySumUI = 0;
    });

    this.uiQty.forEach(d => {
      const i = demands.find(k => k.BeamOrderRowID === d.demandId && k.transportPackageId === d.transportPackageId);
      if (i) {
        i.QtySumUI = d.newQty;
      } else {
        console.warn(`Row Not Found in Gridd ${d.demandId} + ${d.transportPackageId}`);
      }
    });

    this.bindDataToGrid(demands);
  }

  dataStateChange(state): void {
    this.selectedIDs = [];
    this.beamOrderRowIds = [];
    this.state = state;
    if (this.state.take === 0) {
      this.state.take = this.beamOrderLinesGridData.total;
    }
    this.loading = true;
    this.beamOrderRowService.query(this.state, this.showPlanned, this.showOnlyWithNoGluPlan).add(() => {
      this.loading = this.beamOrderRowService.loading;
    });
  }


  onNewDemand() {
    const dr = this.beamOrderRowService.openDialog(<IDemand>{ demandID: -1 });

    dr.result.pipe(takeUntil(this.destroy$)).subscribe((d) => {
      if (d instanceof DialogCloseResult) {
        dr.close();
      } else if (d) {
        this.loadDemands();
      }
    });
  }

  onCancle(e: CancelEvent) {
  }

  bulkEdit() {
    if (this.beamOrderRowIds.length > 0) {
      return new Promise((resolve) => {
        const dialogRef = this.dialogService.open({
          content: AotDemandBulkUpdateComponent,
          width: '500px',
          height: 'auto'
        });

        const content = dialogRef.content.instance as AotDemandBulkUpdateComponent;
        content.data = { input: this.beamOrderRowIds };

        dialogRef.result.pipe(takeUntil(this.destroy$)).subscribe((d) => {
          if (d) {
            this.ngOnInit();
            resolve(true);
          }
        });
      });
    }
  }

  getDeamndTypeText(id: number): string {
    return this.demandTypes.find(d => d.value === id)?.text;
  }

  getPlanningType(id: number): string {
    return this.planningTypes.find(d => d.value === id)?.text;
  }

  addOrViewSpecification(selectedDemand: Demandline): void {
    const dr = this.demandSpecificationService.openDialog(selectedDemand);

    dr.result.pipe(takeUntil(this.destroy$)).subscribe((response: IDemandDTO) => {
      if (response instanceof DialogCloseResult) {
        dr.close();
      } else if (response) {
        this.beamOrderLinesGridData.data
          .filter((filteredlines) => filteredlines.BeamOrderRowID === selectedDemand.BeamOrderRowID)
          .forEach((demand) => {
            this.beamOrderRowService.setDemandFields(demand, response);
          });
      }
    });
  }

  getBeamMaterialType(externalId: number): string {
    return this.beamMaterialTypes?.find(d => d.key === externalId).text;
  }

  private onRowsUppdated(demands: Demandline[]) {
    if (demands.length > 0) {
      this.loading = true;
      this.loadData(demands);
      this.lastUpdated = new Date();
      const rows = this.beamOrderLines.filter(
        bol => this.selectedIDs.find(id => bol.rowId === id)
      );
      this.selectRowCount = rows.length;
      this.loading = false;
    } else {
      console.warn('No Update Rows in Order View: ' + demands.length);
      this.bindDataToGrid(demands);
    }
  }

  private bindDataToGrid(demands: Demandline[]) {
    this.beamOrderLines = demands;
    this.beamOrderLinesGridData = process(demands, { group: this.state.group });
    this.beamOrderLinesGridData.total = this.beamOrderRowService.totalCount;
    this.loading = false;
  }

  private validate(): boolean {
    const invalidDemands = this.getDemands(demand => demand.qtyUnit && demand.qtyUnit.toUpperCase() !== 'PCS');
    const inactiveDemands = this.getDemands(demand => !demand.isActive);
    const demandsWithPO = this.getDemands(demand => demand.prodOrderCount > 0);

    let areDemandsValid = true;

    if (invalidDemands.length > 0) {
      this.notifyError(invalidDemands, 'Operative.AutoGeneratePOForDemandsErrorMsg', 'invalidDemands');
      areDemandsValid = false;
    }

    if (inactiveDemands.length > 0) {
      this.notifyError(inactiveDemands, 'Operative.AutoGeneratePOForDemandsInActiveErrorMsg', 'inactiveDemands');
      areDemandsValid = false;
    }

    if (demandsWithPO.length > 0) {
      this.notifyError(demandsWithPO, 'Operative.AutoGeneratePOForDemandsPOAlreadyExistErrorMsg', 'demandsWithPO');
      areDemandsValid = false;
    }

    return areDemandsValid;
  }

  private getDemands(predicate: (demand: any) => boolean): any[] {
    return this.beamOrderRowIds
      .map(id => this.beamOrderLinesGridData.data.find(demand => demand.BeamOrderRowID === id))
      .filter(demand => demand && predicate(demand));
  }

  private notifyError(demands: any[], messageKey: string, demandsKey: string): void {
    const demandsList = demands.map(demand => `${demand.orderID}:${demand.orderRowID}`).join(', ');
    this.notificationService.notifyErrorAppChanel(messageKey, 'Error', { [demandsKey]: demandsList });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
