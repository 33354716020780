import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { BeamMaterialLine } from '@app/core/models/beam-material-line.model';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { BeamMaterial } from '@app/core/models/beam-material.model';
import { CordHelper } from '@app/modules/gluelam/controls/beam-material-gluset/CordHelper';
import { GlusetMenuEvent } from '@app/modules/gluelam/controls/beam-material-gluset/beam-material-glueset.component';
import { BeamMaterialTypeHelper } from '@app/shared/helpers/beam-material-type.helper';

@Component({
  selector: '[app-beam-material]',
  templateUrl: './beam-material.component.html',
  styleUrls: ['./beam-material-glueset.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class BeamMaterialEditor {
  @Input() containerWidth: number;
  @Input() bm: BeamMaterial;
  @Input() ch: CordHelper;
  @Input() cut: number = 1;
  @Input() isSplitView: boolean;

  @Output('openBeamMaterialLineMenu') openBeamMaterialLineMenuInt: EventEmitter<GlusetMenuEvent> = new EventEmitter();

  public get xOffsetTestPSC() {
    if (this.isNormalShape()) {
      return this.bm.GetTotalAssignedLength() + this.bm.overlength;
    } else {
      return this.bm.length + this.bm.overlength;
    }
  };

  constructor(public readonly conf: ConfigurationService) {
  }

  openBeamMaterialLineMenu(event: GlusetMenuEvent) {
    event.bm = this.bm;
    this.openBeamMaterialLineMenuInt.emit(event);
  }

  grab(event: any) {
    this.openBeamMaterialLineMenuInt.emit(<GlusetMenuEvent>{ bm: this.bm, bml: null, mouseEvent: event });
    return false;
  }

  getLinesToDraw(): BeamMaterialLine[] {
    return this.bm.beamMaterialLines.filter(bml => bml.Cut === this.cut).sort((a, b) => a.IndexInCut - b.IndexInCut);
  }

  getTextForManualBeamMaterial(): string {

    let text = `No: ${this.bm.beamMaterialLines?.length ?? 0}`

    for(let line of this.bm.beamMaterialLines) {
      text += ` ${line.transportPackageDemand.orderID} ${line.NumberOfBeams}`
    }

    return text;
  }

  getXforBML(bml: BeamMaterialLine, index: number) {
    if (bml.IndexInCut === 0) {
      return this.bm.overlength;
    }

    let x = this.bm.overlength;
    const lines = this.getLinesToDraw();
    for (let i = 0; i < index; i++) {
      x += lines[i].length + this.bm.cutWidth; // get this 60 from machine config
    }
    return x;
  }

  calcX(index: number) {
    let x = this.conf.BEAM_DEFAULT_OFF_CUT_BMGS;

    const lines = this.getLinesToDraw();

    for (let i = 0; i < index; i++) {
      x += lines[i].length;
    }

    return this.ch.getWidth(x);
  }

  split = (bm: BeamMaterial) => {
    const isSplit = bm.beamMaterialLines.map(x => {
      const isSplit = x.transportPackageDemand?.deafultNumberOfBeamsPerBeamMaterial > 1;
      return isSplit;
    }).some(x => x === true);
    return isSplit;
  };

  getDefaultNoOfBeams(bml: BeamMaterialLine) {
    if (!this.isSplitView) {
      return this.bm.isSplit ? 2 : 1;
    } else {
      return bml.NumberOfBeams > 1 ? 2 : 1;
    }
  }

  isNormalShape() {
    return BeamMaterialTypeHelper.isNormalShape(this.bm.beamMaterialType);
  }
}
