import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DataStateChangeEvent, GridDataResult } from '@progress/kendo-angular-grid';
import { AoTBalanceDetailService } from '@app/core/services/http-services/aot/aot-balance-detail.service';
import { UnitConversionService } from '@app/core/services/custom-services/unit-conversion.service';
import { IAoTBalanceDetailSearchCriteriaDTO } from '@app/core/models/aotBalanceDetailSearchCriteriaDTO.model';
import { AoTDocumentType } from '@app/core/models/aot-document-type.enum';
import { KendoGridSetUp } from '@app/core/services/custom-services/kendo-grid-setup';
import { DeamndService } from '@app/core/services/http-services/gluelam/demand.service';
import { Router } from '@angular/router';
import { AoTDocumentTypeEnumHelper } from '@app/shared/helpers/aot-documenttype-enum-translation.helper';
import { FilterDescriptor } from '@progress/kendo-data-query';


@Component({
  selector: 'app-aot-grid-detail',
  templateUrl: './aot-grid-detail.component.html',
  styleUrls: ['./aot-grid-detail.component.scss']
})
export class AotGridDetailComponent implements OnInit {
  gridDetailData: GridDataResult;
  detailSerchCriteria: IAoTBalanceDetailSearchCriteriaDTO;
  loading: boolean = false;
  state: DataStateChangeEvent = this.setState();
  documentType: { key: AoTDocumentType, text: string }[];
  selectedTypesValue: AoTDocumentType[] = [];
  public searchDetailResult = <GridDataResult>{};

  @Input() set aotBalanceDetailSelectedCriteriaInput(detailSerchCriteriaInput: IAoTBalanceDetailSearchCriteriaDTO) {
    this.state = this.setState();
    this.detailSerchCriteria = detailSerchCriteriaInput;
    this.onFilterChange();
  };

  @Output() onRefreshRequested = new EventEmitter<boolean>();

  constructor(public aoTBalanceDetailService: AoTBalanceDetailService
    , public beamOrderRowService: DeamndService
    , public unitConversionService: UnitConversionService
    , private router: Router) {
  }

  ngOnInit(): void {
    this.documentType = AoTDocumentTypeEnumHelper.getAoTDocumentTypeEnumTranslations();
  }

  callSearchDetailAPI(detailSerchCriteriaInput: IAoTBalanceDetailSearchCriteriaDTO) {
    this.aoTBalanceDetailService.searchAoTBalanceDetail(this.state, detailSerchCriteriaInput).subscribe(x => {
      this.loading = true;
      this.gridDetailData = x;
      this.gridDetailData.data.forEach(item => {
        item.startDate = item.startDate !== null ? new Date(item.startDate) : null;
        item.endDate = item.endDate !== null ? new Date(item.endDate) : null;
      });
      this.loading = false;
    });
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.callSearchDetailAPI(this.detailSerchCriteria);
  }

  onProdOrderDetailCreation(d: boolean) {
    this.onRefreshRequested.emit(d);
  }

  setState(): DataStateChangeEvent {
    return {
      skip: 0,
      take: KendoGridSetUp.pageSize,
      filter: {
        logic: 'and',
        filters: []
      }
    };
  }

  getDocumentType(dockType: number): string {
    return this.documentType.find(d => d.key === dockType).text;
  }

  canCreateLink(documentType: AoTDocumentType) {
    return (documentType === AoTDocumentType.SalesOrder
      || documentType === AoTDocumentType.ForcastOrder
      || documentType === AoTDocumentType.ProductionOrderProduce
      || documentType === AoTDocumentType.ProductionOrderConsume)
  }

  handleDocumentClick(documentType: AoTDocumentType, dataItem: any) {
    if (documentType === AoTDocumentType.SalesOrder || documentType === AoTDocumentType.ForcastOrder) {
      const dr = this.beamOrderRowService.openDialogForDemandInfo(dataItem.guid);
      dr.result.subscribe((d) => {
        dr.close();
      });
    }
    else if (documentType === AoTDocumentType.ProductionOrderProduce || documentType === AoTDocumentType.ProductionOrderConsume) {
      const url = this.router.serializeUrl(this.router.createUrlTree([`/prod-order/${dataItem.prodOrderId}`]));
      window.open(url, '_blank');
    }
  }

  onFilterChange() {
    if (this.detailSerchCriteria) {
      this.state.filter.filters = [];
      this.detailSerchCriteria.selectedTypes.forEach(element => {
        this.state.filter.filters.push(<FilterDescriptor>{ field: 'documentTypeId', value: element, operator: 'eq' });
      });
      this.callSearchDetailAPI(this.detailSerchCriteria);
    }
  }

  tagMapper(tags: any[]): any[] {
    return tags.length < 3 ? tags : [tags];
  }
}
