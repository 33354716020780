import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { LogForecastService } from '@app/core/services/http-services/aot/log-forecast.service';
import { ILogForecastConfigDTO } from '@app/core/models/logForecastConfigDTO';
import { ProcessForecastLogStockDTO } from '@app/core/models/processForecastLogStockDTO';
import { KendoGridSetUp } from '@app/core/services/custom-services/kendo-grid-setup';
import { CreateLogForecastForm } from '@app/core/models/forms/aot/log-forecast-form/create-log-forecast-form.model';
import { Guid } from 'guid-typescript';

marker('AoT.LogForecastAdded');
marker('AoT.LogForecastUpdated');
marker('AoT.LogForecastDeleted');
marker('AoT.InvalidNoOfDays');
marker('AoT.LogForecastConfigActivityAlreadyExists');
marker('AoT.DataRefreshed');
marker('AoT.ErrDataRefreshed');
marker('App.AddAndRefreshCurrentData');
marker('App.DataUpdateInProgress');

const createLogForecastForm = (p: ILogForecastConfigDTO) => new FormGroup<CreateLogForecastForm>({
  logForecastID: new FormControl<number>(p.logForecastID, [Validators.required]),
  noOfDays: new FormControl<number>(p.noOfDays, [Validators.required, Validators.min(1)]),
  activityIndex: new FormControl<number>(p.activityIndex, [Validators.required]),
  siteId: new FormControl<Guid>(p.siteId, [Validators.required]),
  useStockForecast: new FormControl<boolean>(p.useStockForecast, [Validators.required]),
  noOfStockDaysGen: new FormControl<number>(p.noOfStockDaysGen, [Validators.required, Validators.min(1), maxLargerMin('noOfDays')]),
  noOfStockDaysLookup: new FormControl<number>(p.noOfStockDaysLookup, [Validators.required, Validators.min(1)]),
  removeOutLiers: new FormControl<boolean>(p.removeOutLiers, [Validators.required])
});

export function maxLargerMin(controlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const c2 = control?.parent?.get(controlName);
    if (control?.value >= c2?.value) {
      return {
        maxLargerMin: true
      };
    }
  };
}

@Component({
  selector: 'app-log-forecast',
  templateUrl: './log-forecast.component.html',
  styleUrls: ['./log-forecast.component.css']
})
export class LogForecastComponent implements OnInit {
  gridState: State = {
    skip: 0,
    take: KendoGridSetUp.pageSize,
    filter: {
      logic: 'and',
      filters: []
    }
  };

  gridLogForecastData: GridDataResult;
  logForecastIdToRemove: number = null;
  siteIdFilterForm: FormGroup;
  public formGroup: FormGroup;
  private editedRowIndex: number;

  @ViewChild('logForecastEditorGrid')
  private readonly logForecastEditorGrid: GridComponent;

  constructor(public logForecastService: LogForecastService, private readonly notificationService: AppNotificationService) { }

  public ngOnInit(): void {
    this.setSiteIdFilterForm();
    this.loadData();
  }

  private setSiteIdFilterForm() {
    this.siteIdFilterForm = new FormGroup({
      siteId: new FormControl('', [Validators.required])
    });
  }

  loadData() {
    this.logForecastService.getLogForecast()
      .subscribe(d => {
        this.gridLogForecastData = d;
      });
  }

  addLogForecastRow() {
    this.closeEditor(this.logForecastEditorGrid);
    this.formGroup = createLogForecastForm(<ILogForecastConfigDTO> {
      logForecastID: 0,
      noOfDays: undefined,
      activityIndex: undefined,
      siteId: this.siteIdFilterForm.value.siteId,
      useStockForecast: true,
      noOfStockDaysGen: 10,
      noOfStockDaysLookup: 10,
      removeOutLiers: false
    });
    this.logForecastEditorGrid.addRow(this.formGroup);
  }

  public editHandler({ sender, rowIndex, dataItem }) {
    this.closeEditor(this.logForecastEditorGrid);

    this.formGroup = createLogForecastForm(dataItem);
    sender.editRow(rowIndex, this.formGroup);
  }

  public cancelHandler({ sender, rowIndex }) {
    this.closeEditor(sender, rowIndex);
  }

  public saveHandler({ sender, rowIndex, formGroup, isNew, dataItem }): void {
    const value = formGroup.value;

    value.siteId = this.siteIdFilterForm.value.siteId;

    if (isNew) {
      this.logForecastService.insert(value).subscribe(m => {
        this.loadData();
        sender.closeRow(rowIndex);
        this.notificationService.notifySucsessAppChanel('AoT.LogForecastAdded');
      });
    } else {
      this.logForecastService.update(value).subscribe((m) => {
        dataItem.activityIndex = m.activityIndex;
        dataItem.noOfDays = m.noOfDays;
        dataItem.activity = m.activity;
        dataItem.noOfStockDaysLookup = m.noOfStockDaysLookup;
        dataItem.noOfStockDaysGen = m.noOfStockDaysGen;
        dataItem.useStockForecast = m.useStockForecast;
        dataItem.siteId = m.siteId;
        dataItem.removeOutLiers = m.removeOutLiers;
        sender.closeRow(rowIndex);
        this.notificationService.notifySucsessAppChanel('AoT.LogForecastUpdated');
      });
    }

    this.formGroup = null;
  }

  public removeHandler({ dataItem, isNew, rowIndex }): void {
    this.logForecastIdToRemove = dataItem.logForecastID;
  }

  confirmRemove(isRemove: boolean) {
    if (isRemove) {
      this.logForecastService.remove(this.logForecastIdToRemove).subscribe(() => {
        this.loadData();
        this.notificationService.notifySucsessAppChanel('AoT.LogForecastDeleted');
      });
    }
    this.logForecastIdToRemove = null;
  }

  private closeEditor(grid, rowIndex = this.editedRowIndex) {
    grid.closeRow(rowIndex);
    this.editedRowIndex = undefined;
    this.formGroup = undefined;
  }

  addRefreshForecastData(logForeCast) {
    this.notificationService.notifyInfoAppChanel('App.DataUpdateInProgress');

    const logForecastDatas: ProcessForecastLogStockDTO = <ProcessForecastLogStockDTO>{
      date: new Date(),
      guid: logForeCast.guid
    };

    this.logForecastService.addRefreshLogForecastData(logForecastDatas).subscribe({
      next: res => this.updateResponse(res),
      error: err => this.handleResponseError(err)
    });

    console.log(logForecastDatas);
  }

  private updateResponse(response: boolean) {
    this.notificationService.notifySucsessAppChanel('AoT.DataRefreshed');
  }

  private handleResponseError(error: any) {
    console.error(error);
  }
}
