<div #content style="width: 100%; height: calc(100vh - 200px);">

  <kendo-grid #grdProdOrders
            [data]="prodOrderData"
            style="width: 100%; height: 100%;"
            [selectable]="true"
            [pageable]="true"
            [reorderable]="true"
            [filterable]="true"
            [sortable]="true"
            [resizable]="true"
            [sort]="state.sort"
            [filter]="state.filter"
            [skip]="state.skip"
            [pageSize]="state.take"
            (dataStateChange)="dataStateChange($event)"
            (columnResize)="onGridStateChange()"
            (columnReorder)="onGridStateChange()"
            (detailExpand)="detailExpand($event)"
            [loading]="loading"
            (cellClick)="cellClickHandler($event)"
            (cellClose)="cellCloseHandler($event)"
            [kendoGridSelectBy]="'prodOrderId'"
            [(selectedKeys)]="prodOrderIds"
            [size]="'small'"
  >
    <ng-template kendoGridToolbarTemplate>
    <button
        kendoButton
        (click)="openBatchEditDialog()"
        [primary]="true"
        [disabled]="prodOrderData?.total == 0">
        {{'OrderViewTranslation.BulkEdit' | translate}}
      </button>
      <button
      kendoButton
      (click)="deleteProdOrder(prodOrderIds)"
      themeColor="error"
      [disabled]="prodOrderData?.total == 0">
      {{'App.Remove' | translate}}
    </button>
    </ng-template>
    <kendo-grid-checkbox-column [showSelectAll]="true"></kendo-grid-checkbox-column>

    <kendo-grid-column title="{{ 'App.Name' | translate }}" field="name">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
    </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="startDate" title="{{ 'App.StartDate' | translate }}">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
            <kendo-grid-date-filter-cell    [showOperators]="false"
                                            [column]="column"
                                            [filter]="filter">
            </kendo-grid-date-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.startDate | siteDateFormat: true}}
        </ng-template>
        <ng-template kendoGridEditTemplate let-rowIndex="rowIndex" let-formGroup="formGroup" let-dataItem="dataItem" >
          <kendo-datetimepicker [formControl]="formGroup.get('plannedExecutionStartDate')" *ngIf="dataItem.stateId < prodOrderStateEnum.Started"></kendo-datetimepicker>
          <div *ngIf="dataItem.stateId > prodOrderStateEnum.Released">
            {{dataItem.startDate | siteDateFormat: true}}
          </div>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column  field="endDate" title="{{ 'App.EndDate' | translate }}">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-date-filter-cell  [showOperators]="false"
                                        [column]="column"
                                        [filter]="filter">
          </kendo-grid-date-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.endDate | siteDateFormat: true}}
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="stateText">
        <ng-template kendoGridHeaderTemplate >
          <span>{{ 'Operative.State' | translate}}</span>
          <kendo-svg-icon themeColor="info" [icon]="pencilIcon"></kendo-svg-icon>
        </ng-template>
        <ng-template kendoGridFilterCellTemplate let-filter>
          <app-kendo-grid-multiselect-drop-down-filter
            [filter]="filter"
            [data]="prodOrderStatesData"
            [defaultSelectedValues]="defaultSelectedStateIds"
            textField="name"
            valueField="stateId"
            [isValueTypeString]=true>
          </app-kendo-grid-multiselect-drop-down-filter>
        </ng-template>
        <ng-template kendoGridEditTemplate let-rowIndex="rowIndex" let-formGroup="formGroup" let-dataItem="dataItem">
            <app-select-prod-order-state [formControl]="formGroup.get('stateId')" [showTitel]="false" [prodOrderId]="dataItem.prodOrderId"></app-select-prod-order-state>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="machineName">
      <ng-template kendoGridHeaderTemplate>
        <span>{{ 'Operative.Machine' | translate}}</span>
        <kendo-svg-icon themeColor="info" [icon]="pencilIcon"></kendo-svg-icon>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-grid-multiselect-drop-down-filter
          [filter]="filter"
          [data]="prodOrderMachineData"
          [defaultSelectedValues]="defaultSelectedMachineIds"
          textField="name"
          valueField="machineId"
          [isValueTypeString]=true>
        </app-kendo-grid-multiselect-drop-down-filter>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        {{ dataItem.machine.name }}
     </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="machine.colorCode" title="{{ 'Operative.ColorCode' | translate }}" [filterable]="false" [sortable]="false">
      <ng-template kendoGridCellTemplate let-dataItem>
        <div [ngStyle]="{'background-color': dataItem.machine.colorCode}" class="machineColor">
          <br/>
        </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="qtyCompleted" title="{{ 'Operative.QtyCompleted' | translate }}"
                        [filterable]="false" [sortable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
            <kendo-sparkline type="bar"
                            [data]="dataItem.qtyCompleted"
                            [tooltip]="{visible: false}"
                            [transitions]="true"
                            [seriesDefaults]="{labels: {background: 'none', visible: true, format: '{0}%'}}">
                <kendo-chart-area [width]="200"></kendo-chart-area>
                <kendo-chart-value-axis>
                    <kendo-chart-value-axis-item [min]="0" [max]="130">
                    </kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
            </kendo-sparkline>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="sequenceId"  title="{{ 'Operative.Sequence' | translate }}" [filterable]="false">
      <ng-template kendoGridCellTemplate let-dataItem>
        <app-sequence-display *ngIf="dataItem.sequenceId" [sequenceId]="dataItem.sequenceId" [badge]="true"></app-sequence-display>
        <app-glueset-sequence-display *ngIf="dataItem.glueSetID" [gluesetID]="dataItem.glueSetID" [badge]="true"></app-glueset-sequence-display>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column field="orderId"  title="{{ 'Operative.Demand' | translate }}" [filterable]="false" [sortable]="false">
        <ng-template kendoGridCellTemplate let-dataItem>
          <span *ngIf="dataItem.orderId">
            {{dataItem.orderId}} : {{dataItem.orderRowId}}
          </span>
          <kendo-svg-icon
            *ngIf="dataItem.violatesPOChain"
            [icon]="warningTriangleIcon"
            themeColor="warning">
          </kendo-svg-icon>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column title="{{ 'App.Quantity' | translate }}"
      field="planedQty"
      [filterable]="false"
      [sortable]="false">
    </kendo-grid-column>

    <kendo-grid-column title="{{ 'Operative.UnitCode' | translate }}"
      field="planedQtyUOM"
      [filterable]="false"
      [sortable]="false">
    </kendo-grid-column>

    <kendo-grid-column field="latestProductionDate"
                           title="{{ 'OrderViewTranslation.LatestProductionDate' | translate }}"
                           [filterable]="false"
                         >
          <ng-template kendoGridCellTemplate let-dataItem>
             {{ dataItem.latestProductionDate | siteDateFormat }}
             <kendo-svg-icon
                  *ngIf="dataItem.isPOEndDateGreaterLPDate"
                  [icon]="warningTriangleIcon"
                  themeColor="warning">
             </kendo-svg-icon>
          </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column title="{{ 'App.Edit' | translate }}" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <button kendoButton [svgIcon]="copyIcon" themeColor="primary"(click)="copyProdOrder(dataItem.prodOrderId)" appAccessControl deniedRoles="{{deniedRoles}}">
        </button>
        <button kendoButton [svgIcon]="pencilIcon" themeColor="primary" (click)="editProdOrder(dataItem.prodOrderId)" [disabled]="checkAccessForClosedPO(dataItem.stateId)" >
        </button>
        <button kendoButton [svgIcon]="trashIcon" themeColor="warning" *ngIf="dataItem.showDelete" (click)="deleteProdOrder([dataItem.prodOrderId])" appAccessControl deniedRoles="{{deniedRoles}}">
        </button>
      </ng-template>
    </kendo-grid-command-column>

    <ng-template kendoGridDetailTemplate let-dataItem>
        <app-prod-order-line-grid [prodOrderLines]="dataItem.lines"></app-prod-order-line-grid>
    </ng-template>

  </kendo-grid>
</div>

<app-confirmation-dialog
*ngIf="confirmStateUpdate"
(conformationDialogCloseEvent)="confirmUpdate($event)" [msg]="changeStateBackToReleaseMsg">
</app-confirmation-dialog>
