import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { DateHelper } from '@app/shared/helpers/date-helper';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { DemandTypeEnum, getDemmandTypeValues } from '@app/core/models/aot-demand-type.enum';
import { DeamndService, IDemandUpdateBulkDTO } from '@app/core/services/http-services/gluelam/demand.service';
import { DemandBulkUpdateForm, DemandTypeGroup, IsActiveGroup, LatestProductionDateGroup, PlannedProductionDateGroup } from '@app/core/models/forms/aot/demand-form/aot-demand-bulk-update-form.model';

marker('OrderViewTranslation.ErrorOnDemandUpdate');
marker('OrderViewTranslation.SuccessOnDemandUpdate');
marker('OrderViewTranslation.SelectLatestProductionDate');
marker('OrderViewTranslation.SelectPlannedProductionDate');
marker('OrderViewTranslation.SelectToggleMsg');

@Component({
  selector: 'app-aot-demand-update',
  templateUrl: './aot-demand-bulk-update.component.html',
  styleUrls: ['./aot-demand-bulk-update.component.css']
})
export class AotDemandBulkUpdateComponent extends DialogContentBase {
  dateFormat: string = DateHelper.dateFormat;
  demandTypes = getDemmandTypeValues();

  isActiveItems = [
    {
      value: true,
      text: 'App.Yes'
    },
    {
      value: false,
      text: 'App.No'
    }
  ];

  updateDemandForm: FormGroup<DemandBulkUpdateForm> = new FormGroup<DemandBulkUpdateForm>({
    latestProductionDate: new FormGroup<LatestProductionDateGroup>({
      enable: new FormControl<boolean>(false),
      value: new FormControl<string | null>(null),
    }),
    demandType: new FormGroup<DemandTypeGroup>({
      enable: new FormControl<boolean>(false),
      value: new FormControl<DemandTypeEnum>(DemandTypeEnum.SalesOrder),
    }),
    isActive: new FormGroup<IsActiveGroup>({
      enable: new FormControl<boolean>(false),
      value: new FormControl<boolean>(false),
    }),
    plannedProductionDate: new FormGroup<PlannedProductionDateGroup>({
      enable: new FormControl<boolean>(false),
      value: new FormControl<string | null>(null),
    }),
  });

  constructor(@Inject(DialogRef) public data: { input: number[] },
      private readonly dialogRef: DialogRef,
      private readonly beamOrderRowService: DeamndService,
      private readonly appNotificationService: AppNotificationService
  ) { super(dialogRef); }

  save() {
    if (!this.validate()) return;

    const formValue = this.updateDemandForm.value;
    const { latestProductionDate, demandType, isActive, plannedProductionDate } = formValue;

    const dto: IDemandUpdateBulkDTO = {
      deamndIds: this.data.input,
      latestProductionDate: latestProductionDate?.enable ? latestProductionDate.value : null,
      demandType: demandType?.enable ? demandType.value : null,
      isActive: isActive?.enable ? isActive.value : null,
      plannedProductionDate: plannedProductionDate?.enable ? plannedProductionDate.value : null,
    };

    this.beamOrderRowService.BulkUpdate(dto).subscribe({
      next: (poReportState) => {
        if (poReportState) {
          this.close();
          this.appNotificationService.notifySucsessAppChanel('OrderViewTranslation.SuccessOnDemandUpdate');
        }
      },
      error: () => {
        this.appNotificationService.notifyErrorAppChanel('OrderViewTranslation.ErrorOnDemandUpdate', 'Error');
      }
    });
  }

  close() {
    this.dialogRef.close(true);
  }

  validate(): boolean {
    const checkField = (field: string, errorKey: string): boolean => {
      const fieldValue = this.updateDemandForm.get(field)?.value;
      if (fieldValue?.enable && fieldValue.value === null) {
        this.appNotificationService.notifyErrorAppChanel(errorKey, 'Error');
        return false;
      }
      return true;
    };

    return checkField('latestProductionDate', 'OrderViewTranslation.SelectLatestProductionDate') &&
           checkField('plannedProductionDate', 'OrderViewTranslation.SelectPlannedProductionDate');
  }
}
