import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { environment } from 'src/environments/environment';
import { ILogForecastConfigDTO } from '@app/core/models/logForecastConfigDTO';
import { ProcessForecastLogStockDTO } from '@app/core/models/processForecastLogStockDTO';
import { LocalStorageService } from '../../custom-services/localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class LogForecastService {
  constructor(public http: HttpClient, private localStorageService: LocalStorageService) {}

  getLogForecast(): Observable<GridDataResult> {
    const params = new HttpParams().set('siteId', this.localStorageService.getItem(LocalStorageService.selectedSiteId));
    return this.http.get<GridDataResult>(`${environment.apiUrl}LogForecast/getLogForecast`, { params });
  }

  insert(data: ILogForecastConfigDTO): Observable<number> {
    const endpointUrl = `${environment.apiUrl}LogForecast`;
    return this.http.post<number>(endpointUrl, JSON.stringify(data), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  update(data: ILogForecastConfigDTO): Observable<ILogForecastConfigDTO> {
    const endpointUrl = `${environment.apiUrl}LogForecast/${data.logForecastID}`;
    return this.http.put<ILogForecastConfigDTO>(endpointUrl, JSON.stringify(data), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  remove(logForecastID: number): Observable<boolean> {
    const machineUri = `${environment.apiUrl}LogForecast`;
    return this.http.delete<boolean>(`${machineUri}/${logForecastID}`);
  }

  addRefreshLogForecastData(data: ProcessForecastLogStockDTO): Observable<any> {
    const endpointUrl = `${environment.apiUrl}LogForecast/AddRefreshLogForecastData`;
    return this.http.post<ProcessForecastLogStockDTO>(endpointUrl, JSON.stringify(data), {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }
}
