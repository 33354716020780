import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AotBalanceComponent } from './aot-balance/aot-balance.component';
import { AotDemandComponent } from './aot-demand/aot-demand.component';
import { LogForecastComponent } from './log-forecast/log-forecast.component';
import { AuthGuard } from '@app/core/services/auth-service/auth.guard';
import { AotBalanceReportComponent } from '@app/modules/aot/aot-balance-report/aot-balance-report.component';

const routes: Routes = [
  { path: 'balance', component: AotBalanceComponent, canActivate: [MsalGuard, AuthGuard] },
  { path: 'balanceReport', component: AotBalanceReportComponent, canActivate: [MsalGuard, AuthGuard] },
  { path: 'demand', component: AotDemandComponent, canActivate: [MsalGuard, AuthGuard] },
  { path: 'logForecast', component: LogForecastComponent, canActivate: [MsalGuard, AuthGuard], data: { forceSelect: true } }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AotRoutingModule { }
