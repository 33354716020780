import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { KendoGridService } from '@app/core/services/custom-services/kendo-grid.service';
import { IAoTBalanceDetailSearchCriteriaDTO } from '@app/core/models/aotBalanceDetailSearchCriteriaDTO.model';

@Injectable({
  providedIn: 'root'
})
export class AoTBalanceDetailService extends BehaviorSubject<GridDataResult> {
  handleDetailSearchSubscription = new Subject();
  loading: boolean = false;

  constructor(private http: HttpClient, private kendoGridService: KendoGridService) {
    super(null);
  }

  searchAoTBalanceDetail(state: State, dsc: IAoTBalanceDetailSearchCriteriaDTO): Observable<GridDataResult> {
    const stateFilter = this.kendoGridService.getStateFilter(state);
    let params = new HttpParams()
      .set('unitCode', dsc.unitCode)
      .set('entityGroupIndex', dsc.entityGroupIndex.toString())
      .set('periodTypeId', dsc.periodTypeId.toString())
      .set('groupId', dsc.groupId.toString())
      .set('periodId', dsc.periodId.toString())
      .set('groupValue', dsc.groupValue)
      .set('parentGroupText', dsc.parentGroupText)
      .set('colomName', dsc.colomName)
      .set('skip', stateFilter.skip.toString())
      .set('take', stateFilter.take.toString())
      .set('currentDate', dsc.currentDate)
      .set('orderType', dsc.orderType)
      .set('siteId', dsc.siteId.toString())

    if (stateFilter.filter.length > 0) {
      stateFilter.filter?.forEach((element) => {
        if (element.code === 'documentTypeId')
          params = params.append(element.code, element.value);
      });
    }

    if (stateFilter.sort) {
      params = params.append('sortColumn', stateFilter.sort.sortColumn);
      params = params.append('sortDirection', stateFilter.sort.sortDirection);
    };


    return this.http
      .get<GridDataResult>(`${environment.apiUrl}AoTBalance/SearchAoTBalanceDetail`, { params });
  }
}
