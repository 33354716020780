import { BeamOrderLineAction, BeamOrderLineActionCreator } from '@app/core/models/beam-order-line.model';
import { BeamOrderLineCreator, Demandline } from '@app/core/models/demand-line.model';
import { DialogCloseResult, DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { KendoGridService } from '@app/core/services/custom-services/kendo-grid.service';
import { Observable, Subject } from 'rxjs';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { BaseService } from '@app/core/services/custom-services/base.service';
import { BeamMaterialLine } from '@app/core/models/beam-material-line.model';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { CreateMultiStepGlulamSequenceComponent } from '@app/modules/aot/create-multi-step-glulam-sequence/create-multi-step-glulam-sequence.component';
import { DemandFormComponent } from '@app/modules/aot/demand-form/demand-form.component';
import { DemandInfoComponent } from '@app/modules/aot/demand-info/demand-info.component';
import { GlulamSequenceResponseDTO, IGlulamSequenceResult } from '@app/core/models/glulamsequenceDTO';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { Guid } from 'guid-typescript';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';
import { SiteService } from '@app/core/services/http-services/app/site.service';
import { State } from '@progress/kendo-data-query';
import { map } from 'rxjs/operators';
import { EntityFilter } from '@app/core/services/http-services/model/entity.service';
import { DateHelper } from '@app/shared/helpers/date-helper';
import { ProdOrderService } from '@app/core/services/http-services/operative/prod-order.service';
import { IDemand, IDemandDTO, IDemandPartialDTO } from '@app/core/models/demand-model';

@Injectable()
export class DeamndService extends BaseService {
  beamOrderLines: Demandline[];
  unSelectedbeamOrderLine: Demandline;
  loading = false;
  totalCount: number;
  showPlanned: boolean;
  showOnlyWithNoGluPlan: boolean;
  selectedOrderRows: Subject<Demandline[]> = new Subject<Demandline[]>();
  unselectedOrderRow: Subject<Demandline> = new Subject<Demandline>();
  getBeamOrderRows: Subject<Demandline[]> = new Subject<Demandline[]>();
  state: State = {
    skip: 0,
    take: 50,
    filter: {
      logic: 'and',
      filters: [
      ]
    }
  };

  constructor(
    conf: ConfigurationService,
    http: HttpClient,
    notificationService: AppNotificationService,
    private readonly kendoGridService: KendoGridService,
    private readonly dialogService: DialogService,
    private readonly localStorageService: LocalStorageService,
    private readonly siteService: SiteService,
    private readonly poService: ProdOrderService
  ) {
    super(http, conf, notificationService);
    this.beamOrderLines = [];
  }

  empty() {
    this.getBeamOrderRows.next([]);
    this.loading = true;
  }

  query(state: State = null, showPlanned: boolean = false, showOnlyWithNoGluPlan: boolean = false, siteId: string | null = null) {
    this.loading = true;
    this.showPlanned = showPlanned;
    this.showOnlyWithNoGluPlan = showOnlyWithNoGluPlan;
    const stateFilter = this.kendoGridService.getStateFilter(state);

    let params = this.kendoGridService.getHttpParamsFilter(state);

    stateFilter.filter.forEach(element => {
      if (element.code === 'plannedProductionDate') {
        params = params.set('plannedProductionDate', DateHelper.fromDateISOFormat(new Date(element.value)));
      } else if (element.code === 'latestProductionDate') {
        params = params.set('latestProductionDate', DateHelper.fromDateISOFormat(new Date(element.value)));
      }
    });

    params = params.append('showPlanned', this.showPlanned);
    params = params.append('showOnlyWithNoGluPlan', this.showOnlyWithNoGluPlan);

    if (siteId && !params.has('siteId')) {
      params = params.append('siteId', siteId);
    }

    return this.http.get<GridDataResult>(`${this.configurations.baseApiUrl}TransportPackageDemand`, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      params
    }).pipe(
      map(async (response: GridDataResult) => {
        this.totalCount = response.total;
        response = await this.siteService.mapSiteIdsToNames(response);
        return BeamOrderLineCreator.FromJson(response.data);
      })
    ).subscribe({
      next: async (d) => {
        this.getBeamOrderRows.next(await d);
        this.loading = false;
      },
      error: () => {
        this.getBeamOrderRows.next([]);
        this.loading = false;
      }
    });
  }

  selecOrderRows(orderRows: Demandline[]) {
    this.beamOrderLines = orderRows;
    this.selectedOrderRows.next(orderRows);
  }

  unselectOrderRow(orderRows: Demandline) {
    this.unSelectedbeamOrderLine = orderRows;
    this.unselectedOrderRow.next(orderRows);
  }

  getSelectedOrderRows() {
    this.selectedOrderRows.asObservable();
  }

  savePartial(partial: IDemandPartialDTO): Observable<IDemandPartialDTO> {
    return this.http
      .put<IDemandPartialDTO>(
        `${this.configurations.baseApiUrl}Demand/partial/${partial.demandID}`,
        JSON.stringify(partial),
        this.httpOptions
      );
  }

  doBeamOrderLineAction(beamOrderLineAction: BeamOrderLineAction[]): Observable<Array<BeamOrderLineAction>> {
    return this.http
      .put(
        `${this.configurations.baseApiUrl}orders`,
        JSON.stringify(beamOrderLineAction)
      )
      .pipe(map(response => BeamOrderLineActionCreator.FromJson(response)));
  }

  getBeams(skip: number = 0, take: number = 10): Observable<BeamMaterialLine[]> {
    const endpointUrl = `${this.configurations.baseApiUrl}Beam?Get?Skip=${skip}&Take=${take}`;

    return this.http.get<BeamMaterialLine[]>(endpointUrl);
  }

  getAlternateCodes(entityFilter: EntityFilter): Observable<IAltModelResponceDTO[]> {
    let params = new HttpParams()
      .set('searchTermCode', entityFilter.searchTermCode.toString())
      .set('take', entityFilter.take.toString())
      .set('siteId', entityFilter.siteId.toString())
      .set('beamMaterialExternalId', entityFilter.beamMaterialExternalId.toString());

    if (entityFilter.rawLamellaThickness !== null && entityFilter.rawLamellaThickness !== undefined) {
      params = params.append('rawLamellaThickness', entityFilter.rawLamellaThickness);
    }

    const endpointUrl = `${this.configurations.baseApiUrl}getAltModelForDemand`;
    return this.http.get<IAltModelResponceDTO[]>(endpointUrl, { params });
  }

  removeDemand(demandId: number[], deleteByRow: boolean = false): Observable<number> {
    let params = new HttpParams();
    demandId.forEach(element => {
      params = params.append('ids', element?.toString());
    });
    params = params.append('deleteByRow', deleteByRow);
    return this.http.delete<number>(`${this.configurations.baseApiUrl}Demand/DeleteDemands`, { params });
  }

  removeDemandByPackage(transportPackageId: number[]): Observable<number> {
    let params = new HttpParams();
    transportPackageId.forEach(element => {
      params = params.append('ids', element?.toString());
    });
    return this.http.delete<number>(`${this.configurations.baseApiUrl}Demand/DeleteDemandsByPackageId`, { params });
  }

  createPO(demandId: number, activitysSelected: GlulamSequenceResponseDTO[], fingerJoinPODate: Date | null) {
    let params = new HttpParams();

    params = params.append('id', demandId?.toString());
    params = params.append('siteId', this.localStorageService.getItem(LocalStorageService.selectedSiteId));
    if (fingerJoinPODate)
      params = params.append('fingerJoinPODate', new Date(fingerJoinPODate).toISOString());

    return this.http.post<number>(`${this.configurations.baseApiUrl}Demand/CreateDemandPO`, activitysSelected, { params });
  }

  getDemands(searchTerm: string): Observable<IDemand[]> {
    let params = new HttpParams();
    params = params.append('searchTerm', searchTerm.toString());
    const endpointUrl = `${this.configurations.baseApiUrl}Demand/getDemand`;
    return this.http.get<IDemand[]>(endpointUrl, { params });
  }

  get(guid: string): Observable<Demandline> {
    return this.http.get<Demandline>(`${this.configurations.baseApiUrl}Demand/${guid}`);
  }

  getByOrderIdOrderRowId(orderID: string, orderRowID: string): Observable<IDemand> {
    const endpointUrl = `${this.configurations.baseApiUrl}Demand/${orderID}/${orderRowID}`;
    return this.http.get<IDemand>(endpointUrl);
  }

  BulkUpdate(dto: IDemandUpdateBulkDTO): Observable<boolean> {
    let params = new HttpParams();
    dto.deamndIds.forEach(element => {
      params = params.append('ids', element.toString());
    });

    const endpointUrl = `${this.configurations.baseApiUrl}Demand/Bulk`;
    return this.http.put<boolean>(endpointUrl, dto, { params });
  }

  insertDemand(demand: IDemandPOSequenceDTO): Observable<number> {
    return this.http.post<number>(`${this.configurations.baseApiUrl}Demand`, demand);
  }

  openDialog(demand: IDemand): DialogRef {
    const dialogRef = this.dialogService.open({
      content: DemandFormComponent,
      width: '730px',
    });

    const content = dialogRef.content.instance as DemandFormComponent;
    content.data = { demand }
    return dialogRef;
  }


  openDialogForDemandInfo(guid: string): DialogRef {
    const dialogRef = this.dialogService.open({
      content: DemandInfoComponent,
      width: '865px',
      minHeight: '60vh',
    });

    const content = dialogRef.content.instance as DemandInfoComponent;
    content.data = { guid }

    return dialogRef;
  }

  openDialogForGlulamSeq(input: IDemandInputForActivitySelection): DialogRef {

    const dialogRef = this.dialogService.open({
      content: CreateMultiStepGlulamSequenceComponent,
      width: '1200px',
      minHeight: '65vh'
    });

    const content = dialogRef.content.instance as CreateMultiStepGlulamSequenceComponent;
    content.data = {
      materialIndex: input.materialIndex,
      orderId: input.orderId,
      orderRowId: input.orderRowId,
      current: input.currentPosition,
      totalCount: input.totalCount
    }

    return dialogRef;
  }

  setDemandFields(demand: Demandline, d: IDemandDTO) {
    demand.demandSpecificationsCount = d.demandSpecificationsCount;
    demand.NumberOfLaminas = d.numberOfLaminas;
  }

  handlePopupResult(data: IGlulamSequenceResult, dr: DialogRef, input: IDemandInputForActivitySelection, fingerJoinPODate: Date) {
    if (data instanceof DialogCloseResult) {
      dr.close();
    } else if (data.success) {
      this.createPO(input.beamOrderRowID, data.lines, fingerJoinPODate).subscribe((response) => {
        if (response) {
          this.notificationService
            .notifySucsessAppChanel('Operative.DemandProdOrderSequenceInProgress',
              'Success',
              { orderId: input.orderId, orderRowId: input.orderRowId });
          if (fingerJoinPODate !== null)
            this.poService.navigateToProdOrderGrid();
        } else {
          this.notificationService.notifyErrorAppChanel('Operative.CreateProdOrderErrMsg');
        }
      });
    }
  }
}

export interface IAltModelResponceDTO {
  index: number;
  code: string;
  isSplit: boolean;
  lamminaPlaneThickness: number;
  numberOfLamminas: number;
  deafultNumberOfBeamsPerBeamMaterial: number;
  beamLamminaDimensionId: number | null;
  materialDescription: string;
  entityGroupDescription: string;
}
export interface ITransportPackageAllocation {
  transportPackageID: number;
  packageID: string;
  qty: number;
  unitsExtra: number;
  volumeM3: number;
}

export interface IDemandProperty {
  demandPropertyId: number;
  propertyCode: string;
  valueInt: number;
  valueDecimal: number;
  valueString: string;
  displayValue: string;
}
export class UpdateSumUIQtyDTO {
  demandId: number;
  transportPackageId: number | null;
  newQty: number;
  gluePlanId: number;
  gluePlanIndex: number;
}

export class UpdateSumUIQtyForStaticalDTO {
  demandId: number;
  transportPackageId: number | null;
  newQty: number;
  gluePlanId: number;
  gluePlanIndex: number;
  isInitialized: boolean;
}

export interface DemandFilterDTO extends PaginationFilterDTO {
  transportPackagNO: string;
  orderID: string;
  orderRowID: string;
  lamellaId: number | null;
  deamndID: number | null;
  width: number | null;
  showPlanned: boolean;
  showOnlyWithNoGluPlan: boolean;
}

export interface PaginationFilterDTO {
  skip: number;
  take: number;
  sortColumn: string;
  sortDirection: string;
  getAll: boolean;
}


export interface IDemandPOSequenceDTO {
  demand: IDemand;
  isGenerateProdOrder: boolean;
  instruction: string;
  siteId: Guid;
  activitysSelected: GlulamSequenceResponseDTO[]
}

export interface IDemandUpdateBulkDTO {
  deamndIds: number[];
  latestProductionDate: string | null;
  isActive: boolean | null;
  demandType: number | null;
  plannedProductionDate: string | null;
}

export interface IDemandInputForActivitySelection {
  beamOrderRowID: number;
  orderId: string;
  orderRowId: string;
  materialIndex: number;
  currentPosition: number;
  totalCount: number;
}
