import { Component, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AoTDocumentType } from '@app/core/models/aot-document-type.enum';
import { AoTDocumentTypeEnumHelper } from '@app/shared/helpers/aot-documenttype-enum-translation.helper';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker('AoT.TransType');

@Component({
  selector: 'app-transaction-type-multi-select',
  template: `
  <kendo-formfield appearance="fill">
  <kendo-label *ngIf="showLable" class="min-width-dropdown">{{label | translate}}</kendo-label>
    <kendo-multiselect
    [data]="documentTypes"
    [checkboxes]="true"
    valueField="key"
    textField="text"
    [value]="typevalue"
    (valueChange)="onChangedVal($event)"
    [autoClose]="false"
    [tagMapper]="tagMapper"
    [valuePrimitive]="true"
    [disabled]="disableTranstype"
    (blur)="onBlur()">
      <ng-template kendoMultiSelectTagTemplate  let-dataItem>
        <span translate>
            {{dataItem.text}}
        </span>
      </ng-template>
      <ng-template kendoMultiSelectItemTemplate  let-dataItem>
        <span translate>
            {{dataItem.text}}
        </span>
      </ng-template>
    </kendo-multiselect>
  </kendo-formfield>
  `,
  styleUrls: ['./transaction-type-multi-select.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TransactionTypeMultiSelectComponent,
      multi: true
    }
  ]
})
export class TransactionTypeMultiSelectComponent implements OnInit {
  documentTypes: { key:AoTDocumentType, text:string}[];
  typevalue:AoTDocumentType[] = [];

  @Input() label = 'AoT.TransType';
  @Input() showLable = false;
  @Input() disableTranstype = true;
  @Input() set selectedTypes(types: AoTDocumentType[]) {
    if (types !== undefined && types.length > 0) {
      this.typevalue = types;
    } else {
      this.setDefault();
    }
  };

  private onTouched!: Function;
  private onChanged!: Function;

  constructor() { }

  ngOnInit(): void {
    this.setDefault();
  }

  setDefault() {
    this.documentTypes = AoTDocumentTypeEnumHelper.getAoTDocumentTypeEnumTranslations();
    this.setAllSelected();
    this.onChanged(this.typevalue);
  }

  private setAllSelected() {
    this.typevalue = this.documentTypes.map(d => d.key);
  }

  setValueToTransactionTypeObject(transactionTypes: any[]) {
    this.typevalue = transactionTypes;
  }

  onChangedVal(valuesSelected:any[]) {
    this.typevalue = valuesSelected;
    this.onChanged(this.typevalue);
  }

  onBlur(): void {
    if(this.typevalue.length === 0)
       this.setDefault();
  }

  writeValue(transactionTypeId: number) {}

  registerOnChange(onChange: any): void {
    this.onChanged = onChange;
    this.onChanged(this.typevalue);
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  public tagMapper(tags: any[]): any[] {
    return tags.length < 3 ? tags : [tags];
  }
}
