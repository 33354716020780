import { BeamMaterialGlueSet, BeamMaterialGlueSetLayer, BeamMaterialGlueSetState, ProposalState } from '@app/core/models/beam-material-glue-set.model';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { GlueSetService, IGlueplanGlusetSaveResultDTO } from '@app/core/services/http-services/gluelam/glue-set.service';
import { SVGIcon, pencilIcon, printIcon, saveIcon, trashIcon } from '@progress/kendo-svg-icons';
import { Subject, takeUntil } from 'rxjs';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { BeamLamminaDimension } from '@app/core/models/beam-lammina-dimension.model';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { GluePlanService } from '@app/core/services/http-services/gluelam/glue-plan.service';
import { GlueSetState } from '@app/core/services/http-services/gluelam/glueset-state.service';
import { GluelamStaticalMediatorService } from '@app/modules/gluelam/services/gluelam-statical-mediator.service';
import { GluingPlan } from '@app/core/models/gluing-plans.model';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { NgxSpinnerService } from 'ngx-spinner';
import { cloneDeep } from 'lodash-es';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker('GluePlan.Saving');
marker('GlueSet.BMGS_Removed');
marker('GlueSet.BMGS_NOT_Removed');
marker('GlueSet.GLUSET_SORTED_INFO');
marker('GlueSet.GLUSET_UPDATED');
marker('GlueSet.GLUSET_SAVE_ERROR');
marker('GluePlan.Loading');
marker('GluePlan.SaveAllGluingPlan');
marker('GlueSet.WARNING_HEIGHT_OF_LAYERS');
marker('GlueSet.WARNING_LENGTH_OF_LAYERS');

@Component({
  selector: 'app-glue-plan-view-statical',
  templateUrl: './glue-plan-view-statical.component.html',
  styleUrls: ['./glue-plan-view-statical.component.css']
})

export class GluePlanViewStaticalComponent implements OnInit, OnDestroy {
  @Input() public id: number;
  @Input() public plan: GluingPlan;
  @Input() public machine: IMachineDTO | null;
  @Input() public dimension: BeamLamminaDimension;

  @Output() onEditGluePlan = new EventEmitter<GluingPlan>();
  @Output() onDeleteGluePlan = new EventEmitter<GluingPlan>();
  @Output() onUpdateSelection = new EventEmitter<GluingPlan>();
  @Output() onCalculateUnits = new EventEmitter<BeamMaterialGlueSet[]>();

  //Icons
  pencilIcon: SVGIcon = pencilIcon;
  trashIcon: SVGIcon = trashIcon;
  saveIcon: SVGIcon = saveIcon;
  printIcon: SVGIcon = printIcon;

  glueSetsIdInDB: number[] = [];
  isRemoveGluePlan = false;
  glueSets: BeamMaterialGlueSet[] = [];
  updatedTotalBeamCount = 0;
  states: BeamMaterialGlueSetState[] = [];
  selectedState: BeamMaterialGlueSetState;
  gsWidth = 560;
  glueSetStateEnum = GlueSetState;
  gluelamSpinner: string = '';
  glueSetSpinner: string = '';

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private readonly gluingPlanService: GluePlanService,
    public conf: ConfigurationService,
    private readonly spinner: NgxSpinnerService,
    private readonly notificationService: AppNotificationService,
    private readonly bmgsService: GlueSetService,
    public ref: ChangeDetectorRef,
    private readonly gluelamStaticalMediatorService: GluelamStaticalMediatorService) { }

  ngOnInit(): void {
    if (this.plan?.gluingPlanID > 0) {
      this.gluelamSpinner = 'gluelamSpinner_' + this.plan?.gluingPlanID;
      this.glueSetSpinner = 'glueSetSpinner_' + this.plan?.gluingPlanID;
      this.getGlueSetsByGluePlanId(this.plan?.gluingPlanID);
    } else if (this.plan?.glueSets.length > 0) {
      this.gluelamSpinner = 'gluelamSpinner_0';
      this.glueSetSpinner = 'glueSetSpinner_0';
      this.glueSets = this.plan?.glueSets as unknown as BeamMaterialGlueSet[];
      this.updateGluePlan();
    }

    this.gluelamStaticalMediatorService.saveGlueplanwithGlueSetSubject$.pipe(takeUntil(this.destroy$)).subscribe(x => {
      if (!this.disableSaveGluesetsButton()) {
        // disable the save all button
        this.gluelamStaticalMediatorService.notifyOnIsGlueplanSaveAllChangesValidChanged(false);
        this.saveGluePlan();
      }
    });
  }

  edit() {
    this.onEditGluePlan.emit(this.plan);
  }

  delete() {
    this.isRemoveGluePlan = true;
  }

  getGlueSetsByGluePlanId(id: number) {
    this.bmgsService.getGlueSetByGluingPlanId(id).pipe(takeUntil(this.destroy$)).subscribe((glueSets: BeamMaterialGlueSet[]) => {
      if (glueSets && glueSets.length > 0) {
        glueSets.forEach((gs) => {
          this.glueSetsIdInDB.push(gs.beamMaterialGlueSetID);
          gs.errorMessageText = this.gluelamStaticalMediatorService.getGluesetValidationErrorMessage(gs.beamMaterialGlueSetID);
        });
        this.glueSets = glueSets.reverse();
      } else {
        this.AddEmptyBmgs();
      }
      this.updateGluePlan();
    });
  }

  updateSelection() {
    let isOptimizerGPSelected = this.gluingPlanService._selectedPlan?.gluingPlanID === 0;
    if (!this.gluingPlanService._selectedPlan || (isOptimizerGPSelected ? this.gluingPlanService._selectedPlan?.gluePlanIndex !== this.plan.gluePlanIndex : this.gluingPlanService._selectedPlan?.gluingPlanID !== this.plan.gluingPlanID)) {
      this.onUpdateSelection.emit(this.plan);
      this.calculateUnits();
    }
  }

  confirmRemove($event) {
    if ($event) {
      this.onDeleteGluePlan.emit(this.plan);
    }
    this.isRemoveGluePlan = false;
  }

  AddEmptyBmgs() {
    const bmgsl = new BeamMaterialGlueSetLayer([]);
    const bmgs = new BeamMaterialGlueSet(
      -1,
      null,
      this.machine.minPressLength,
      false,
      [bmgsl],
      0,
      0,
      null,
      true,
      this.dimension,
      null,
      false,
      false,
      this.conf,
      null,
      ProposalState,
      1
    );
    this.glueSets.unshift(bmgs);
    this.populateState();
  }

  saveGluePlan() {
    this.spinner.show(this.glueSetSpinner);
    this.notificationService.notifyInfoAppChanel('GluePlan.Saving');

    if (this.plan.gluingPlanID <= 0) {
      this.gluingPlanService.addGluingPlan(this.plan).pipe(takeUntil(this.destroy$)).subscribe({
        next: (gp: GluingPlan) => {
          gp.plannedExecutionStartDate = new Date(gp?.plannedExecutionStartDate);
          gp.plannedExecutionEndDate = new Date(gp?.plannedExecutionEndDate);
          this.spinner.hide(this.glueSetSpinner);
          this.plan = gp;
          this.saveGlueSetsToGluingPlan();
        },
        error: (_error) => {
          this.spinner.hide(this.glueSetSpinner);
        }
      });
    } else {
      this.plan.machine = null;
      this.gluingPlanService.updateGluingPlan(this.plan).pipe(takeUntil(this.destroy$)).subscribe({
        next: (gp: GluingPlan) => {
          this.spinner.hide(this.glueSetSpinner);
          this.saveGlueSetsToGluingPlan();
        },
        error: (_error) => {
          this.spinner.hide(this.glueSetSpinner);
        }
      });
    }
  }

  saveGlueSetsToGluingPlan() {
    this.spinner.show(this.gluelamSpinner);
    const updatedGlueset = cloneDeep(this.glueSets.filter(gp => gp.save || gp.remove));
    const glueSets = {
      glueSets: updatedGlueset.reverse().map(set => {
        set.layers.reverse(); return set;
      }),
      existingGlueSetsId: this.glueSetsIdInDB
    };
    this.bmgsService
      .updateBeamMaterialGlueSetForGluingPlan(this.plan?.gluingPlanID, glueSets)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (gpSaveResult) => {
          this.processGlueSetSaveResult(gpSaveResult);
          this.spinner.hide(this.gluelamSpinner);
        }
      );
  }

  deleteGluesetsFromGluingPlan(glueset: BeamMaterialGlueSet, index: number) {
    if (!glueset.beamMaterialGlueSetID || glueset.beamMaterialGlueSetID === -1) {
      this.glueSets.splice(index, 1);
      this.updateGluePlan();
      this.calculateUnits();
      this.ref.detectChanges();
    } else {
      this.bmgsService.removeGlueSet(glueset.beamMaterialGlueSetID, glueset.guid, this.plan.gluingPlanID).pipe(takeUntil(this.destroy$)).subscribe(res => {
        if (res.isSuccsessfull) {
          this.updatedTotalBeamCount = res.glueplanNumberOfBeams;
          this.notificationService.notifySucsessAppChanel(
            'GlueSet.BMGS_Removed'
          );
          this.glueSets = this.glueSets.filter(b => b.guid !== res.guiID);
          this.glueSetsIdInDB = this.glueSetsIdInDB.filter(i => i !== glueset.beamMaterialGlueSetID);
          this.gluelamStaticalMediatorService.removeGluesetValidation(glueset.beamMaterialGlueSetID);
          this.getGluingPlanById(this.plan?.gluingPlanID);
          this.updateGluePlan();
          this.calculateUnits();
          this.ref.detectChanges();
        } else {
          this.notificationService.notifySucsessAppChanel(
            'GlueSet.BMGS_NOT_Removed'
          );
          glueset.errorMessageText = res.errorMessage;
        }
      });
    }
  }

  disableSaveGluesetsButton(): boolean {
    return this.glueSets.filter(gp => gp.save || gp.remove).length === 0;
  }

  calculateUnits() {
    this.gluelamStaticalMediatorService.notifyOnIsGlueplanSaveAllChangesValidChanged(
      this.glueSets.filter(gp => gp.save || gp.remove).length !== 0);
    this.onCalculateUnits.emit(this.glueSets);
  }

  // update length of the selected glueplan
  // with the length of longest beam material line
  updateGluePlan(): void {
    this.plan.length = this.calculateMaxLengthForGluePlan();
    this.plan = { ...this.plan };
    this.glueSets = [...this.glueSets];
    this.gluelamStaticalMediatorService.gluesets = this.glueSets;
  }

  //#region Privatemethods
  private processGlueSetSaveResult(gpSaveResult: IGlueplanGlusetSaveResultDTO) {
    let hasError = false;
    this.updatedTotalBeamCount = gpSaveResult.glueplanNumberOfBeams;
    gpSaveResult.glusetSaveResults.forEach((gp) => {
      const bmgs = this.glueSets.find((g) => g.guid === gp.guiID);
      if (gp.isSuccsessfull && gp.errorMessage) {
        this.notificationService.notifyWarningAppChanel(gp.errorMessage, 'GlueSet.GLUSET_SAVE_ERROR');
        bmgs.errorMessageText = gp.errorMessage;
        bmgs.save = false;
        this.gluelamStaticalMediatorService.saveGluesetValidations(gp);
      }
      else {
        this.gluelamStaticalMediatorService.removeGluesetValidation(gp.glusetID);
        if (gp.errorMessage) {
          this.notificationService.notifyErrorAppChanel(gp.errorMessage, 'GlueSet.GLUSET_SAVE_ERROR');
          hasError = true;
          bmgs.errorMessageText = gp.errorMessage;
          bmgs.save = true;
        } else if (gp.isSuccsessfull) {
          this.gluelamStaticalMediatorService.removeGluesetValidation(gp.glusetID);
          bmgs.errorMessageText = null;
          bmgs.save = false;
        } else {
          this.gluelamStaticalMediatorService.removeGluesetValidation(gp.glusetID);
          bmgs.save = true;
        }
      }

      bmgs.beamMaterialGlueSetID = gp.glusetID;
      if (gp.isSuccsessfull && !this.glueSetsIdInDB.includes(gp.glusetID)) {
        this.glueSetsIdInDB.push(gp.glusetID);
      }
    });
    if (!hasError) {
      this.handleSuccessfulSave();
    }
  }

  private handleSuccessfulSave() {
    this.bmgsService.isDirty = false;
    this.glueSets.forEach((gs)  => {
      gs.save = false;
  })
    this.notificationService.notifySucsessAppChanel('GlueSet.GLUSET_UPDATED');
    this.notificationService.notifyInfoAppChanel('GlueSet.GLUSET_SORTED_INFO');
    this.getGluingPlanById(this.plan?.gluingPlanID);
  }

  private getGluingPlanById(id: number) {
    this.gluingPlanService.getGluingPlan(id).pipe(takeUntil(this.destroy$)).subscribe((plan: GluingPlan) => {
      this.plan = plan;
      this.plan.plannedExecutionStartDate = new Date(plan?.plannedExecutionStartDate);
      this.plan.plannedExecutionEndDate = new Date(plan?.plannedExecutionEndDate);
      this.gluelamStaticalMediatorService.notifyOnGlueSetUpdate(this.plan);
    });
  }

  private calculateMaxLengthForGluePlan(): number {
    let calcLength = 0;

    if (this.glueSets.length > 0) {
      const maxLength = Math.max(...this.glueSets.map(gs => gs.calcMaxLength()));

      calcLength = maxLength;
    }
    return Math.max(this.machine.minPressLength, calcLength);
  }

  private populateState() {
    this.states = [];
    const allState = new BeamMaterialGlueSetState(
      -1,
      'All',
      'All PlaceHolder',
      false,
      []
    );
    this.states.push(allState);
    this.glueSets.forEach((bmgs) => {
      if (
        !this.states.find((x) => x.name === bmgs.beamMaterialGlueSetState.name)
      ) {
        this.states.push(bmgs.beamMaterialGlueSetState);
      }
    });
    this.selectedState = allState;
  }
  //#endregion

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
