
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { AoTDocumentType } from '@app/core/models/aot-document-type.enum';

marker('AoT.SalesOrder');
marker('AoT.ProductionOrderProduce');
marker('AoT.ProductionOrderConsume');
marker('AoT.LogForecast');
marker('AoT.BudgetOrder');
marker('AoT.ForcastOrder');
marker('AoT.StockOrder');
marker('AoT.PlanDataProduce');
marker('AoT.PlanDataConsume');

export  class AoTDocumentTypeEnumHelper {
  static getAoTDocumentTypeEnumTranslations(): { key: AoTDocumentType, text: string }[] {
    return [
      { key: AoTDocumentType.LogForecast, text: 'AoT.LogForecast' },
      { key: AoTDocumentType.ProductionOrderConsume, text: 'AoT.ProductionOrderConsume' },
      { key: AoTDocumentType.ProductionOrderProduce, text: 'AoT.ProductionOrderProduce' },
      { key: AoTDocumentType.SalesOrder, text: 'AoT.SalesOrder' },
      { key: AoTDocumentType.StockOrder, text: 'AoT.StockOrder' },
      { key: AoTDocumentType.ForcastOrder, text: 'AoT.ForcastOrder' },
      { key: AoTDocumentType.PlanDataProduce, text: 'AoT.PlanDataProduce' },
      { key: AoTDocumentType.PlanDataConsume, text: 'AoT.PlanDataConsume' },
    ];
  }
}


