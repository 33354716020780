import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, takeUntil } from 'rxjs';

import { IUnit } from '@app/core/models/unit.model';
import { UnitService } from '@app/core/services/http-services/common/unit-service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker('App.UnitCode');

@Component({
  selector: 'app-select-unit',
  template: `
  <kendo-label *ngIf="showLable" translate>{{label}}</kendo-label>
  <kendo-dropdownlist
    [data]="units"
    textField="unitCode"
    valueField="unitCode"
    [loading]="loading"
    [disabled]="isDisabled"
    [valuePrimitive]="true"
    [(value)]="selectedUnitCode"
    (selectionChange)="onChangedVal($event)"
    class="c">
  </kendo-dropdownlist>
`,
  styleUrls: ['./select-unit.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectUnitComponent
    }
  ]
})
export class SelectUnitComponent implements OnInit, ControlValueAccessor, OnDestroy {
  units: IUnit[] = [];
  selectedUnitCode:string = 'M3';
  loading:boolean = true;

  @Input() label:string = 'App.UnitCode';
  @Input() isDisabled = false;
  @Input() showLable:boolean = false;
  @Input() set selectedUnitCodeInput(unitCode: string) {
    this.selectedUnitCode = unitCode;
  };

  @Output() onUnitChange = new EventEmitter<string>();

  private onChanged: Function = () => {};
  private onTouched: Function = () => {};
  private destroy$ = new Subject<void>();

  constructor(private readonly unitService: UnitService) { }

  ngOnInit(): void {
    this.loadUnit();
  }

  onChangedVal(unit:IUnit) {
    this.selectedUnitCode = unit != null ? unit.unitCode : '';
    this.onUnitChange.emit(this.selectedUnitCode);
    this.onTouched();
    this.onChanged(this.selectedUnitCode);
  }

  writeValue(unitCode: string): void {
    this.selectedUnitCode = unitCode;
  }

  registerOnChange(onChange: any): void {
    this.onChanged = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  private loadUnit() {
    this.unitService.query({}).pipe(takeUntil(this.destroy$)).subscribe(u => {
      this.units = u;
      this.loading = false;
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
