<h1 translate>AoT.AoT</h1>
<form
  [formGroup]="aotSearchBalanceForm">
  <section
    class="app-button-row">
   <app-site-selector
      formControlName="siteId"
      [showLable]="false"
      (onSiteSelected)="onSiteSelected()">
   </app-site-selector>
    <app-select-entity-group
      formControlName="entitygroup"
      [entityTypeCode]="'RESOURCE'"
      (entityGroup)="onSelectEntityGroup()"
      [selectedSiteId]="aotSearchBalanceForm.get('siteId').value"
      [inOperative]="true">
    </app-select-entity-group>
    <kendo-formfield>
      <kendo-label translate>
        AoT.ForecastVolume
      </kendo-label>
      <kendo-switch
        (valueChange)="forecastchange($event)"
        formControlName="showForecast"
        color="primary">
      </kendo-switch>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label translate>
        AoT.StockLimits
      </kendo-label>
      <kendo-switch
        (valueChange)="stockLimitChange($event)"
        formControlName="stockLimits"
        color="primary"
      >
      </kendo-switch>
    </kendo-formfield>
    <kendo-formfield>
      <kendo-label translate>
        AoT.ComparisonColumn
      </kendo-label>
      <kendo-switch
        (valueChange)="orderTypeChange($event)"
        formControlName="orderType"
        color="primary"
      >
      </kendo-switch>
    </kendo-formfield>
    <div style="flex-grow: 3;"></div>
    <kendo-formfield appearance="fill">
      <kendo-label translate>
        AoT.Main
      </kendo-label>
    <app-transaction-type-multi-select
      [showLable]="false"
      [selectedTypes]="selectedTypesMain"
      formControlName="transTypeMain"
      [disableTranstype]="false">
    </app-transaction-type-multi-select>
    </kendo-formfield>
    <kendo-formfield appearance="fill">
      <kendo-label translate>
        AoT.Comparison
      </kendo-label>
    <app-transaction-type-multi-select
      [showLable]="false"
      [selectedTypes]="selectedTypesSecondary"
      formControlName="transTypeSecondary"
      [disableTranstype]="disabletransTypeSecondary">
    </app-transaction-type-multi-select>
    </kendo-formfield>
    <kendo-formfield appearance="fill">
      <kendo-label translate>
        App.Date
      </kendo-label>
      <kendo-datepicker
        formControlName="date"
        [value]="aotSearchBalanceForm.get('date').value | siteDateFormat">
      </kendo-datepicker>
    </kendo-formfield>
    <app-period-selector
      formControlName="period"
      [setPeriodSelectorCriteria]="setPeriodSelectorCriteriaInput"
      style="flex-grow: 2;">
    </app-period-selector>
    <kendo-formfield
      appearance="fill"
      style="flex-grow: 1">
      <kendo-label>
        {{ 'AoT.Grouping' | translate }}
      </kendo-label>
      <kendo-dropdownlist
        formControlName="group"
        [valuePrimitive]="true"
        [data]="group"
        [valueField]="'value'"
        [textField]="'key'">
      </kendo-dropdownlist>
    </kendo-formfield>
    <kendo-formfield
      appearance="fill">
      <kendo-label translate>
        AoT.UOM
      </kendo-label>
      <kendo-dropdownlist
        formControlName="unitCode"
        [popupSettings]="{ popupClass: 'addSeparator' }"
        [valuePrimitive]="true"
        [data]="unitOfMeasurment"
        [valueField]="'unitCode'"
        [textField]="'unitCode'">
      </kendo-dropdownlist>
    </kendo-formfield>
    <div style="flex-grow: 0;">
      <button
        kendoButton
        themeColor="primary"
        (click)="onSubmit()"
        [disabled]="!aotSearchBalanceForm.valid || isSearchDisable">
          {{ 'AoT.Submit' | translate }}
      </button>
    </div>
    <div
      style="flex-grow: 0;">
      <button
        kendoButton
        themeColor="primary"
        (click)="onSaveSettings()">
          {{'App.SaveSetting' | translate}}
      </button>
    </div>
  </section>
</form>
<div class="grid-section">
  <app-aot-grid-main
    [aotBalanceSelectedCriteriaInput]="aotBalanceSelectedCriteriaInput"
    [changeForecastVolume]="isForecastVolumeOn"
    (disableSearchButtonEvent)="disableSearchButton($event)"
    (handleAoTMainGridRowClick)="loadAoTBalanceDetailData($event)"
    (onRefreshRequested)="onSubmit()">
  </app-aot-grid-main>
  <app-aot-grid-detail
    *ngIf="showDetailGrid" (onRefreshRequested)="onSubmit()"
    [aotBalanceDetailSelectedCriteriaInput]="aotBalanceDetailSelectedCriteriaInput">
  </app-aot-grid-detail>
</div>
