<svg:rect
[attr.width]="ch.getWidth(bm.getLength())"
[attr.height]="ch.getHight(bm.getHight())"
[ngStyle]="bm.getColor()"
(contextmenu)="grab($event)"
[attr.y]="0">
</svg:rect>

<svg:rect
*ngIf="bm.takeTestPsc"
[attr.width]="ch.getWidth(bm.testPscLenght)"
[attr.height]="ch.getHight(bm.getHight())"
[attr.x]="ch.getWidth(xOffsetTestPSC)"
class="test-pcs"
></rect>

<svg:g *ngIf="isNormalShape()">
    <svg
        *ngFor="let bml of getLinesToDraw(); let i = index"
        app-beam-material-line
        [bml]="bml"
        [ch]="ch"
        [attr.x]="ch.getWidth(getXforBML(bml,i))"
        [planedThickness]="bm.planedThickness"
        [defaultNoOfBeams]="getDefaultNoOfBeams(bml)"
        [colorLight]="bm.beamMaterialType.colorLight"
        (openBeamMaterialLineMenu)="openBeamMaterialLineMenu($event)"
        [attr.y]="0">
    </svg>
</svg:g>
<svg:g *ngIf="!isNormalShape()">
  <text alignment-baseline="middle" [attr.clip-path]="'url(#clip_' + bm.getLength() + ')'" [attr.y]="12" [attr.x]="5" >
    <tspan>
      {{getTextForManualBeamMaterial()}}
      <tspan x="500" y="15" *ngIf="bm.additionalLamellasTop > 0">
        {{ 'GlueSet.ZulagenTop' | translate:{no: bm.additionalLamellasTop} }}
      </tspan>
      <tspan x="500" y="30" *ngIf="bm.additionalLamellasBottom > 0">
        {{ 'GlueSet.ZulagenBottom' | translate:{no: bm.additionalLamellasBottom} }}
      </tspan>
    </tspan>
  </text>

  <svg>
    <g>
      <foreignObject [attr.x]="ch.getWidth(bm.getLength()) - 120" y="5" [attr.width]="50" [attr.height]="50">
          <app-beam-icon [beamIconId]="bm.beamMaterialLines[0].transportPackageDemand?.beamIconId"></app-beam-icon>
      </foreignObject>
    </g>
  </svg>
</svg:g>
