  <kendo-dialog-titlebar kendo-dialog-title>
    {{ 'Operative.Demand' | translate }}
  </kendo-dialog-titlebar>

  <div class="container">
    <form
        [formGroup]="demandForm"
        class="flex-display flex-direction">
        <div class="col" kendoTooltip position="right" style="align-items: stretch;">
          <div class="row">
              <app-site-selector-v2
                  class="flex-grow-1 k-w-full"
                  [showLable]="true"
                  formControlName="siteId">
              </app-site-selector-v2>
              <app-select-entity
                  class="flex-grow-1 k-w-full"
                  *ngIf="!isEditDemand"
                  formControlName="materialIndex"
                  [entityTypeCode]="'RESOURCE'"
                  [showLable]="true"
                  [getMaterialStandardProperties]="true"
                  [label]="'Operative.SelectMaterial'"
                  (selectedEntity)="setSelectedMaterial($event)">
              </app-select-entity>
              <kendo-formfield *ngIf="isEditDemand" class="k-w-full">
                <kendo-label class="min-width-dropdown">{{ 'App.SelectEntity' | translate}}</kendo-label>
                <kendo-multicolumncombobox
                  [data]="altModels"
                  textField="materialDescription"
                  valueField="index"
                  formControlName="materialIndex" placeholder="{{'EntityTranslation.SearchEntity' | translate}}"
                  [loading]="isloadingAlt"
                  [filterable]="true"
                  (filterChange)="queryAlternativeModels($event)"
                  (selectionChange)="onMaterialSelected($event)" [valuePrimitive]="true">
                  <kendo-combobox-column field="materialDescription" title="{{'EntityTranslation.Description' | translate}}">
                  </kendo-combobox-column>
                  <kendo-combobox-column field="entityGroupDescription"
                    title="{{'EntityTranslation.EntityGroupDescription' | translate}}">
                  </kendo-combobox-column>
                </kendo-multicolumncombobox>
              </kendo-formfield>
          </div>

          <div class="row flex-grow-1">
            <kendo-formfield class="flex-grow-5">
              <kendo-label text="{{ 'OrderViewTranslation.PlanningType' | translate }}"></kendo-label>
              <kendo-dropdownlist [data]="planningTypes" valueField="value" textField="text"
                formControlName="planningType" [valuePrimitive]="true">
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                  {{ dataItem.text | translate}}
                </ng-template>
                <ng-template kendoDropDownListItemTemplate let-dataItem>
                  {{ dataItem.text | translate}}
                </ng-template>
              </kendo-dropdownlist>
            </kendo-formfield>
            <kendo-formfield class="flex-grow-4">
              <kendo-label text="{{ 'OrderViewTranslation.DemandType' | translate }}"></kendo-label>
              <kendo-dropdownlist
               [data]="demandTypes"
               valueField="value"
               textField="text"
               formControlName="demandType"
               (valueChange)="onDemandTypeChange($event)"
               [valuePrimitive]="true">
                <ng-template kendoDropDownListValueTemplate let-dataItem>
                  {{ dataItem.text | translate}}
                </ng-template>
                <ng-template kendoDropDownListItemTemplate let-dataItem>
                  {{ dataItem.text | translate}}
                </ng-template>
              </kendo-dropdownlist>
            </kendo-formfield>
            <kendo-formfield appearance="standard" class="flex-grow-1">
              <kendo-label translate>Stock.IsActive</kendo-label>
              <kendo-switch formControlName="isActive" color="primary" class="margin-top"></kendo-switch>
            </kendo-formfield>
          </div>

          <div
              class="row flex-grow-1">
              <kendo-formfield class="flex-grow-4"
                  appearance="standard">
                  <kendo-label translate>Operative.NewQty</kendo-label>
                  <kendo-numerictextbox
                      formControlName="qty"></kendo-numerictextbox>
              </kendo-formfield>
              <app-select-unit
                  formControlName="qtyUnit"
                  [showLable]="true"
                  [label]="'Operative.UnitCode'"
                  class="flex-grow-1">
              </app-select-unit>
          </div>
          <kendo-formfield>
              <kendo-label>{{ 'CLT.LPD' | translate }}</kendo-label>
              <kendo-datepicker
                  formControlName="latestProductionDate"
                  name="latestProductionDate"
                  required>
              </kendo-datepicker>
          </kendo-formfield>

          <div class="row flex-grow-1">
            <kendo-formfield
              appearance="standard"
              showErrors="initial"
              class="flex-grow-1">
                <kendo-label translate>OrderViewTranslation.Length</kendo-label>
                <kendo-textbox formControlName="length"></kendo-textbox>
            </kendo-formfield>
            <kendo-formfield appearance="standard" class="flex-grow-1">
                <kendo-label translate>OrderViewTranslation.Height</kendo-label>
                <kendo-textbox formControlName="height"></kendo-textbox>
            </kendo-formfield>
            <kendo-formfield appearance="standard" class="flex-grow-1">
                <kendo-label translate>OrderViewTranslation.Width</kendo-label>
                <kendo-textbox formControlName="width"></kendo-textbox>
            </kendo-formfield>
          </div>
          <div class="row">
            <kendo-formfield appearance="standard" class="flex-grow-3">
                <kendo-label translate>OrderViewTranslation.Species</kendo-label>
                <kendo-textbox formControlName="species"></kendo-textbox>
            </kendo-formfield>
            <kendo-formfield appearance="standard" class="flex-grow-3">
              <kendo-label translate>OrderViewTranslation.LamminaPlaneThickness</kendo-label>
              <kendo-combobox
                [allowCustom]="true"
                [data]="planeLaminaThicknessOptions"
                formControlName="defaultLamminaPlaneThickness">
              </kendo-combobox>
            </kendo-formfield>
          </div>
          <div class="row">
            <kendo-formfield appearance="standard" class="flex-grow-3">
              <kendo-label translate>GluePlanPrint.OrderId</kendo-label>
              <kendo-textbox formControlName="orderID"></kendo-textbox>
            </kendo-formfield>
            <kendo-formfield appearance="standard" class="flex-grow-3">
                <kendo-label translate>OrderViewTranslation.OrderRow</kendo-label>
                <kendo-textbox formControlName="orderRowID"></kendo-textbox>
            </kendo-formfield>
          </div>

          <b translate>AppComponent.Glulam</b>
          <div class="flex-grow-5">
            <app-select-beam-material-type
              formControlName="beamMaterialTypeID"
              (selectedBeamMaterialType) = selectedBeamMaterialType($event)>
            </app-select-beam-material-type>
          </div>

          <div class="row flex-grow-1">
            <kendo-formfield appearance="standard" class="flex-grow-1">
              <kendo-label translate>OrderViewTranslation.PlaneProfile</kendo-label>
                <kendo-dropdownlist [data]="planeProfiles" valueField="planeProfileId" textField="code"
                formControlName="planeProfileId" [valuePrimitive]="true" [loading]="loadingTypes">
                </kendo-dropdownlist>
            </kendo-formfield>
          </div>
          <div class="row flex-grow-1">
            <kendo-formfield appearance="standard" class="flex-grow-2">
              <kendo-label translate>OrderViewTranslation.SpecialFormName</kendo-label>
              <kendo-textbox formControlName="specialFormName"></kendo-textbox>
            </kendo-formfield>
            <kendo-formfield appearance="standard" class="flex-grow-2">
              <kendo-label translate>OrderViewTranslation.Radius</kendo-label>
              <kendo-textbox formControlName="radius"></kendo-textbox>
            </kendo-formfield>
          </div>

          <b translate>Operative.ProdOrder</b>

          <kendo-formfield>
              <kendo-label translate>Operative.Instruction
                <kendo-svg-icon
                  themeColor="info"
                  [icon]="infoCircleIcon"
                  title="{{'Operative.InstructionInfoTooltip' | translate}}">
                </kendo-svg-icon>
              </kendo-label>
              <kendo-textarea
                  formControlName="instruction"
                  [rows]="3">
              </kendo-textarea>
          </kendo-formfield>
        </div>
    </form>
  </div>

  <kendo-dialog-actions layout="end">
      <button
          kendoButton
          (click)="onDemandPOSequence()"
          [disabled]="(!demandForm.valid || disableSubmit || isEditDemand)"
          themeColor="primary">
          {{ 'Operative.SaveDemandGeneratePO' | translate }}
      </button>
      <button
          kendoButton
          (click)="onSubmit()"
          [disabled]="(!demandForm.valid || disableSubmit || !isFormValid())"
          themeColor="primary">
          {{ 'Operative.SaveDemand' | translate }}
      </button>
      <button
          kendoButton
          (click)="onCancel()"
          [themeColor]="'error'">
          {{ 'EntityTranslation.Cancle' | translate }}
      </button>
  </kendo-dialog-actions>
