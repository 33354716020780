import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BeamMaterial } from '@app/core/models/beam-material.model';
import { IPrintBeamMaterialDTO } from '@app/core/models/print-beammaterial.model';
import { BaseService } from '@app/core/services/custom-services/base.service';
import { ConfigurationService } from '@app/core/services/custom-services/configuration.service';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { DimensionService } from '@app/core/services/http-services/gluelam/dimension.service';
import { EntityService } from '@app/core/services/http-services/model/entity.service';
import { environment } from '@environment/environment';
import { map, mergeMap, Observable, of } from 'rxjs';

@Injectable()
export class BeamMaterialService extends BaseService {
  private readonly gluplanUri = `${environment.apiUrl}BeamMaterial`;

  constructor(
    conf: ConfigurationService,
    http: HttpClient,
    notificationService: AppNotificationService,
    private readonly entitySimpleService: EntityService,
    private readonly dimensionService: DimensionService,
  ) {
    super(http, conf, notificationService);
  }

  getBeamMaterialLength(beamMaterial: BeamMaterial, machineId: number, lenghtOffset: number): Observable<number> {
    return this.http.post<number>(`${this.gluplanUri}/${machineId}/${lenghtOffset}`, beamMaterial);
  }

  getBeamMaterialByGlueplanId(gluePlanId: number): Observable<IPrintBeamMaterialDTO[]> {
    return this.http
      .get<IPrintBeamMaterialDTO[]>(`${this.gluplanUri}/GetBeamMaterialForPrint/${gluePlanId}`);
  }

  calculateHeight(lamminaPlaneThicknes: number, numberOfLaminas: number) {
    return lamminaPlaneThicknes * numberOfLaminas;
  }

  getWidthFromMaterial(demandMaterialIndex: number, demandBeamLamminaDimensionId: number): Observable<number> {
    return this.entitySimpleService.get(demandMaterialIndex).pipe(
      mergeMap(material => material?.standardProperties?.width
        ? of(material.standardProperties.width)
        : this.dimensionService.getBeamLamminaDimention(demandBeamLamminaDimensionId).pipe(
          map(dimension => dimension?.width ?? 0)
        )
      )
    );
  }
}
