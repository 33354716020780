import { Component, Input } from '@angular/core';
import { ISearchAoTBalanceReportCriteria } from '@app/core/models/SearchAoTBalanceReportCriteria';
import { IAoTBalanceReportDetailDTO } from '@app/core/models/aotGridResultLineDTO.model';
import { DataStateChangeEvent } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { AoTBalanceReportService } from '@app/core/services/http-services/aot/aot-balance-report.service';

@Component({
  selector: 'app-aot-balance-report-detail',
  templateUrl: './aot-balance-report-detail.component.html',
  styleUrls: ['./aot-balance-report-detail.component.css']
})
export class AotBalanceReportDetailComponent {
  gridData: IAoTBalanceReportDetailDTO[];
  public loading: boolean = false;
  unitCode: string = 'M3';
  public gridState: State = {
    skip: 0,
    take: 20,
    filter: {
      logic: 'and',
      filters: []
    }
  };

  @Input() set searchAoTBalanceReportCriteria(searchAoTBalanceReportCriteria: ISearchAoTBalanceReportCriteria) {
    this.gridData = null;
    this.loading = true;
    if (searchAoTBalanceReportCriteria !== undefined && searchAoTBalanceReportCriteria !== null) {
      this.aoTBalanceReportService.searchAoTBalanceReport(searchAoTBalanceReportCriteria).subscribe((result) => {
        this.gridData = result.reportDetails;
        this.loading = false;
      });
    }
  }

  constructor(public aoTBalanceReportService: AoTBalanceReportService) {}

  dataStateChange(state: DataStateChangeEvent): void {
    this.gridState = state;
  }
}
