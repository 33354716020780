import { Component, EventEmitter, Input, Output } from '@angular/core';
import { EntityRelationDirection } from '@app/core/services/http-services/model/entity.service';
import { ProdOrderCreateModelService } from '@app/core/services/http-services/operative/prod-order-create-model.service';
import { AoTBalanceService } from '@app/core/services/http-services/aot/aot-balance.service';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { Guid } from 'guid-typescript';
import { DeamndService } from '@app/core/services/http-services/gluelam/demand.service';
import { IProdOrderCreateInput } from '@app/core/models/prod-order-create-input.model';
import { IDemand } from '@app/core/models/demand-model';

marker('Operative.ProduceProductionOrder');
marker('Operative.ConsumeProductionOrder');
marker('Operative.CreateDemandForMaterial');
marker('Operative.ProduceProductionOrderV2');
marker('Operative.ConsumeProductionOrderV2');

@Component({
  selector: 'app-aot-grid-context-menu',
  templateUrl: './aot-grid-context-menu.component.html',
  styleUrls: ['./aot-grid-context-menu.component.css']
})
export class AotGridContextMenuComponent {
  @Input() target: HTMLDivElement;
  @Input() startDateTime:Date;
  @Input() quantity:number;
  @Input() unitCode:string;
  @Input() entityIndex:number;
  @Input() siteId:Guid;

 @Output() onRefreshRequested = new EventEmitter<boolean>();
 public demand: IDemand;

 aotGridContextMenuItems: any[] = [
   {
     id: 1,
     text: 'Operative.ProduceProductionOrder',
     isDisabled: true
   },
   {
     id: 2,
     text: 'Operative.ConsumeProductionOrder',
     isDisabled: true
   },
   {
     id: 3,
     text: 'Operative.CreateDemandForMaterial',
     isDisabled: false
   }
 ];

 constructor(private readonly prodOrderCreateModelService:ProdOrderCreateModelService
   , public aoTBalanceService: AoTBalanceService
   , public beamOrderRowService: DeamndService
   , public notificationService: AppNotificationService
 ) {
 }

 ngOnChanges() {
   this.setUpMenu();
 }

 onSelectOfMenu(menuId: number) {
   if (menuId === 1 || menuId === 2) {
     const input: IProdOrderCreateInput = {
       productIndex: this.entityIndex,
       startDateTime: this.startDateTime,
       quantity: this.quantity.toString(),
       unitCode: this.unitCode,
       fromAot: true,
       direction: ((menuId === 1) ? EntityRelationDirection.PRODUCE : EntityRelationDirection.CONSUMES),
       siteId: this.siteId
     };

     if (menuId === 1 || menuId === 2) {
      const res = this.prodOrderCreateModelService.openDialog(input);
      this.prodOrderCreateModelService.handleClose(res).subscribe((result) => {
       if (result) {
         this.refresh(result);
       }
     });
    }
   }
   if (menuId === 3) {
     this.demand = <IDemand>{
       materialIndex: this.entityIndex,
       qty: this.quantity,
       latestProductionDate: this.startDateTime,
       demandID: -1,
       qtyUnit: this.unitCode,
       siteId: this.siteId
     };

     this.beamOrderRowService.openDialog(this.demand);
   }
 }

 setUpMenu() {
   this.aotGridContextMenuItems.forEach(x => {
     switch (x.id) {
       case 1:
       case 2:
         x.isDisabled = this.entityIndex <= 0;
         break;
     }
   });
 }

 onClickOfMenuItem(event: any){
  if(!event.item.isDisabled)
    this.onSelectOfMenu(event.item.id)
 }

 refresh(response: boolean) {
   if (response) {
     this.onRefreshRequested.emit(response);
   }
 }
}
