import { Guid } from "guid-typescript";
import { IBeamMaterialType } from "./beam-material-type.model";
import { BeamIconEnum, Demandline } from "./demand-line.model";
import { IPlaneProfile } from "./plane-profile";
import { IDemandProperty, ITransportPackageAllocation } from "../services/http-services/gluelam/demand.service";

export class IDemmandCreator{
  static ToJsonObj(demandLine: Demandline): IDemand {
    return {
      demandID: demandLine.BeamOrderRowID,
      beamOrderRowID: demandLine.BeamOrderRowID,
      orderID: demandLine.orderID,
      orderRowID: demandLine.orderRowID,
      customerDesc: demandLine.customerDesc,
      latestProductionDate: demandLine.latestProductionDate,
      width: demandLine.width,
      hight: demandLine.hight,
      length: demandLine.length,
      qty: demandLine.qty,
      qtyUnit: demandLine.qtyUnit,
      qtyPlanned: demandLine.QtyPlanned,
      numberOfLaminas: demandLine.NumberOfLaminas,
      beamLamminaDimensionID: demandLine.BeamLamminaDimensionID,
      qtyReproduce: demandLine.QtyReproduce,
      defaultLamminaPlaneThickness: demandLine.DefaultLamminaPlaneThickness,
      lamminaWidth: demandLine.LamminaPlaneWidth,
      deafultNumberOfBeamsPerBeamMaterial: demandLine.deafultNumberOfBeamsPerBeamMaterial,
      beamMaterialType: demandLine.beamMaterialType,
      beamMaterialTypeID : demandLine.beamMaterialType.beamMaterialTypeID,
      color: demandLine.Color,
      constructionCertification: demandLine.ConstructionCertification,
      externalItemID: demandLine.ExternalItemID,
      materialIndex: demandLine.materialIndex,
      materialDescription: demandLine.materialDescription,
      materialCode: demandLine.materialCode,
      species: demandLine.species,
      siteId: demandLine.siteId,
      radius: demandLine.radius,
      specialFormName: demandLine.specialFormName,
      demandType: demandLine.demandType,
      isActive: demandLine.isActive,
      planningType: demandLine.planningType,
      rawLamellaThickness: demandLine.rawLamellaThickness,
      glueplans: demandLine.gluingPlandIds,
      lamminaThickness:demandLine.rawLamellaThickness,
      planeProfileId:demandLine.planeProfile?.planeProfileId,
      transportPackageAllocation: demandLine.transportPackageAllocation,
      transportPackageAllocations:demandLine.transportPackageAllocations,
      projectName: null,
      propertys : null,
      guid:null,
      orderIDOrderRowId:null,
      orderStateDesc:null,
      isPlanable:null,
      demandValue:null,
      beamMaterialCombinationCode:null,
      entityGroupCode : null,
      glusetCombinationCode: null,
    };
  }
}


export interface IDemandDTO{
  activityIndex: number;
  beamIconId?: BeamIconEnum;
  beamLamminaDimensionID: number | null;
  beamMaterialType: IBeamMaterialType;
  color: string;
  constructionCertification: string;
  consumedBML: number;
  createDate?: Date;
  customerDesc: string;
  customerNo: string;
  deafultNumberOfBeamsPerBeamMaterial: number;
  demandId: number;
  demandSpecificationsCount: number;
  demandType: number;
  defaultLamminaPlaneThickness: number | null;
  externalItemId: string;
  gluingPlandIds: string;
  hight: number;
  isActive: boolean;
  isSpecialFormBeam?: boolean;
  lamminaWidth: number;
  latestProductionDate: string;
  length: number;
  mainProduct: string;
  materialCode: string;
  materialDescription: string;
  materialIndex: number;
  millExitDate: Date;
  numberOfLaminas: number | null;
  orderComment: string;
  orderID: string;
  orderRowComment: string;
  orderRowID: string;
  pickingInst: string;
  planeProfile: IPlaneProfile;
  planeProfileCode: string;
  planeProfileDesc: string;
  plannedProductionDate: Date;
  planningType: number;
  planState: string;
  prodOrderCount: number;
  prodOrderId: number;
  qty: number;
  qtyProduced: number | null;
  qtyReproduce: number | null;
  qtyUnit: string;
  radius?: number;
  rawLamellaThickness: number;
  siteId: Guid;
  siteName: string;
  specialFormName?: string;
  species: string;
  thickness: string;
  transportPackageCode: string;
  transportPackageId: number | null;
  transportPackageQty: number | null;
  transportPackageQtyProduced: number | null;
  transportPackageQtyReproduce: number | null;
  transportPackageTotalQty: number | null;
  trimmingLocation?: string;
  visualQuality: string;
  volumeM3: number;
  width: number;
}

export interface IDemand {
  beamLamminaDimensionID: number | null;
  beamMaterialCombinationCode: number;
  beamMaterialType: IBeamMaterialType;
  beamMaterialTypeID: number | null;
  beamOrderRowID: number;
  color: string;
  constructionCertification: string;
  customerDesc: string;
  deafultNumberOfBeamsPerBeamMaterial: number | null;
  defaultLamminaPlaneThickness: number | null;
  demandID: number;
  demandType: number;
  demandValue: number | null;
  entityGroupCode: string;
  externalItemID: string;
  glusetCombinationCode: number;
  glueplans: string;
  guid: string;
  hight: number;
  isActive: boolean;
  isPlanable: boolean;
  lamminaThickness: number | null;
  lamminaWidth: number | null;
  latestProductionDate: Date;
  length: number;
  materialCode: string;
  materialDescription: string,
  materialIndex: number | null;
  numberOfLaminas: number | null;
  orderID: string;
  orderIDOrderRowId: string;
  orderRowID: string;
  orderStateDesc: string;
  planeProfileId: number;
  planningType: number;
  projectName: string;
  propertys: IDemandProperty[];
  qty: number;
  qtyPlanned: number;
  qtyReproduce: number | null;
  qtyUnit: string;
  radius: number;
  rawLamellaThickness: number;
  siteId: Guid | null;
  specialFormName: string;
  species: string;
  transportPackageAllocation: ITransportPackageAllocation;
  transportPackageAllocations: ITransportPackageAllocation[];
  width: number;
}

export interface IDemandPartialDTO {
  demandID: number;
  lamminaPlaneThickness: number;
  lamminaPlaneWidth: number;
  beamLamminaDimensionId: number;
  numberOfLamminas: number;
  materialIndex: number;
  numberOfBeamsPerBeamMaterial: number;
  materialDescription: string;
  qtyReproduce: number;
  transportPackageId: number;
  beamMaterialTypeId : number;
}
