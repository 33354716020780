import { BeamMaterialType } from "@app/core/models/beam-material-type-enum";
import { IBeamMaterialType } from '@app/core/models/beam-material-type.model';

export class BeamMaterialTypeHelper {
  public static isManualShape(bm: IBeamMaterialType): boolean {
      switch (bm.externalId) {
         case BeamMaterialType.ANGELD_HEIGHT_SLICE:
         case BeamMaterialType.ANGELD_CUT:
         case BeamMaterialType.ANGLED_HEIGHT_SPLIT:
         case BeamMaterialType.WIDTH_SPLIT:
            return true;
         default:
            return false;
         }
  }

  public static isUndefinedShape(bm: IBeamMaterialType): boolean {
      return bm.externalId === BeamMaterialType.UNKNOWN;
  }

  public static isNormalShape(bm: IBeamMaterialType): boolean {
      switch (bm.externalId) {
         case BeamMaterialType.NORMAL:
         case BeamMaterialType.KVH:
            return true;
         default:
            return false;
         }
  }

  public static isStraightShape(bm: IBeamMaterialType): boolean {
   switch (bm.externalId) {
      case BeamMaterialType.NORMAL:
      case BeamMaterialType.KVH:
      case BeamMaterialType.ANGELD_HEIGHT_SLICE:
      case BeamMaterialType.ANGELD_CUT:
      case BeamMaterialType.ANGLED_HEIGHT_SPLIT:
      case BeamMaterialType.WIDTH_SPLIT:
         return true;
      default:
         return false;
      }
   }

   public static isSpecialShape(bm: IBeamMaterialType): boolean {
      return bm.externalId === BeamMaterialType.SPECIAL_FORM
   }
}
