import { HttpClient, HttpParams } from '@angular/common/http';

import { DateHelper } from '@app/shared/helpers/date-helper';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { GridDataResultTyped } from '@app/core/models/general/grid-data-result-typed.model';
import { IProdOrderListResponseDTO } from '@app/core/models/prod-order';
import { Injectable } from '@angular/core';
import { KendoGridService } from '@app/core/services/custom-services/kendo-grid.service';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';
import { Observable } from 'rxjs';
import { State } from '@progress/kendo-data-query';
import { environment } from 'src/environments/environment';
import { marker } from '@colsen1991/ngx-translate-extract-marker';

marker('Operative.ProdOrderSchedulerDateOverlappingError');

@Injectable({
  providedIn: 'root'
})
export class ProdOrderQueryService {

  constructor(private http: HttpClient,
    private readonly kendoGridService: KendoGridService,
    private readonly localStorageService: LocalStorageService
  ) {}

  query(state: State): Observable<GridDataResultTyped<IProdOrderListResponseDTO>> {
    const stateFilter = this.kendoGridService.getStateFilter(state);

    let params = new HttpParams()
      .set('skip', stateFilter.skip.toString())
      .set('take', stateFilter.take.toString());

    stateFilter.filter.forEach(element => {
      if (element.code === 'stateId') {
        const stateIds = element.value.split(',');
        stateIds.forEach(stateId => {
          params = params.append('stateId', stateId);
        });
      } else if (element.code === 'machineId') {
        const machineIds = element.value.split(',');
        machineIds.forEach(machineId => {
          params = params.append('machineIds', machineId);
        });
      } else if (element.code === 'startDate') {
        params = params.append('plannedExecutionStartDate', DateHelper.fromDateISOFormat(new Date(element.value)));
      } else if (element.code === 'endDate') {
        params = params.append('plannedExecutionEndDate', DateHelper.toDateISOFormat(new Date(element.value)));
      } else {
        params = params.append(element.code, element.value);
      }
    });

    if (stateFilter.sort) {
      params = params.append('sortColumn', stateFilter.sort.sortColumn);
      params = params.append('sortDirection', stateFilter.sort.sortDirection);
    };

    params = params.append('siteId', this.localStorageService.getItem(LocalStorageService.selectedSiteId));

    return this.http
      .get<GridDataResult>(`${environment.apiUrl}ProdOrder/getProdOrders`, { params });
  }
}
