<h1 translate>AoT.LogForecast</h1>

<section class="app-button-row">
  <div style="flex-grow: 1;">
    <form
      [formGroup]="siteIdFilterForm"
      class="form">
      <app-site-selector
        formControlName="siteId"
        [showLable]="false"
        (onSiteSelected)="loadData()">
      </app-site-selector>
    </form>
  </div>
  <button
    kendoButton
    themeColor="primary"
    type="button"
    (click)="addLogForecastRow()">
    {{ 'App.AddNew' | translate }}
  </button>
</section>

<kendo-grid #logForecastEditorGrid
  [data]="gridLogForecastData"
  class="crud-grid"
  [pageSize]="gridState.take"
  [skip]="gridState.skip"
  [pageable]="true"
  [resizable]="true"
  (edit)="editHandler($event)"
  (cancel)="cancelHandler($event)"
  (save)="saveHandler($event)"
  (remove)="removeHandler($event)"
  [size]="'small'">

<kendo-grid-column
  field="noOfDays"
  title="{{ 'AoT.NoOfDays' | translate }}">
  <ng-template
    kendoGridEditTemplate
    let-formGroup="formGroup">
    <kendo-numerictextbox format="n" [formControl]="formGroup.get('noOfDays')" [min]="1" [max]="365"></kendo-numerictextbox>
  </ng-template>
</kendo-grid-column>

<kendo-grid-column
  field="activityIndex"
  title="{{ 'Operative.Activity' | translate }}"
  [editable]= "true">
    <ng-template
      kendoGridCellTemplate let-dataItem>
      {{dataItem.activity.description}}
    </ng-template>
    <ng-template kendoGridEditTemplate let-formGroup="formGroup" let-isNew="isNew">
        <app-select-entity [formControl]="formGroup.get('activityIndex')"
                           [entityTypeCode]="'ACTIVITY'"
                           [showLable]="false"
                           [label]="'Operative.SelectMaterial'"
                          >
      </app-select-entity>
    </ng-template>
</kendo-grid-column>

<kendo-grid-column
  field="useStockForecast"
  title="{{ 'AoT.useStockForecast' | translate }}">
  <ng-template
    kendoGridCellTemplate
    let-dataItem>
      {{ (dataItem.useStockForecast ? 'App.Yes' : 'App.No')  | translate}}
  </ng-template>
  <ng-template
    kendoGridEditTemplate
    let-formGroup="formGroup">
    <kendo-switch [formControl]="formGroup.get('useStockForecast')"></kendo-switch>
  </ng-template>
</kendo-grid-column>

<kendo-grid-column
  field="noOfStockDaysLookup"
  title="{{ 'AoT.noOfStockDaysLookup' | translate }}">
  <ng-template
    kendoGridEditTemplate
    let-formGroup="formGroup">
    <kendo-numerictextbox format="n" [formControl]="formGroup.get('noOfStockDaysLookup')" [min]="1" [max]="365"></kendo-numerictextbox>
  </ng-template>
</kendo-grid-column>

<kendo-grid-column
field="noOfStockDaysGen"
title="{{ 'AoT.noOfStockDaysGen' | translate }}">
  <ng-template
    kendoGridEditTemplate
    let-formGroup="formGroup">
    <kendo-numerictextbox format="n" [formControl]="formGroup.get('noOfStockDaysGen')" [min]="1" [max]="365"></kendo-numerictextbox>
  </ng-template>
</kendo-grid-column>

<kendo-grid-column
field="removeOutLiers"
title="{{ 'AoT.removeOutLiers' | translate }}">
  <ng-template
    kendoGridCellTemplate
    let-dataItem>
    {{ (dataItem.removeOutLiers ? 'App.Yes' : 'App.No')  | translate}}
  </ng-template>
  <ng-template
    kendoGridEditTemplate
    let-formGroup="formGroup">
    <kendo-switch [formControl]="formGroup.get('removeOutLiers')"></kendo-switch>
  </ng-template>
</kendo-grid-column>

<kendo-grid-command-column title="{{ 'Operative.Command' | translate }}"
   [width]="350">
    <ng-template
      kendoGridCellTemplate
      let-isNew="isNew"
      let-dataItem>
      <button
        kendoGridEditCommand
        themeColor="primary">
        {{ 'App.Edit' | translate }}
      </button>
      <button
        kendoGridRemoveCommand
        themeColor="error"
        translate>
        {{ 'App.Remove'  | translate }}
      </button>
      <button
        kendoGridSaveCommand
        [disabled]="formGroup?.invalid"
        translate themeColor="primary">
        {{ (isNew ? "App.Add" : "App.Update")  | translate  }}
      </button>
      <button
        kendoGridCancelCommand
        translate themeColor="error">
        {{ 'App.Cancel'  | translate  }}
      </button>
      <button
      kendoButton
      [disabled]="isNew || formGroup"
      themeColor="primary"
      translate
      (click)="addRefreshForecastData(dataItem)">
      {{ 'App.AddAndRefreshCurrentData' | translate }}
    </button>
    </ng-template>
</kendo-grid-command-column>
</kendo-grid>

<app-confirmation-dialog
    *ngIf="logForecastIdToRemove"
    (conformationDialogCloseEvent)="confirmRemove($event)">
</app-confirmation-dialog>

