import { Component, Input } from '@angular/core';
import { BeamIconEnum } from '@app/core/models/demand-line.model';

@Component({
  selector: 'app-beam-icon',
  template: `
  <svg width="100%" [attr.height]="height" viewBox="0 0 100 31" >
    <g  *ngIf="selectedBeamIconId === beamIconEnum.Straight">
      <rect x=1.5 y=1.5 width="95" height="27" class="beamIcon" />
    </g>
    <g  *ngIf="selectedBeamIconId === beamIconEnum.Arched">
      <path d="M 0 10 Q 50 1.5 100 10 L 100 28 Q 50 18 0 28 L 0 10"  class="beamIcon"/>
    </g>
    <g  *ngIf="selectedBeamIconId === beamIconEnum.FishBelly">
      <path d="M 0 1.5 L 100 1.5 L 100 13 Q 50 29.5 0 13 L 0 1.5"  class="beamIcon"/>
    </g>
    <g  *ngIf="selectedBeamIconId === beamIconEnum.GableRoof">
      <path d="M 0 10 L 50 1.5 L 100 10 L 100 28 Q 50 16 0 28 L 0 10"  class="beamIcon"/>
    </g>
    <g  *ngIf="selectedBeamIconId === beamIconEnum.SpecialForm">
      <path d="M 0 8 Q 25 -6, 50 8 T 100 8 L 100 22 Q 75 36, 50 22 T 0 22 L 0 8" class="beamIcon"/>
    </g>
    <g  *ngIf="selectedBeamIconId === beamIconEnum.StraightWithElevation">
      <text x=45 y=13>&Uuml;</text>
      <rect x=1.5 y=15.5 width="94" height="13.5" class="beamIcon" />
    </g>
  </svg>
  `,
  styles: ['.beamIcon { fill:orange;stroke:black;stroke-width:3px; }']
})
export class BeamIconComponent {
  selectedBeamIconId: BeamIconEnum;

  @Input() set beamIconId(beamIconId: BeamIconEnum | null) {
      if (beamIconId != undefined) {
          this.selectedBeamIconId = beamIconId;
      }
  }

  @Input() height:number = 27
   width: number = 100;
  beamIconEnum:typeof BeamIconEnum = BeamIconEnum;
}
