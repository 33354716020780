<kendo-dialog-titlebar kendo-dialog-title>
  <div translate>OrderViewTranslation.BulkEdit</div>
</kendo-dialog-titlebar>
<form [formGroup]="updateDemandForm" style="height: 98%;">
  <div style="height: calc(100% - 80px);display: contents;">
    <section class="edit-section" formGroupName="latestProductionDate">
      <kendo-switch formControlName="enable" color="primary">
      </kendo-switch>
      <kendo-formfield>
        <kendo-label text="{{ 'OrderViewTranslation.LatestProductionDate' | translate }}"></kendo-label>
        <kendo-datepicker formControlName="value" [format]="dateFormat"></kendo-datepicker>
      </kendo-formfield>
    </section>
    <section class="edit-section" formGroupName="plannedProductionDate">
      <kendo-switch formControlName="enable" color="primary">
      </kendo-switch>
      <kendo-formfield>
        <kendo-label text="{{ 'OrderViewTranslation.PlannedProductionDate' | translate }}"></kendo-label>
        <kendo-datepicker formControlName="value" [format]="dateFormat"></kendo-datepicker>
      </kendo-formfield>
    </section>
    <section class="edit-section" formGroupName="isActive">
      <kendo-switch formControlName="enable" color="primary">
      </kendo-switch>
      <kendo-formfield>
        <kendo-label text="{{ 'Stock.IsActive' | translate }}"></kendo-label>
        <kendo-dropdownlist [data]="isActiveItems" [valueField]="'value'" [textField]="'text'"
        formControlName="value" [valuePrimitive]="true">
        <ng-template kendoDropDownListValueTemplate let-dataItem>
          {{ dataItem.text | translate}}
        </ng-template>
        <ng-template kendoDropDownListItemTemplate let-dataItem>
          {{ dataItem.text | translate}}
        </ng-template>
      </kendo-dropdownlist>
      </kendo-formfield>
    </section>
    <section class="edit-section"  formGroupName="demandType">
      <kendo-switch formControlName="enable" color="primary">
      </kendo-switch>
      <kendo-formfield>
        <kendo-label text="{{ 'OrderViewTranslation.DemandType' | translate }}"></kendo-label>
        <kendo-dropdownlist [data]="demandTypes" [valueField]="'value'" [textField]="'text'"
          formControlName="value" [valuePrimitive]="true">
          <ng-template kendoDropDownListValueTemplate let-dataItem>
            {{ dataItem.text | translate}}
          </ng-template>
          <ng-template kendoDropDownListItemTemplate let-dataItem>
            {{ dataItem.text | translate}}
          </ng-template>
        </kendo-dropdownlist>
      </kendo-formfield>
    </section>
  </div>
  <kendo-dialog-actions  layout="end">
    <button kendoButton themeColor="error" type="button" (click)="close()">
      {{"App.Close" | translate }}
    </button>
    <button kendoButton themeColor="primary" type="button" (click)="save()" class="float-right" [disabled]="!updateDemandForm.valid">
      {{"App.Submit" | translate}}
    </button>
  </kendo-dialog-actions>

</form>
