<kendo-grid
  [kendoGridBinding]="gridData"
  [selectable]="true"
  [reorderable]="true"
  [resizable]="true"
  [filterable]="true"
  [pageable]="true"
  [pageSize]="gridState.take"
  [skip]="gridState.skip"
  [filter]="gridState.filter"
  [loading]="loading"
  [size]="'small'"
  (dataStateChange)="dataStateChange($event)">
  <kendo-grid-column field="entityGroupDescription" [filterable]="false" title="{{ 'EntityTranslation.EntityGroup' | translate }}" [width]="25"
    [style]="{'text-align': 'left'}">
  </kendo-grid-column>
  <kendo-grid-column field="entityDescription" [filterable]="false" title="{{ 'Operative.Material' | translate }}" [width]="25" [style]="{'text-align': 'left'}">
  </kendo-grid-column>
  <kendo-grid-column field="forecastDate" [filterable]="false" title="{{ 'App.StartDate' | translate }}" [width]="25" >
    <ng-template kendoGridCellTemplate let-dataItem>
      <div>{{ dataItem.forecastDate | siteDateFormat }}</div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="forcastVolume" title="{{ 'Stock.ForecastStock' | translate }}" [filterable]="true" [width]="25" [style]="{'text-align': 'left'}" >
    <ng-template kendoGridCellTemplate let-dataItem>
      <div>
        {{dataItem.forcastVolume}} {{unitCode}}
      </div>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-numeric-filter-cell [column]="column"  [showOperators]="false" [filter]="filter" operator="lte">
      </kendo-grid-numeric-filter-cell>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>
