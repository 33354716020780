import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AdditionalInstructionsDisplayComponent } from './additional-instructions-display/additional-instructions-display.component';
import { AotBalanceComponent } from './aot-balance/aot-balance.component';
import { AotBalanceReportComponent } from '@app/modules/aot/aot-balance-report/aot-balance-report.component';
import { AotBalanceReportDetailComponent } from '@app/modules/aot/aot-balance-report-detail/aot-balance-report-detail.component';
import { AotDemandBulkUpdateComponent } from './aot-demand-bulk-update/aot-demand-bulk-update.component';
import { AotDemandComponent } from './aot-demand/aot-demand.component';
import { AotGridContextMenuComponent } from './aot-grid-context-menu/aot-grid-context-menu.component';
import { AotGridDetailComponent } from './aot-grid-detail/aot-grid-detail.component';
import { AotGridMainComponent } from './aot-grid-main/aot-grid-main.component';
import { AotRoutingModule } from './aot-routing.module';
import { AppControlsModule } from '../app-controls/app-controls.module';
import { BeamMaterialTypeFilterComponent } from '@app/core/components/beam-material-type-filter/beam-material-type-filter.component';
import { CommonModule } from '@angular/common';
import { CreateMultiStepGlulamSequenceComponent } from './create-multi-step-glulam-sequence/create-multi-step-glulam-sequence.component';
import { DemandFormComponent } from './demand-form/demand-form.component';
import { DemandInfoComponent } from './demand-info/demand-info.component';
import { DemandSpecificationDialogComponent } from './demand-specification-dialog/demand-specification-dialog.component';
import { KendoImportModule } from '@app/core/kendo-import.module';
import { LogForecastComponent } from './log-forecast/log-forecast.component';
import { MsalModule } from '@azure/msal-angular';
import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  declarations: [
    AotBalanceComponent,
    AotGridMainComponent,
    AotGridDetailComponent,
    AotGridContextMenuComponent,
    AotDemandComponent,
    LogForecastComponent,
    DemandFormComponent,
    AotDemandBulkUpdateComponent,
    DemandInfoComponent,
    AdditionalInstructionsDisplayComponent,
    AotBalanceReportComponent,
    AotBalanceReportDetailComponent,
    CreateMultiStepGlulamSequenceComponent,
    DemandSpecificationDialogComponent,
    BeamMaterialTypeFilterComponent
  ],
  imports: [

    CommonModule,
    AotRoutingModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    KendoImportModule,
    MsalModule,
    AppControlsModule,
    SharedModule
  ],
  exports: [
    DemandFormComponent
  ]
})
export class AotModule { }
