import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ISearchAoTBalanceReportCriteria } from '@app/core/models/SearchAoTBalanceReportCriteria';
import { IAoTBalanceReportDTO } from '@app/core/models/aotGridResultLineDTO.model';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../../custom-services/localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class AoTBalanceReportService {
  constructor(private http: HttpClient
    , private localStorageService: LocalStorageService) {
  }

  searchAoTBalanceReport(criteria:ISearchAoTBalanceReportCriteria): Observable<IAoTBalanceReportDTO> {
    const params = new HttpParams()
      .set('entityGroupIndex', criteria.entityGroupIndex.toString())
      .set('periodColumn', criteria.periodColumn.toString())
      .set('unitOfMeasure', criteria.unitOfMeasure.toString())
      .set('periodCount', criteria.periodCount.toString())
      .set('isDetailGridRequest', criteria.isDetailGridRequest.toString())
      .set('siteId', this.localStorageService.getItem(LocalStorageService.selectedSiteId).toString());

    return this.http.get<IAoTBalanceReportDTO>(`${environment.apiUrl}AoTBalanceReport/SearchAoTBalanceReport`, { params });
  }
}
