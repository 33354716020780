import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ISearchAoTBalanceReportCriteria } from '@app/core/models/SearchAoTBalanceReportCriteria';
import { IAoTBalanceReportDataDTO, IAoTBalanceReportDetailDTO } from '@app/core/models/aotGridResultLineDTO.model';
import { IAoTGridResultPeriodDTO } from '@app/core/models/aotGridResultPeriodDTO.model';
import { CellClickEvent } from '@progress/kendo-angular-grid';
import { State } from '@progress/kendo-data-query';
import { AoTBalanceReportService } from '@app/core/services/http-services/aot/aot-balance-report.service';

@Component({
  selector: 'app-aot-balance-report',
  templateUrl: './aot-balance-report.component.html',
  styleUrls: ['./aot-balance-report.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AotBalanceReportComponent implements OnInit {
  // Parent grid
  periods: IAoTGridResultPeriodDTO[] = [];
  loading: boolean = false;
  aoTBalanceReports: IAoTBalanceReportDataDTO[] = [];
  aoTBalanceReportdetails: IAoTBalanceReportDetailDTO[] = [];
  selectedCellUniqueId:string;
  searchAoTBalanceReportCriteria : ISearchAoTBalanceReportCriteria;

  showDetailGrid: boolean = false;
  gridState: State = {
    skip: 0,
    take: 20
  };

  constructor(public aoTBalanceReportService: AoTBalanceReportService
  ) { }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.loading = true;
    const criteria = this.getSearchAoTBalanceReportCriteria(false, 0, 0);
    this.aoTBalanceReportService.searchAoTBalanceReport(criteria).subscribe(result => {
      this.aoTBalanceReports = result.report;
      this.periods = result.periods;
      this.loading = false;
    });
  }

  public cellClickHandler(args: CellClickEvent): void {
    if (!args.isEdited) {
      this.showDetailGrid = true;
      this.searchAoTBalanceReportCriteria = this.getSearchAoTBalanceReportCriteria(true, args.dataItem.entityGroupIndex, args.column.title.replace('W', ''));
    }
  }

  getSearchAoTBalanceReportCriteria(isDetailGridRequest, entityGroupIndex, periodColumn) {
    return <ISearchAoTBalanceReportCriteria> {
      unitOfMeasure: 'M3',
      periodCount: 5,
      isDetailGridRequest,
      periodColumn,
      entityGroupIndex
    };
  }

  onCellClick(dataItem: any, rowIndex: number, columnIndex: number) {
    this.selectedCellUniqueId = dataItem.entityGroupDescription + '_' + rowIndex + '_' + columnIndex;
  }

  onSiteChange() {
    this.loadData();
  }
}
