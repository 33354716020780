import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-additional-instructions-display',
  templateUrl: './additional-instructions-display.component.html',
  styleUrls: ['./additional-instructions-display.component.css']
})
export class AdditionalInstructionsDisplayComponent {
  public showPopup = false;
  public instructionsCount: number = 0;
  public isInstructionsCount: boolean = false;
  public _instructions: any;
  @Input() set instructions(instructions: any) {
    this.instructionsCount = Object.keys(instructions).length;
    this.isInstructionsCount = this.instructionsCount < 1;
    this._instructions = instructions;
  };

  constructor() { }

  public onToggle(): void {
    this.showPopup = !this.showPopup;
  }
}
