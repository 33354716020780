
export enum AoTDocumentType {
  SalesOrder,
  ProductionOrderProduce,
  LogForecast,
  BudgetOrder,
  ForcastOrder,
  StockOrder,
  ProductionOrderConsume,
  PlanDataProduce,
  PlanDataConsume
}

