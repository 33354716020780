import { Component, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { IPeriodSelector } from '@app/core/models/periodSelector.model';
import { EnumToObject } from '@app/shared/helpers/transform-enum-to-object.helper';
import { ISetPeriodSelectorCriteria } from '@app/core/models/setPeriodSelectorCriteria';
import { AoTPeriodTypeEnum } from '@app/core/models/aot-period-type.enum';
import { IEnumKeyValue } from '@app/core/models/enumKeyValue.model';

@Component({
  selector: 'app-period-selector',
  template: `
      <kendo-formfield style="margin-right: 10px;" appearance="fill">
        <kendo-label translate>AoT.Period</kendo-label>
        <kendo-dropdownlist
                [(value)]="selPeriod.periodType"
                 [valuePrimitive]="true"
                [data]="periodType"
                [itemDisabled]="isPeriodDisabled"
                [valueField]="'value'"
                [textField]="'key'"
                (selectionChange)="onChangedVal($event)"></kendo-dropdownlist>
      </kendo-formfield>
      <kendo-formfield appearance="fill">
        <kendo-label translate>AoT.PeriodCount</kendo-label>
        <kendo-dropdownlist
                [(value)]="selPeriod.periodCount"
                 [valuePrimitive]="true"
                [data]="periodCount"
                [valueField]="'value'"
                [textField]="'key'"
                (selectionChange)="onChangedVal($event)"></kendo-dropdownlist>
      </kendo-formfield>
    `,
  styleUrls: ['./period-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: PeriodSelectorComponent
    },
    EnumToObject
  ]
})
export class PeriodSelectorComponent implements OnInit, ControlValueAccessor {
  selPeriod: IPeriodSelector = { periodCount: 1, periodType: 1 };
  periodType: IEnumKeyValue[] = [];
  periodCount = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  private onTouched!: Function;
  private onChanged!: Function;
  periodSelectedCriteria:ISetPeriodSelectorCriteria = null;

  constructor(private readonly enumToObject: EnumToObject) {
  }

  @Input() set setPeriodSelectorCriteria(criteria: ISetPeriodSelectorCriteria) {
    if (criteria !== undefined) {
      this.setPeriodCriteria(criteria);
      this.getPeriodType();
    }
  };

  ngOnInit(): void {
    this.getPeriodType();
  }

  onChangedVal($event) {
    this.onTouched(this.selPeriod);
    this.onChanged(this.selPeriod);
  }

  private getPeriodType() {
    this.periodType = this.enumToObject.transform(AoTPeriodTypeEnum);
  }

  public periodSelected(event: any) {
    console.log(event);
  }

  public periodCountSelected(event: any) {
    console.log(event);
  }

  writeValue(value: IPeriodSelector) {
    if (value) {
      this.selPeriod = value;
    }
  }

  registerOnChange(onChange: any) {
    this.onChanged = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setPeriodCriteria(criteria:ISetPeriodSelectorCriteria) {
    this.periodSelectedCriteria = { ...criteria };
  }

  isPeriodDisabled(itemArgs: { dataItem: any; index: number }) {
    return itemArgs.dataItem.disable;
  }
}
