<h1 translate>
  AppComponent.Demand_Report
</h1>
<div class="grid-wrapper">
  <kendo-grid
      [columnMenu]="false"
      [data]="beamOrderLinesGridData"
      [filter]="state.filter"
      [group]="state.group"
      [groupable]="false"
      [pageable]="true"
      [pageSize]="state.take"
      [reorderable]="true"
      [resizable]="true"
      [selectable]="true"
      [skip]="state.skip"
      [sort]="state.sort"
      [style]="{'height': 'calc(100vh - 10em)'}"
      [sortable]="true"
      (dataStateChange)="dataStateChange($event)"
      [filterable]="true"
      [kendoGridSelectBy]="'BeamOrderRowID'"
      [(selectedKeys)]="beamOrderRowIds"
      [size]="'small'"
      [loading]="loading"
  >

      <ng-template kendoGridToolbarTemplate>
          <button
              type="button"
              kendoGridExcelCommand
              themeColor="secondary"
              [svgIcon]="fileExcelIcon">
              {{ 'OrderViewTranslation.ExportToExcel' | translate }}
          </button>
          <button
              kendoButton
              themeColor="primary"
              (click)="createPO()"
              [disabled]="(beamOrderRowIds.length === 0)">
              {{'OrderViewTranslation.AutoGeneratePO' | translate}}
          </button>
          <button
              kendoButton
              themeColor="primary"
              (click)="onNewDemand()">
          {{'App.New' | translate}}
          </button>
          <button
              kendoButton
              themeColor="primary"
              (click)="bulkEdit()"
              [disabled]="beamOrderRowIds.length === 0">
              {{'OrderViewTranslation.BulkEdit' | translate}}
          </button>
        </ng-template>
        <kendo-grid-checkbox-column
         [showSelectAll]="true"
         class="text-center"
         headerClass="text-center"
         [width]="50">
        </kendo-grid-checkbox-column>
        <kendo-grid-column field="siteId" title="{{ 'App.Site'| translate }}" [width]="150" >
          <ng-template kendoGridFilterCellTemplate let-filter>
            <app-site-filter [filter]="filter">
            </app-site-filter>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{ dataItem.siteName }}</span>
        </ng-template>
        </kendo-grid-column>
       <kendo-grid-column
          class="text-truncate"
          title="{{ 'OrderViewTranslation.DemandType' | translate }}"
          field="demandType"
          [width]="150">
          <ng-template
          kendoGridFilterCellTemplate
              let-filter
              >
               <app-kendo-grid-drop-down-filter  [data]="demandTypes"
              valueField="value"
              textField="text"
              [filter]="filter"
              [filterCode]="'demandType'"
              >
              </app-kendo-grid-drop-down-filter>
          </ng-template>
          <ng-template
          kendoGridCellTemplate
          let-dataItem>
          {{ getDeamndTypeText(dataItem?.demandType) | translate  }}
      </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        title="{{ 'OrderViewTranslation.PlanningType' | translate }}"
        field="planningType"
        [width]="150">
        <ng-template
            kendoGridFilterCellTemplate
            let-filter>
             <app-kendo-grid-drop-down-filter
                [data]="planningTypes"
                valueField="value"
                textField="text"
                [filter]="filter"
                [filterCode]="'planningType'">
            </app-kendo-grid-drop-down-filter>
        </ng-template>
        <ng-template
        kendoGridCellTemplate
        let-dataItem>
        {{ getPlanningType(dataItem?.planningType) | translate  }}
    </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
          class="text-truncate"
          title="{{ 'OrderViewTranslation.PackageId' | translate }}"
          field="transportPackageCode"
          [width]="150">
          <ng-template
              kendoGridFilterCellTemplate
              let-filter
              let-column="column">
              <kendo-grid-string-filter-cell
                  [showOperators]="false"
                  [column]="column"
                  [filter]="filter">
              </kendo-grid-string-filter-cell>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
          class="text-truncate"
          title="{{ 'OrderViewTranslation.Order' | translate }}"
          field="orderID"
          [width]="150">
          <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex">
              <div [ngStyle]="{'color' : dataItem.Color}">{{ dataItem.orderID }}</div>
          </ng-template>
          <ng-template
              kendoGridFilterCellTemplate
              let-filter
              let-column="column">
              <kendo-grid-string-filter-cell
                  [showOperators]="false"
                  [column]="column"
                  [filter]="filter">
              </kendo-grid-string-filter-cell>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
          class="text-truncate"
          title="{{ 'OrderViewTranslation.OrderRow' | translate }}"
          field="orderRowID"
          [width]="150">
          <ng-template
              kendoGridFilterCellTemplate
              let-filter
              let-column="column">
              <kendo-grid-string-filter-cell
                  [showOperators]="false"
                  [column]="column"
                  [filter]="filter">
              </kendo-grid-string-filter-cell>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
          title="{{ 'OrderViewTranslation.CustomerNo' | translate }}"
          field="customerNo"
          [width]="150">
          <ng-template
          kendoGridFilterCellTemplate
          let-filter
          let-column="column">
          <kendo-grid-string-filter-cell
          [showOperators]="false"
          [column]="column"
          [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        title="{{ 'OrderViewTranslation.beamMaterialType' | translate }}"
        field="beamMaterialType.name"
        [width]="150">
           <ng-template let-dataItem kendoGridCellTemplate>
            {{ getBeamMaterialType(dataItem.beamMaterialType.externalId)  | translate}}
            </ng-template>

            <ng-template
            kendoGridFilterCellTemplate
            let-filter
            let-column="column">
            <app-kendo-grid-drop-down-filter
                [data]="beamMaterialTypes"
                textField="text"
                valueField="key"
                [filter]="filter"
                [filterCode]="'beamMaterialExternalId'">
            </app-kendo-grid-drop-down-filter>
            </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
      class="text-truncate text-center"
      headerClass="text-center"
      title="{{ 'OrderViewTranslation.BeamIcon' | translate }}"
      [filterable]="false"
      field="beamIconId"
      [width]="150">
      <ng-template
          kendoGridCellTemplate
          let-dataItem>
        <app-beam-icon [beamIconId]="dataItem.beamIconId" ></app-beam-icon>
        </ng-template>
     </kendo-grid-column>
     <kendo-grid-column
         class="text-truncate text-center"
         headerClass="text-center"
         title="{{ 'OrderViewTranslation.SpecialFormName' | translate }}"
         [filterable]="false"
         field="specialFormName"
         [width]="150">
     </kendo-grid-column>
      <kendo-grid-column
          title="{{ 'OrderViewTranslation.PlaneProfileCode' | translate }}"
          field="planeProfileCode"
          [width]="150">
          <ng-template
          kendoGridFilterCellTemplate
          let-filter
          let-column="column">
        <kendo-grid-string-filter-cell
        [showOperators]="false"
        [column]="column"
        [filter]="filter">
      </kendo-grid-string-filter-cell>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
        title="{{ 'OrderViewTranslation.PlaneProfileDesc' | translate }}"
        field="planeProfileDesc"
        [width]="150">
        <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
      <kendo-grid-string-filter-cell
      [showOperators]="false"
      [column]="column"
      [filter]="filter">
      </kendo-grid-string-filter-cell>
      </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
          title="{{ 'OrderViewTranslation.CustomerDesc' | translate }}"
          field="customerDesc"
          [width]="150">
          <ng-template
          kendoGridFilterCellTemplate
          let-filter
          let-column="column">
        <kendo-grid-string-filter-cell
        [showOperators]="false"
        [column]="column"
        [filter]="filter">
      </kendo-grid-string-filter-cell>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
          title="{{ 'OrderViewTranslation.OrderComment' | translate }}"
          field="orderComment"
          [width]="150">
          <ng-template
          kendoGridFilterCellTemplate
          let-filter
          let-column="column">
          <kendo-grid-string-filter-cell
          [showOperators]="false"
          [column]="column"
          [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
          title="{{ 'OrderViewTranslation.OrderRowComment' | translate }}"
          field="orderRowComment"
          [width]="150">
          <ng-template
          kendoGridFilterCellTemplate
          let-filter
          let-column="column">
          <kendo-grid-string-filter-cell
          [showOperators]="false"
          [column]="column"
          [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
          title="{{ 'OrderViewTranslation.PickingInst' | translate }}"
          field="pickingInst"
          [width]="150">
          <ng-template
          kendoGridFilterCellTemplate
          let-filter
          let-column="column">
          <kendo-grid-string-filter-cell
          [showOperators]="false"
          [column]="column"
          [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
          class="text-truncate"
          title="{{ 'OrderViewTranslation.Qty' | translate }}"
          field="Qty"
          [filterable]="false"
          [width]="150">
          <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex">
              <kendo-svg-icon
                  *ngIf="dataItem.QtyAlloatedOnDemand > (dataItem.transportPackageAllocation.Qty + dataItem.transportPackageAllocation.unitsExtra)"
                  [icon]="warningTriangleIcon"
                  themeColor="warning">
              </kendo-svg-icon>
              {{ dataItem.QtySumUI | number}}+
              <span style="color:#007bff">{{ dataItem.QtyPlanned}}</span>
              /{{dataItem.transportPackageAllocation?.qty}}
              <span
                  *ngIf="dataItem.transportPackageAllocation?.unitsExtra > 0"
                  style="color:red">
                  +{{dataItem.transportPackageAllocation?.unitsExtra }}
              </span>
          </ng-template>
          <ng-template
              kendoGridEditTemplate
              let-dataItem="dataItem"
              let-column="column"
              let-formGroup="formGroup">
              <kendo-numerictextbox [formControl]="formGroup.get('qtyReproduce')"></kendo-numerictextbox>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
      title="{{ 'Stock.QuantityUnit' | translate }}"
      field="qtyUnit"
      [width]="150">
        <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
        [showOperators]="false"
        [column]="column"
        [filter]="filter">
      </kendo-grid-string-filter-cell>
      </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
          class="text-truncate"
          title="{{ 'OrderViewTranslation.Width' | translate }}"
          field="width"
          [style]="{'text-align': 'right'}"
          [width]="150">
          <ng-template
              kendoGridFilterCellTemplate
              let-filter
              let-column="column">
              <kendo-grid-string-filter-cell
                  [showOperators]="false"
                  [column]="column"
                  [filter]="filter">
              </kendo-grid-string-filter-cell>
          </ng-template>
          <ng-template
              kendoGridCellTemplate
              let-dataItem>
              {{ dataItem.width | number}}
            </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
          class="text-truncate"
          title="{{ 'OrderViewTranslation.Length' | translate }}"
          field="length"
          [style]="{'text-align': 'right'}"
          [width]="150">
          <ng-template
          kendoGridFilterCellTemplate
          let-filter
          let-column="column">
          <kendo-grid-string-filter-cell
          [showOperators]="false"
          [column]="column"
          [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template
        kendoGridCellTemplate
        let-dataItem>
        {{ dataItem.length | number}}
      </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
          class="text-truncate"
          title="{{ 'OrderViewTranslation.VolumeM3' | translate }}"
          [filterable]="false"
          field="volumeM3"
          [style]="{'text-align': 'right'}"
          format="{0:n}"
          [width]="150">
      </kendo-grid-column>
      <kendo-grid-column
          title="{{ 'OrderViewTranslation.Thickness' | translate }}"
          field="thickness"
          [style]="{'text-align': 'right'}"
          [width]="150">
          <ng-template
          kendoGridFilterCellTemplate
          let-filter
          let-column="column">
          <kendo-grid-string-filter-cell
          [showOperators]="false"
          [column]="column"
          [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template
        kendoGridCellTemplate
        let-dataItem>
        {{ dataItem.thickness | number}}
      </ng-template>
      </kendo-grid-column>

      <kendo-grid-column
      class="text-truncate text-center"
      headerClass="text-center"
      title="{{ 'OrderViewTranslation.Species' | translate }}"
      [filterable]="false"
      field="species"
      [width]="150">
  </kendo-grid-column>
      <kendo-grid-column
          class="text-truncate"
          title="{{ 'OrderViewTranslation.Material'| translate }}"
          field="materialDescription"
          [width]="150">
          <ng-template
          kendoGridFilterCellTemplate
          let-filter
          let-column="column">
          <kendo-grid-string-filter-cell
          [showOperators]="false"
          [column]="column"
          [filter]="filter">
        </kendo-grid-string-filter-cell>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
          class="text-truncate"
          title="{{ 'OrderViewTranslation.BEAM_QUALITY' | translate }}"
          field="visualQuality"
          [width]="150">
          <ng-template
              kendoGridFilterCellTemplate
              let-filter
              let-column="column">
              <kendo-grid-string-filter-cell
                  [showOperators]="false"
                  [column]="column"
                  [filter]="filter">
              </kendo-grid-string-filter-cell>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.BEAM_PROFILE_CODE' | translate }}"
      field="planeProfileCode"
      [width]="75">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell  [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
    class="text-truncate"
    title="{{ 'OrderViewTranslation.BEAM_PROFILE_DESC' | translate }}"
    field="planeProfileDesc"
    [width]="75">
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell  [showOperators]="false"
                                        [column]="column"
                                        [filter]="filter">
        </kendo-grid-string-filter-cell>
    </ng-template>
  </kendo-grid-column>
      <kendo-grid-column
          class="text-truncate"
          title="{{ 'OrderViewTranslation.glueplans' | translate }}"
          field="gluingPlandIds"
          [width]="150">
          <ng-template
              kendoGridFilterCellTemplate
              let-filter
              let-column="column">
              <kendo-grid-string-filter-cell
                  [showOperators]="false"
                  [column]="column"
                  [filter]="filter">
              </kendo-grid-string-filter-cell>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
          class="text-truncate"
          title="{{ 'OrderViewTranslation.PlanState'| translate }}"
          field="planState"
          [width]="150">
          <ng-template
              kendoGridFilterCellTemplate
              let-filter
              let-column="column">
              <kendo-grid-string-filter-cell
                  [showOperators]="false"
                  [column]="column"
                  [filter]="filter">
              </kendo-grid-string-filter-cell>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
          class="text-truncate"
          title="{{ 'OrderViewTranslation.LatestProductionDate' | translate }}"
          field="latestProductionDate"
          [filterable]="false"
          format="{0:d}"
          [width]="150">
          <ng-template
              kendoGridFilterCellTemplate
              let-filter>
              <date-range-filter-cell
                  class="date-range-filter"
                  [filter]="filter"
                  field="latestProductionDate">
              </date-range-filter-cell>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.PlannedProductionDate' | translate }}"
      field="plannedProductionDate"
      [filterable]="false"
      format="{0:d}"
      [width]="150">
      <ng-template
          kendoGridFilterCellTemplate
          let-filter>
          <date-range-filter-cell
              class="date-range-filter"
              [filter]="filter"
              field="plannedProductionDate">
          </date-range-filter-cell>
      </ng-template>
  </kendo-grid-column>
      <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.MillExitDate' | translate }}"
      field="millExitDate"
      [filterable]="false"
      [format]="{ date: 'short' }"
      [width]="150">
      <ng-template
          kendoGridFilterCellTemplate
          let-filter>
          <date-range-filter-cell
              class="date-range-filter"
              [filter]="filter"
              field="millExitDate">
          </date-range-filter-cell>
      </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
          class="text-truncate"
          title="{{ 'OrderViewTranslation.ProdOrderCount' | translate }}"
          field="prodOrderCount"
          [filterable]="false"
          [width]="150">
          <ng-template
              kendoGridCellTemplate
              let-dataItem
              let-rowIndex="rowIndex">
              <a
                  *ngIf="dataItem.prodOrderId"
                  routerLink="/prod-order/{{dataItem.prodOrderId}}">
                  {{dataItem.prodOrderCount}}
              </a>
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
          filter="boolean"
          field="isActive"
          title="{{ 'Stock.IsActive'| translate  }}"
          [width]="150">
          <!-- below line of code can be used to rename filter options-->
          <kendo-grid-messages filterIsTrue="{{'App.Yes' | translate}}" filterIsFalse="{{ 'App.No' | translate}}"></kendo-grid-messages>
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ (dataItem.isActive ? 'App.Yes' : 'App.No') | translate }}
          </ng-template>
      </kendo-grid-column>
      <kendo-grid-column
          title="{{ 'AoT.DemandSpecification'| translate  }}"
          [width]="150">
          <ng-template
            kendoGridCellTemplate
            let-dataItem>
              <button
                themeColor="primary"
                kendoGridEditCommand
                (click)="addOrViewSpecification(dataItem)">
                  {{ (dataItem.demandSpecificationsCount > 0 ? 'App.View' : 'App.Add') | translate  }}
              </button>
          </ng-template>
      </kendo-grid-column>
      <ng-template
          kendoPagerTemplate
          let-totalPages="totalPages"
          let-currentPage="currentPage">
          <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
          <kendo-pager-numeric-buttons [buttonCount]="10"></kendo-pager-numeric-buttons>
          <kendo-pager-next-buttons></kendo-pager-next-buttons>
          <kendo-pager-info></kendo-pager-info>
          <kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
      </ng-template>
      <kendo-grid-excel fileName="Demand_Report.xlsx"></kendo-grid-excel>
  </kendo-grid>
</div>
