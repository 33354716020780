
    <kendo-grid [data]="gridDetailData"
                [selectable]="true"
                [reorderable]="true"
                [resizable]="true"
                [columnMenu]="false"
                [pageable]="true"
                [sortable]="true"
                [filterable]="true"
                [pageSize]="state.take"
                [skip]="state.skip"
                [filter]="state.filter"
                [sort]="state.sort"
                [loading]="loading"
                [size]="'small'"
                (dataStateChange)="dataStateChange($event)"
    >
        <kendo-grid-column [filterable]="false" field="startDate" title="{{ 'App.StartDate' | translate }}" [width]="25">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div>{{  dataItem.startDate | siteDateFormat:true }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [filterable]="false" field="endDate"  title="{{ 'App.EndDate' | translate }}" [width]="25">
          <ng-template kendoGridCellTemplate let-dataItem>
            <div>{{  dataItem.endDate | siteDateFormat:true }}</div>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [filterable]="false" field="customerDesc" title="{{ 'AoT.CustomerDesc' | translate }}" [width]="25"></kendo-grid-column>
        <kendo-grid-column [filterable]="false" field="machineName" title="{{ 'AoT.AssignedMachine' | translate }}" [width]="25"></kendo-grid-column>
        <kendo-grid-column field="documentTypeId" title="{{ 'AoT.DocumentType' | translate }}"  [width]="25" [style]="{'text-align': 'left'}">
          <ng-template kendoGridCellTemplate let-dataItem>
            {{ getDocumentType(dataItem.documentTypeId) | translate}}
          </ng-template>
          <ng-template kendoGridFilterCellTemplate let-filter>
               <kendo-multiselect
                  [data]="documentType"
                  textField="text"
                  valueField="key"
                  [valuePrimitive]="true"
                  [(ngModel)]="detailSerchCriteria.selectedTypes"
                  (valueChange)="onFilterChange()"
                  [tagMapper]="tagMapper">
                  <ng-template kendoMultiSelectTagTemplate  let-dataItem>
                    <span translate>
                        {{dataItem.text}}
                    </span>
                  </ng-template>
                  <ng-template kendoMultiSelectItemTemplate  let-dataItem>
                    <span translate>
                        {{dataItem.text}}
                    </span>
                  </ng-template>
              </kendo-multiselect>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column  [filterable]="false" field="documentNo" title="{{ 'AoT.DocumentName' | translate }}"  [width]="25" [style]="{'text-align': 'left'}">
            <ng-template kendoGridCellTemplate let-dataItem>
              <span [ngClass]="{'demand-info-dialog-link' : canCreateLink(dataItem.documentTypeId)}">
                  <div (click)="handleDocumentClick(dataItem.documentTypeId, dataItem)">
                    {{ dataItem.documentNo }}
                  </div>
                </span>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [filterable]="false" field="documentLineNo" title="{{ 'AoT.DocumentLineNo' | translate }}" [width]="25"></kendo-grid-column>
        <kendo-grid-column [filterable]="false" field="width" title="{{ 'App.Width' | translate }}" [width]="25" [style]="{'text-align': 'right'}"></kendo-grid-column>
        <kendo-grid-column [filterable]="false" field="thickness" title="{{ 'App.Thickness' | translate }}" [width]="25" [style]="{'text-align': 'right'}"></kendo-grid-column>
        <kendo-grid-column [filterable]="false" field="length" title="{{ 'App.Length' | translate }}" [width]="25" [style]="{'text-align': 'right'}"></kendo-grid-column>
        <kendo-grid-column [filterable]="false" field="quality" title="{{ 'GluePlanPrint.Quality' | translate }}" [width]="25"></kendo-grid-column>
        <kendo-grid-column [filterable]="false" field="instructions" title="{{ 'AoT.Instructions' | translate }}"  [width]="30">
          <ng-template kendoGridCellTemplate let-dataItem>
            <app-additional-instructions-display [instructions]="dataItem.instructions"></app-additional-instructions-display>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column [filterable]="false" field="qty" title="{{ 'AoT.Qty' | translate }}"  [width]="25" [style]="{'text-align': 'right'}">
            <ng-template kendoGridCellTemplate let-dataItem >
                <div #target>
                  {{dataItem.qty | number : '1.3'}} {{dataItem.qtyUnit}}
                </div>
                <app-aot-grid-context-menu  [target]="target"
                                            [entityIndex]="dataItem.entityIndex"
                                            [startDateTime]="dataItem.availTransDate"
                                            [quantity]="dataItem.qty"
                                            [unitCode]="dataItem.qtyUnit"
                                            [siteId]="detailSerchCriteria.siteId"
                                            (onRefreshRequested) ="onProdOrderDetailCreation($event)"
                                            >
                </app-aot-grid-context-menu>
            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column [filterable]="false" title="{{ 'AoT.QtyConverted' | translate }}"  [width]="25" [style]="{'text-align': 'right'}">
          <ng-template kendoGridCellTemplate let-dataItem >
              <div #target>
                {{dataItem.convertedQty | number : '1.3'}} {{dataItem.convertedUoM}}
              </div>
              <app-aot-grid-context-menu  [target]="target"
                                          [entityIndex]="dataItem.entityIndex"
                                          [startDateTime]="dataItem.availTransDate"
                                          [quantity]="dataItem.convertedQty"
                                          [unitCode]="dataItem.convertedUoM"
                                          [siteId]="detailSerchCriteria.siteId"
                                          (onRefreshRequested) ="onProdOrderDetailCreation($event)"
                                          >
              </app-aot-grid-context-menu>
          </ng-template>
        </kendo-grid-column>
    </kendo-grid>
