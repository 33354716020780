import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { EntityFilter, IEntity } from '@app/core/services/http-services/model/entity.service';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { DemandTypeEnum, getDemmandTypeValues } from '@app/core/models/aot-demand-type.enum';
import { getPlanningTypeValues } from '@app/core/models/aot-planning-type.enum';
import { DeamndService, IDemandPOSequenceDTO, IDemandInputForActivitySelection, IAltModelResponceDTO } from '@app/core/services/http-services/gluelam/demand.service';
import { DialogRef, DialogContentBase, DialogCloseResult } from '@progress/kendo-angular-dialog';
import { IGlulamSequenceResult } from '@app/core/models/glulamsequenceDTO';
import { firstValueFrom, Subject, takeUntil } from 'rxjs';
import { SVGIcon, infoCircleIcon } from '@progress/kendo-svg-icons';
import { IBeamMaterialType } from '@app/core/models/beam-material-type.model';
import { IPlaneProfile } from '@app/core/models/plane-profile';
import { PlaneProfileService } from '@app/core/services/http-services/aot/plane-profile.service';
import { BeamMaterialType } from '@app/core/models/beam-material-type-enum';
import { BeamMaterialTypeHelper } from '@app/shared/helpers/beam-material-type.helper';
import { IDemand, IDemandPartialDTO } from '@app/core/models/demand-model';
import { EntityPropertyService } from '@app/core/services/http-services/model/entity-property.service';
import { DemandFormHelper } from './demand-form-helper';

marker('Operative.AddDemandErrMsg');
marker('Operative.DemandAddedSuccessfully');
marker('Operative.DemandAndPOSeuqenceCreation');
marker('Operative.Qty_Reproduce_High');
marker('Operative.Qty_Reproduce_Negative');

@Component({
  selector: 'app-demand-form',
  templateUrl: './demand-form.component.html',
  styleUrls: ['./demand-form.component.css']
})
export class DemandFormComponent extends DialogContentBase implements OnInit, OnDestroy {
  disableSubmit: boolean = false;
  demandForm: FormGroup;
  demandTypes = getDemmandTypeValues();
  planningTypes = getPlanningTypeValues();
  beamMaterialTypes: IBeamMaterialType[];
  selectedBM :IBeamMaterialType;
  planeProfiles: IPlaneProfile[];
  loadingTypes = false;
  //Icons
  infoCircleIcon: SVGIcon = infoCircleIcon;
  altModels: IAltModelResponceDTO[] = [];
  isloadingAlt = false;
  planeLaminaThicknessOptions = [];
  isEditDemand:boolean;

  private _demand: IDemand;
  private isDemandPOSequence: boolean = false;
  private readonly destroy$ = new Subject<void>();
  private readonly planeLaminaThickness = 'PLANE_LAMINA_THICKNESS';

  constructor(
    @Inject(DialogRef) public data: { demand: IDemand },
    private readonly fb: FormBuilder,
    private readonly dialogRef: DialogRef,
    private readonly beamOrderRowService: DeamndService,
    private readonly notificationService: AppNotificationService,
    private readonly entityPropertyService: EntityPropertyService,
    private readonly planeProfileService: PlaneProfileService) { super(dialogRef); }

  ngOnInit() {
    this._demand = this.data.demand;
    this.isEditDemand = this._demand.demandID >0;

    this.initializeForm();
    this.loadPlaneProfiles();
  }

  private initializeForm(): void {
      this.demandForm = DemandFormHelper.initializeForm(this.fb);

    if (this.isEditDemand) {
      this.prepareEditMode();
      this.queryAlternativeModels(this._demand.materialDescription);
      this.getLammelaThiknessOptions();
    } else {
      let normalType = this.beamMaterialTypes?.find(b => b.externalId === BeamMaterialType.NORMAL);
      this.demandForm.get('beamMaterialTypeID')?.patchValue(normalType?.beamMaterialTypeID);
    }
  }

  async loadPlaneProfiles() {
    this.loadingTypes = true;
    const response = await firstValueFrom(this.planeProfileService.query());
    this.planeProfiles = response;
    if (response)
    {
      this.loadingTypes = false;
      this.demandForm.get('planeProfileId')?.patchValue(this._demand?.planeProfileId);
    }
  };

  queryAlternativeModels(searchTerm: string) {
    this.isloadingAlt = true;
    if (searchTerm.length >= 3) {
      const entityState: EntityFilter = {} as EntityFilter;
      entityState.searchTermCode = searchTerm;
      entityState.take = 25;
      entityState.siteId = this.demandForm.get('siteId').value;
      entityState.rawLamellaThickness = this.demandForm.get('defaultLamminaPlaneThickness').value;
      entityState.beamMaterialExternalId = this.selectedBM?.externalId ;

      this.beamOrderRowService.getAlternateCodes(entityState)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (result) => {
            this.isloadingAlt = false;
            this.altModels = result;
          },
          error: (err) => {
            this.isloadingAlt = false;
          }
        });
    }
  }

  setSelectedMaterial(material: IEntity) {
    if (material != null && this._demand.demandID <= 0) {
      this.demandForm.patchValue({
        length: material.standardProperties.length,
        height: material.standardProperties.thickness,
        width: material.standardProperties.width,
        species: material.standardProperties.species,
        entityGroupCode: material.entityGroup.index.toString(),
        externalItemID: material.code,
      });
    }
  }

  selectedBeamMaterialType(bm:IBeamMaterialType){
    this.selectedBM = bm;
    if(!this.isEditDemand){
      if (BeamMaterialTypeHelper.isSpecialShape(bm)) {
        this.demandForm.get('specialFormName').enable();
        this.demandForm.get('radius').enable();
      } else {
        DemandFormHelper.resetAndDisableFields(['specialFormName', 'radius'], this.demandForm);
      }
    }
  }

  validate(currentQtyReproduce, updatedQtyReproduce) {
    if (updatedQtyReproduce > currentQtyReproduce) {
      this.notificationService.notifyErrorAppChanel('OrderViewTranslation.Qty_Reproduce_High');
      return false;
    } else if (updatedQtyReproduce < 0) {
      this.notificationService.notifyErrorAppChanel('OrderViewTranslation.Qty_Reproduce_Negative');
      return false;
    }
    return true;
  }

  handleDemandEdit(formData: any){
    const isValid = this.validate(this._demand.transportPackageAllocation?.unitsExtra, formData.qty);
    if (isValid) {
      this.beamOrderRowService
        .savePartial(<IDemandPartialDTO>{
          demandID: formData.demandID,
          lamminaPlaneThickness: formData.defaultLamminaPlaneThickness,
          lamminaPlaneWidth: formData.width,
          beamLamminaDimensionId: formData.beamLamminaDimensionID,
          materialIndex: formData.materialIndex,
          qtyReproduce: formData.qty,
          transportPackageId: this._demand.transportPackageAllocation.transportPackageID,
          beamMaterialTypeId : formData.beamMaterialTypeID
        })
        .subscribe({
          next: (d) => this.dialogRef.close(d),
          error: () => (this.disableSubmit = false)
        });
    }
    else{
      this.disableSubmit = false;
    }
  }

  onSubmit() {
    this.disableSubmit = true;
    const formData = this.demandForm.getRawValue();

    if(this.isEditDemand) {
      this.handleDemandEdit(formData);
    }
    else{
      this.createNewDemand(formData);
    }
  }

  isFormValid(){
    if(this.isEditDemand){
      if (this.selectedBM && (this.selectedBM?.beamMaterialTypeID != this._demand?.beamMaterialTypeID)) {
        if(BeamMaterialTypeHelper.isSpecialShape(this.selectedBM) || BeamMaterialTypeHelper.isUndefinedShape(this.selectedBM)){
          return false;
        }
      }
    }
    return true;
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  onDemandPOSequence() {
    if (this.demandForm.get('qtyUnit').value && this.demandForm.get('qtyUnit').value !== 'PCS') {
      this.notificationService.notifyErrorAppChanel('Operative.AddAndGenerateSequenceErrorMsg');
      return;
    }
    this.isDemandPOSequence = true;
    this.onSubmit();
  }

  onDemandTypeChange(value: any) {
    if (value) {
      if (value === DemandTypeEnum.StockOrder) {
        this.demandForm.get('isActive').patchValue(false);
      } else {
        this.demandForm.get('isActive').patchValue(true);
      }
    }
  }

  onMaterialSelected(material: IAltModelResponceDTO) {
    const entity = this.altModels.find((x) => x.index === material.index);
    this.demandForm.patchValue({
      materialIndex: entity.index,
      defaultLamminaPlaneThickness: entity.lamminaPlaneThickness ?? this.demandForm.get('defaultLamminaPlaneThickness').value,
      beamLamminaDimensionId: entity.beamLamminaDimensionId,
    });
  }

  private createNewDemand(formData: any): void {
    const demandData: IDemandPOSequenceDTO =  <IDemandPOSequenceDTO>{
      demand: DemandFormHelper.mapFormToDemand(this.demandForm.getRawValue()),
      isGenerateProdOrder: this.isDemandPOSequence,
      instruction: this.demandForm.value.instruction,
      siteId: this.demandForm.value.siteId
    };

    if (this.isDemandPOSequence) {
      this.handleDemandPOSequence(demandData)
     } else {
       this.insertDemand(demandData);
     }
  }

  async getLammelaThiknessOptions(){
    let entityPropertys = await firstValueFrom(this.entityPropertyService.get());

    this.planeLaminaThicknessOptions = entityPropertys.filter((x) => x.code === this.planeLaminaThickness).map((x) => {
      return x.validValues.map((y) => {
        return y.valueDouble;
      });
    })[0];
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private handleDemandPOSequence(demandSequenceData : IDemandPOSequenceDTO) {
    const d: IDemandInputForActivitySelection = {
      beamOrderRowID: 0,
      orderId: this.demandForm.value.orderID,
      orderRowId: this.demandForm.value.orderRowID,
      materialIndex: this.demandForm.value.materialIndex,
      currentPosition: 1,
      totalCount: 1
    };

    const dr = this.beamOrderRowService.openDialogForGlulamSeq(d);

    dr.result
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: IGlulamSequenceResult) => {
        if (data instanceof DialogCloseResult) {
          dr.close();
        } else if (data.success) {
          demandSequenceData.activitysSelected = data.lines;
          demandSequenceData.isGenerateProdOrder = true;
          this.insertDemand(demandSequenceData);
        } else {
          this.isDemandPOSequence = false;
          this.disableSubmit = false;
        }
      });
  }

  private insertDemand(createDemandPOSequence: IDemandPOSequenceDTO) {
    this.beamOrderRowService
      .insertDemand(createDemandPOSequence)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (beamOrderRowID) => {
          this.disableSubmit = false;
          if (this.isDemandPOSequence) {
            this.notificationService.notifySucsessAppChanel('Operative.DemandAndPOSeuqenceCreation');
          } else {
            this.notificationService.notifySucsessAppChanel('Operative.DemandAddedSuccessfully');
          }
          this.isDemandPOSequence = false;
          createDemandPOSequence.demand.beamOrderRowID = beamOrderRowID;
          this.dialogRef.close(createDemandPOSequence);
        },
        error: () => {
          this.disableSubmit = false;
          this.notificationService.notifyErrorAppChanel('Operative.AddDemandErrMsg');
        }
      });
  }

  private prepareEditMode(): void {
    this.demandForm.disable();
    if(BeamMaterialTypeHelper.isSpecialShape(this._demand.beamMaterialType))
    {
      DemandFormHelper.enableFields(['qty', 'defaultLamminaPlaneThickness', 'materialIndex'], this.demandForm);

    }else{
      DemandFormHelper.enableFields(['qty', 'defaultLamminaPlaneThickness', 'beamMaterialTypeID', 'materialIndex'], this.demandForm);
    }

    this.demandForm.patchValue(this._demand);
    this.demandForm.get('qty').patchValue(this._demand.qtyReproduce);
    this.demandForm.get('latestProductionDate').patchValue(this._demand.latestProductionDate ? new Date(this._demand.latestProductionDate) : null)
  }
}
