import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { EnumToObject } from '@app/shared/helpers/transform-enum-to-object.helper';
import { IPeriodSelector } from '@app/core/models/periodSelector.model';
import { UnitService } from '@app/core/services/http-services/common/unit-service';
import { UserService } from '@app/core/services/http-services/common/user.service';
import { groupBy } from '@progress/kendo-data-query';
import { DatePipe } from '@angular/common';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { DateFormatEnum } from '@app/core/models/date-format.enum';
import { ISearchAoTBalanceCriteria } from '@app/core/models/searchAoTBalanceCriteria.model';
import { ISetPeriodSelectorCriteria } from '@app/core/models/setPeriodSelectorCriteria';
import { IAoTBalanceDetailSearchCriteriaDTO } from '@app/core/models/aotBalanceDetailSearchCriteriaDTO.model';
import { AoTGrouping } from '@app/core/models/aot-grouping.enum';
import { AoTOrderTypeEnum } from '@app/core/models/aot-order-type.enum';
import { IEnumKeyValue } from '@app/core/models/enumKeyValue.model';
import { UserSettingkeyEnum } from '@app/core/models/user-setting-key.enum';
import { UserSettingService } from '@app/core/services/http-services/common/user-setting.service';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { IGeneralFilterSetting, IGridSetting } from '@app/core/models/gridSetting';
import { forkJoin } from 'rxjs';
import { AotBalanceSearchForm } from '@app/core/models/forms/aot/aot-balance-search-form.model';
import { Guid } from 'guid-typescript';
import { AoTDocumentType } from '@app/core/models/aot-document-type.enum';

marker('App.SaveSettingMsg');

@Component({
  selector: 'app-aot-balance',
  templateUrl: './aot-balance.component.html',
  styleUrls: ['./aot-balance.component.scss'],
  providers: [DatePipe,
    EnumToObject
  ],
  encapsulation: ViewEncapsulation.None
})
export class AotBalanceComponent implements OnInit {
  aotBalanceSelectedCriteriaInput: ISearchAoTBalanceCriteria;
  setPeriodSelectorCriteriaInput: ISetPeriodSelectorCriteria;
  group: IEnumKeyValue[];
  unitOfMeasurment: any[];
  aotSearchBalanceForm: FormGroup;
  isForecastVolumeOn: boolean = null;
  isSearchDisable = false;
  showDetailGrid = false;
  aotBalanceDetailSelectedCriteriaInput: IAoTBalanceDetailSearchCriteriaDTO;
  orderTypes: IEnumKeyValue[];
  showBudget = false;
  selectedTypesMain: AoTDocumentType[] = [];
  selectedTypesSecondary: AoTDocumentType[] = [];
  disabletransTypeSecondary: boolean = true;

  private aotBalanceSelectedCriteria: ISearchAoTBalanceCriteria = {} as ISearchAoTBalanceCriteria;
  private areSettingsApplied = false;
  private readonly setPeriodSelectorCriteria: ISetPeriodSelectorCriteria = {} as ISetPeriodSelectorCriteria;
  private readonly settingsId = UserSettingkeyEnum.availability_aot_balance;
  private readonly aotBalanceSavedSearchedCriteria: ISearchAoTBalanceCriteria = {} as ISearchAoTBalanceCriteria;
  private readonly defaultPeriodType = 1;
  private readonly defaultPeriodCount = 4;

  constructor(
    private readonly datepipe: DatePipe,
    private readonly enumToObject: EnumToObject,
    private readonly unitService: UnitService,
    private readonly userService: UserService,
    private readonly userSettingService: UserSettingService,
    private readonly notificationService: AppNotificationService
  ) { }

  ngOnInit(): void {
    this.group = this.enumToObject.transform(AoTGrouping);
    this.orderTypes = this.enumToObject.transform(AoTOrderTypeEnum);
    this.setAoTBalanceSearchForm();
    this.setUpSubscriptions();
  }


  private setAoTBalanceSearchForm(): void {
    this.aotSearchBalanceForm = new FormGroup<AotBalanceSearchForm>({
      entitygroup: new FormControl<string>('', [Validators.required]),
      period: new FormControl<IPeriodSelector>(({ periodType: this.defaultPeriodType, periodCount: this.defaultPeriodCount }), [Validators.required]),
      group: new FormControl<string>('', [Validators.required]),
      showForecast: new FormControl<boolean>(true, [Validators.required]),
      unitCode: new FormControl<string>(''),
      date: new FormControl<Date>(new Date(Date.now()), [Validators.required]),
      stockLimits: new FormControl<boolean>(true),
      orderType: new FormControl<number>(0),
      transTypeMain: new FormControl<AoTDocumentType[]>([]),
      transTypeSecondary: new FormControl<AoTDocumentType[]>([]),
      siteId: new FormControl<Guid>(null, [Validators.required])
    });
  }

  setUpSubscriptions() {
    forkJoin({
      unitService: this.unitService.query({}),
      tenantInformation: this.userService.getTenant(),
      userSettings: this.userSettingService.getUserSetting(this.settingsId)
    }).subscribe((result) => {
      this.unitOfMeasurment = groupBy(result.unitService.sort((a, b) => (a.mandatoryUnitRelation > b.mandatoryUnitRelation ? -1 : 1)), [{ field: 'mandatoryUnitRelation' }]);
      if (result.userSettings) {
        this.aotBalanceSavedSearchedCriteria.period = {
          periodType: this.defaultPeriodType,
          periodCount: this.defaultPeriodCount
        };
        result.userSettings.filterSettings.forEach(setting => {
          switch (setting.field) {
            case 'periodType':
              this.aotBalanceSavedSearchedCriteria.period.periodType = +setting.value;
              break;
            case 'periodCount':
              this.aotBalanceSavedSearchedCriteria.period.periodCount = +setting.value;
              break;
            case 'unitCode':
              this.aotBalanceSavedSearchedCriteria.unitCode = this.setDefaultOrSelectedUOM(setting.value);
              break;
            case 'group':
              this.aotBalanceSavedSearchedCriteria.group = this.group.find(x => x.key === AoTGrouping[setting.value]).value;
              break;
            case 'orderType':
              this.aotBalanceSavedSearchedCriteria.orderType = this.orderTypes.find(x => x.key === AoTOrderTypeEnum[setting.value]).value;
              break;
            case 'stockLimits':
              this.setStockLimits(setting.value);
              break;
            case 'showForecast':
              this.setForecastVolume(setting.value);
              break;
            case 'transTypeMain':
              this.setTransType(setting.value, AoTOrderTypeEnum.Main);
              break;
            case 'transTypeSecondary':
              this.setTransType(setting.value, AoTOrderTypeEnum.Comparison);
              break;
          }
        });

        this.updateAoTBalanceSearchForm();
      } else {
        this.setDefault();
      }

      this.onSubmit();
    });
  }

  disableSearchButton(value: boolean) {
    this.isSearchDisable = value;
  }

  onSiteSelected() {
    if (this.aotSearchBalanceForm.valid) {
      this.onSubmit();
    }
  }

  onSelectEntityGroup() {
    setTimeout(() => {
      if (this.aotSearchBalanceForm.valid) {
        this.onSubmit();
      }
    }, 1000);
  }

  onSubmit(): void {
    const date = this.aotSearchBalanceForm.get('date').value;

    const aotSearchForm = this.aotSearchBalanceForm.value;

    this.aotBalanceSelectedCriteria.group = aotSearchForm.group;
    this.aotBalanceSelectedCriteria.showForecast = aotSearchForm.showForecast;
    this.aotBalanceSelectedCriteria.unitCode = aotSearchForm.unitCode;
    this.aotBalanceSelectedCriteria.period = aotSearchForm.period;
    this.aotBalanceSelectedCriteria.entitygroupIndex = aotSearchForm.entitygroup;
    this.aotBalanceSelectedCriteria.stockLimits = aotSearchForm.stockLimits;
    this.aotBalanceSelectedCriteria.orderType = aotSearchForm.orderType;
    this.aotBalanceSelectedCriteria.date = this.datepipe.transform(new Date(date), DateFormatEnum.yyyy_MM_dd);
    this.aotBalanceSelectedCriteria.transTypeMainId = aotSearchForm.transTypeMain;
    this.aotBalanceSelectedCriteria.transTypeSecondaryId = aotSearchForm.transTypeSecondary;
    this.aotBalanceSelectedCriteria.siteId = aotSearchForm.siteId;

    this.aotBalanceSelectedCriteriaInput = { ...this.aotBalanceSelectedCriteria };
    this.aotBalanceDetailSelectedCriteriaInput = null;
    this.showDetailGrid = false;
  }

  onSaveSettings(): void {
    this.setAndSaveUserSettingDTO(this.createUserFilterSettingsDTO());
  }

  loadAoTBalanceDetailData(aotBalanceDetailSearchCriteriaDTO: IAoTBalanceDetailSearchCriteriaDTO) {
    this.showDetailGrid = true;
    aotBalanceDetailSearchCriteriaDTO.selectedTypes = this.getSelectedTypes(aotBalanceDetailSearchCriteriaDTO.orderType);
    this.aotBalanceDetailSelectedCriteriaInput = { ...aotBalanceDetailSearchCriteriaDTO };
    this.aotBalanceDetailSelectedCriteriaInput.currentDate = this.aotBalanceSelectedCriteria.date;
  }

  forecastchange(value: boolean) {
    this.aotSearchBalanceForm.get('showForecast').setValue(value);
    this.aotBalanceSelectedCriteriaInput.showForecast = value;

    if (value) {
      this.aotSearchBalanceForm.get('stockLimits').enable();
    } else {
      this.aotSearchBalanceForm.get('stockLimits').disable();
    }

    if (!this.areSettingsApplied) {
      this.aotSearchBalanceForm.get('stockLimits').setValue(value);
      this.aotBalanceSelectedCriteriaInput.stockLimits = value;
    }

    this.isForecastVolumeOn = value;
  }

  stockLimitChange(value: boolean) {
    this.aotSearchBalanceForm.get('stockLimits').setValue(value);
    this.aotBalanceSelectedCriteriaInput.stockLimits = value;
  }

  orderTypeChange(orderType: any) {
    const orderTypeVal = orderType ? AoTOrderTypeEnum.Comparison : AoTOrderTypeEnum.Main;
    if (this.aotBalanceSelectedCriteriaInput !== undefined) {
      this.aotBalanceSelectedCriteriaInput.orderType = orderType ? AoTOrderTypeEnum.Comparison : AoTOrderTypeEnum.Main;
    }

    this.disabletransTypeSecondary = !orderType;

    this.aotSearchBalanceForm.get('period').setValue(<IPeriodSelector>(
      {
        periodType: this.defaultPeriodType,
        periodCount: this.aotSearchBalanceForm.get('period').value.periodCount
      }));

    this.aotSearchBalanceForm.get('orderType').setValue(orderTypeVal);
    this.setPeriodSelectorCriteria.orderType = orderType ? AoTOrderTypeEnum.Comparison : AoTOrderTypeEnum.Main;
    this.setPeriodSelectorCriteriaInput = { ...this.setPeriodSelectorCriteria };
  }

  getSelectedTypes(orderType: AoTOrderTypeEnum): any {
    if (orderType === AoTOrderTypeEnum.Main)
      return this.aotBalanceSelectedCriteriaInput.transTypeMainId;
    else if (orderType === AoTOrderTypeEnum.Comparison)
      return this.aotBalanceSelectedCriteriaInput.transTypeSecondaryId;
  }

  private setDefault(): void {
    this.areSettingsApplied = false;
    this.setDefaultGroup();
    this.setDefaultUOM();
  }

  private setDefaultGroup(): void {
    const defaultGroup = this.group.find(x => x.key === AoTGrouping[AoTGrouping.Material]);
    this.aotSearchBalanceForm.get('group').setValue(defaultGroup.value);
  }

  private setDefaultUOM(): void {
    this.aotSearchBalanceForm.get('unitCode').setValue(this.setDefaultOrSelectedUOM('M3'));
  }

  private setDefaultOrSelectedUOM(unitCode: string) {
    return this.unitOfMeasurment.find(x => x.field === 'mandatoryUnitRelation').items.find(x => x.unitCode === unitCode)?.unitCode;
  }

  private setStockLimits(value: any): void {
    this.aotBalanceSavedSearchedCriteria.stockLimits = value;
  }

  private setForecastVolume(value: any): void {
    this.aotBalanceSavedSearchedCriteria.showForecast = value;
  }

  private setTransType(value: string, type: AoTOrderTypeEnum): void {
    if (value.length < 1)
      return;

    const transactionTypeIds: AoTDocumentType[] = value.split(',')
      .map(t => parseInt(t.trim(), 10))
      .filter(t => !isNaN(t));

    if (transactionTypeIds.length === 0)
      return;

    if (type === AoTOrderTypeEnum.Main) {
      this.disabletransTypeSecondary = true;
      this.selectedTypesMain = transactionTypeIds;
      this.aotBalanceSavedSearchedCriteria.transTypeMainId = this.selectedTypesMain;
    } else if (type === AoTOrderTypeEnum.Comparison) {
      this.disabletransTypeSecondary = false;
      this.selectedTypesSecondary = transactionTypeIds;
      this.aotBalanceSavedSearchedCriteria.transTypeSecondaryId = this.selectedTypesSecondary;
    }

  }

  private updateAoTBalanceSearchForm(): void {
    this.areSettingsApplied = true;
    this.aotSearchBalanceForm.patchValue({
      group: this.aotBalanceSavedSearchedCriteria.group
    });

    if(this.aotBalanceSavedSearchedCriteria.orderType !== undefined) {
      this.aotSearchBalanceForm.patchValue({
        orderType: this.aotBalanceSavedSearchedCriteria.orderType
      });
    }


    this.aotSearchBalanceForm.patchValue({
      unitCode: this.aotBalanceSavedSearchedCriteria.unitCode
    });

    this.aotSearchBalanceForm.patchValue({
      period: this.aotBalanceSavedSearchedCriteria.period
    });

    this.aotSearchBalanceForm.patchValue({
      showForecast: this.aotBalanceSavedSearchedCriteria.showForecast
    });

    this.aotSearchBalanceForm.patchValue({
      stockLimits: this.aotBalanceSavedSearchedCriteria.stockLimits
    });

    if(this.aotBalanceSavedSearchedCriteria.transTypeMainId !== undefined) {
      this.aotSearchBalanceForm.get('transTypeMain').reset();
      this.aotSearchBalanceForm.patchValue({
        transTypeMain: this.aotBalanceSavedSearchedCriteria.transTypeMainId
      });
    }

    if(this.aotBalanceSavedSearchedCriteria.transTypeSecondaryId !== undefined) {
      this.aotSearchBalanceForm.get('transTypeSecondary').reset();
      this.aotSearchBalanceForm.patchValue({
        transTypeSecondary: this.aotBalanceSavedSearchedCriteria.transTypeSecondaryId
      });
    }

    this.aotBalanceSelectedCriteria = { ...this.aotBalanceSavedSearchedCriteria };
    this.areSettingsApplied = false;
  }

  private createUserFilterSettingsDTO(): IGridSetting {
    const userSettings: IGridSetting = {
      columnSettings: [],
      filterSettings: []
    };

    const formValue = this.aotSearchBalanceForm.value;

    userSettings.filterSettings.push(this.createGeneralFilterSettings('showForecast', formValue.showForecast));
    userSettings.filterSettings.push(this.createGeneralFilterSettings('stockLimits', formValue.stockLimits));
    userSettings.filterSettings.push(this.createGeneralFilterSettings('periodType', formValue.period.periodType));
    userSettings.filterSettings.push(this.createGeneralFilterSettings('periodCount', formValue.period.periodCount));
    userSettings.filterSettings.push(this.createGeneralFilterSettings('group', formValue.group));
    userSettings.filterSettings.push(this.createGeneralFilterSettings('orderType', formValue.orderType));
    userSettings.filterSettings.push(this.createGeneralFilterSettings('transTypeMain', formValue.transTypeMain.join()));
    userSettings.filterSettings.push(this.createGeneralFilterSettings('transTypeSecondary', formValue.transTypeSecondary?.join() ?? []));
    userSettings.filterSettings.push(this.createGeneralFilterSettings('unitCode', formValue.unitCode));

    return userSettings;
  }

  private createGeneralFilterSettings(field: string, value: any): IGeneralFilterSetting {
    const setting: IGeneralFilterSetting = {
      field,
      value
    };
    return setting;
  }

  private setAndSaveUserSettingDTO(settings: any): void {
    const userSettings = {
      key: this.settingsId,
      value: JSON.stringify(settings)
    };

    this.saveUserSettings(userSettings);
  }

  private saveUserSettings(newUserSettings: any): void {
    this.userSettingService.saveUserSetting(newUserSettings).subscribe((result) => {
      if (result) {
        this.notificationService.notifySucsessAppChanel('App.SaveSettingMsg');
      }
    });
  }
}
