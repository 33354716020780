<kendo-dialog-titlebar kendo-dialog-title>
  {{"Beams.ManualBeamMaterial" | translate}}
</kendo-dialog-titlebar>
<div class="container">
  <form [formGroup]="form" *ngIf="form">
    <div>
      <kendo-grid [data]="demandlines" [scrollable]="'none'">
        <kendo-grid-column field="orderID" title="{{ 'GluePlanPrint.OrderId' | translate }}"></kendo-grid-column>
        <kendo-grid-column field="orderRowID" title="{{ 'OrderViewTranslation.OrderRow' | translate }}"></kendo-grid-column>
        <kendo-grid-column field="length" title="{{ 'OrderViewTranslation.Length' | translate }}"></kendo-grid-column>
        <kendo-grid-column field="width" title="{{ 'OrderViewTranslation.Width' | translate }}"></kendo-grid-column>
        <kendo-grid-column field="thickness" title="{{ 'OrderViewTranslation.RawLamellaThickness' | translate }}"></kendo-grid-column>
        <kendo-grid-column field="hight" title="{{ 'OrderViewTranslation.Height' | translate }}"></kendo-grid-column>
        <kendo-grid-column field="NumberOfLaminas" title="{{ 'OrderViewTranslation.NumberOfLamminas' | translate }}"></kendo-grid-column>
        <kendo-grid-column field="DefaultLamminaPlaneThickness" title="{{ 'OrderViewTranslation.LamminaPlaneThickness' | translate }}"></kendo-grid-column>
        <kendo-grid-column field="qty" title="{{ 'OrderViewTranslation.Qty' | translate }}">
          <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <kendo-numerictextbox [formControl]="getDemandFormControl(rowIndex, 'qty')"></kendo-numerictextbox>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid>
    </div>
    <div class="row k-p-auto">

      <kendo-formfield>
        <kendo-label>{{ 'OrderViewTranslation.NumberofLabels' | translate }}</kendo-label>
        <kendo-numerictextbox  [min]=0 [max]=9 formControlName="noOfLabels"></kendo-numerictextbox>
      </kendo-formfield>

      <kendo-formfield>
        <kendo-label>{{ 'OrderViewTranslation.Length' | translate }}</kendo-label>
        <kendo-numerictextbox formControlName="length"></kendo-numerictextbox>
      </kendo-formfield>

      <app-select-entity-simple [showLable]="true" entityTypeCode="RESOURCE" [label]="'Operative.SelectMaterial'"
        formControlName="materialId" [disableControl]="disableMaterialControl">
      </app-select-entity-simple>

      <kendo-formfield>
        <kendo-label>{{ 'OrderViewTranslation.NumberOfLamminas' | translate }}</kendo-label>
        <kendo-numerictextbox formControlName="numberOfLaminas"></kendo-numerictextbox>
      </kendo-formfield>

      <app-select-beam-material-type
        [isManualBeam] = true
        formControlName="beamMaterialTypeId"
        [demandLines]="demandlines"
        (selectedBeamMaterialType) = selectedBeamMaterialType($event)>
      </app-select-beam-material-type>
    </div>
  </form>
  <kendo-dialog-actions layout="end">
    <button kendoButton type="submit" themeColor="primary" [disabled]="!form.valid" (click)="addManualBeamMaterial()">
      {{'App.Add' | translate}}
    </button>
    <button kendoButton themeColor="error" type="button" (click)="closeDialog()">
      {{'App.Close' | translate}}
    </button>
  </kendo-dialog-actions>
</div>
