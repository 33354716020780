import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { DeamndService } from '@app/core/services/http-services/gluelam/demand.service';
import { IDemand } from '@app/core/models/demand-model';

marker('OrderViewTranslation.SelectDemand');

@Component({
  selector: 'app-select-demand',
  template: `
    <kendo-formfield>
  <kendo-label *ngIf="showLable" class="min-width-dropdown">{{label | translate}}</kendo-label>
<kendo-multicolumncombobox class="demandcombobox"
                          [data]="demands"
                          textField="orderIDOrderRowId"
                          valueField="demandID"
                          placeholder="{{'OrderViewTranslation.SearchDemand' | translate}}"
                          [loading]="loading"
                          [filterable]="true"
                          [(value)]="demandID"
                          (filterChange)="handleFilterChange($event)"
                          (selectionChange) = "onSelectionChange($event)"
                          [valuePrimitive]="true"
                          class="min-width-dropdown">
<kendo-combobox-column field="orderID" title="{{'OrderViewTranslation.Order' | translate}}" >
</kendo-combobox-column>
<kendo-combobox-column field="orderRowID" title="{{'OrderViewTranslation.OrderRow' | translate}}" >

</kendo-combobox-column>
</kendo-multicolumncombobox>
</kendo-formfield>
  `,
  styleUrls: ['./select-demand.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectDemandComponent
    }
  ]
})
export class SelectDemandComponent implements ControlValueAccessor {
  demandID:number = null;
  demands: IDemand[];

  private onChanged!: Function;
  private onTouched!: Function;
  private filterSubscription: Subscription;

  @Input() loading = false;
  @Input() label: string = 'OrderViewTranslation.SelectDemand';
  @Input() showLable: boolean = false;

  @Input() set refreshDemand(demand:IDemand) {
    if (demand) {
      this.bindDemand([demand]);
    } else {
      this.demandID = null;
    }
  };

  @Output() selectedDemand: EventEmitter<IDemand> = new EventEmitter<IDemand>();

  constructor(private readonly beamOrderRowService: DeamndService) { }

  private bindDemand(demand: IDemand[]) {
    this.demands = demand;
    this.demands.forEach(x => {
      x.orderIDOrderRowId = x.orderID + ':' + x.orderRowID;
    });
  }

  onSelectionChange(demand: IDemand) {
    this.demandID = demand.demandID;
    this.selectedDemand.emit(demand);
    this.onChanged(this.demandID);
  }

  writeValue(value: number): void {
    this.demandID = value;
  }

  registerOnChange(onChange: any): void {
    this.onChanged = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  public handleFilterChange(searchTerm: string): void {
    if (searchTerm.length >= 3) {
      if (this.filterSubscription) {
        this.filterSubscription.unsubscribe();
      }
      this.loading = true;
      this.filterSubscription = this.beamOrderRowService.getDemands(searchTerm)
        .subscribe(d => {
          this.bindDemand(d);
          this.loading = false;
        }
        );
    }
  }
}
