import { Component, OnInit, Inject, ViewEncapsulation, OnDestroy } from '@angular/core';
import { GluingPlan } from '@app/core/models/gluing-plans.model';
import { IGluesetLamellaInstruction } from '@app/core/models/glueset-lamella-instruction.model';
import { BeamLamminaDimension } from '@app/core/models/beam-lammina-dimension.model';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { forkJoin, Subject, takeUntil } from 'rxjs';
import { GlueSetState, GlueSetStateService, IBeamMaterialGlueSetState } from '@app/core/services/http-services/gluelam/glueset-state.service';
import { DimensionService } from '@app/core/services/http-services/gluelam/dimension.service';
import { IPressbedRamConfigurationDTO } from '@app/core/models/pressbed-ram-ronfiguration-dto';
import { AppNotificationService } from '@app/core/services/custom-services/notification.service';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { GluelamStaticalMediatorService } from '@app/modules/gluelam/services/gluelam-statical-mediator.service';
import { GluePlanService } from '@app/core/services/http-services/gluelam/glue-plan.service';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import { IGluPlanModalInput } from '@app/core/models/glueplan-modal-input.model';
import { CreateGluingPlanForm, GluesetLamellaInstructionForm, PlannedLamellasWithBoxInfosForm } from '@app/core/models/forms/gluelam/create-gluing-plan-form.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { GlueSetService, IBeamMaterialGlueSet } from '@app/core/services/http-services/gluelam/glue-set.service';
import { BeamMaterialGlueSet } from '@app/core/models/beam-material-glue-set.model';
import { SelectEvent } from '@progress/kendo-angular-layout';
import { RegexHelper } from '@app/shared/helpers/regex-helper';
import { IExchangedLammela, IExchangeLammelaInstructions } from '@app/core/models/exchange-lammela-instructions.model';
import { plannedLamellaWithBoxInfoValidator } from '@app/modules/gluelam/controls/gluing-plan-modal-ng/validators/planned-lamella-with-box-info-validator';
import { IAltModelResponceDTO } from '@app/core/services/http-services/gluelam/demand.service';
import { BeamMaterialTypeHelper } from '@app/shared/helpers/beam-material-type.helper';

marker('App.Cancel');
marker('GluePlan.COMPLETED');
marker('GluePlan.GENERATED');
marker('GluePlan.PLANNED');
marker('GluePlan.STARTED');
marker('GluePlan.SENT_BY_PLANNER');
marker('GluePlan.RECIVED_BY_PROD_SYSTEM');
marker('GluePlan.FAIL_TO_RECEIVE_BY_PROD_SYSTEM');
marker('GluePlan.PAUSED');
marker('GluePlan.CANCELED');
marker('GluePlan.RELEASED');
marker('App.ErrorEndDateLessThenStartDate');

@Component({
  selector: 'app-gluing-plan-modal-ng',
  templateUrl: './gluing-plan-modal-ng.component.html',
  styleUrls: ['./gluing-plan-modal-ng.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class GluingPlanModalNgComponent extends DialogContentBase implements OnInit, OnDestroy {
  isNew: boolean = false;
  dimensions: BeamLamminaDimension[] = [];
  states: IBeamMaterialGlueSetState[] = [];
  glueSets: BeamMaterialGlueSet[] = [];
  gluingPlanForm: FormGroup<CreateGluingPlanForm>;
  ramConfiguration: IPressbedRamConfigurationDTO[] = [];
  selectedMachine: IMachineDTO = null;
  selectedTabIndex: number = 0;
  defaultDimension = { width: 'Select All', beamLamminaDimensionID: null };
  lamellaBufferBoxValues: number[] = [];
  isShowGlueSetLamellaInstruction: boolean = false;
  lammelaInstructions: IGluesetLamellaInstruction[] = [];
  exchangeLammelaInstructions : IExchangeLammelaInstructions[] = [];
  plannedThiknessToUpdate : { [key: number]: number } = {};

  private readonly defaultLamellaBoxValue = 1;
  private readonly destroy$ = new Subject<void>();
  private readonly noOfHoursToAdd = 4;

  constructor(
    public readonly dialogRef: DialogRef,
    @Inject(DialogRef) public data: IGluPlanModalInput,
    private readonly dimensionService: DimensionService,
    private readonly bmgsService: GlueSetService,
    private readonly glueSetStateService: GlueSetStateService,
    private readonly gluelamStaticalMediatorService: GluelamStaticalMediatorService,
    private readonly notificationService: AppNotificationService,
    private readonly gluePlanService: GluePlanService,
    private readonly spinner: NgxSpinnerService,
    private readonly fb: FormBuilder
  ) {
    super(dialogRef);
    this.exchangeLammelaInstructions.push({
      plannedLamellaMaterialIndex : null,
      exchangedLamellas : [{ materialDescription: 'None', index: null }]
    });
  }

  ngOnInit(): void {
    this.loadInitialData();
    this.initializeForm();

    if (this.data.isStaticalPressGroup) {
      this.applyStaticalPressGroupSettings();
    } else {
      this.gluingPlanForm.get('beamLaminaDimentionId').setValidators([Validators.required]);
    }

    this.setupFormSubscriptions();
  }

  onMachienChange(value: IMachineDTO) {
    this.gluingPlanForm.controls.machineId.setValue(value.machineId);
    this.selectedMachine = value;
    this.setLamellaBufferBoxValues();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  //#region plannedLamellas

  previous() {
    this.selectedTabIndex = this.selectedTabIndex - 1;
  }

  next() {
    this.selectedTabIndex = this.selectedTabIndex + 1;
  }

  onSelect(e: SelectEvent): void {
    this.selectedTabIndex = e.index;
  }

  valueChange(value: any, gluesetId: number) {
    this.getGluesetLamellaInstructionFormGroup(gluesetId)?.controls.plannedLamellasWithBoxInfos.controls.forEach((x) => {
      x.controls.bufferBox.setValue(value);
    })
  }


  trackByIndex(index: number): number {
    return index;
  }

  isNextDisabled(): boolean {
    return this.data.gluingPlan.glueSetStateId <= GlueSetState.PLANNED || this.glueSets?.length <= 0 || this.selectedTabIndex == this.glueSets?.length - 1
  }

  getGluesetLamellaInstructionFormGroup(gluesetId: number): FormGroup<GluesetLamellaInstructionForm> {
    const glueSetArray = this.gluingPlanForm.controls.gluesetLamellaInstruction as FormArray;
    // Loop through the FormArray to find the FormGroup with the specific glueSetId
    for (let i = 0; i < glueSetArray.length; i++) {
      const formGroup = glueSetArray.at(i) as FormGroup<GluesetLamellaInstructionForm>;
      // If the glueSetId matches, return the FormGroup
      if (formGroup.controls.glueSetId.value === gluesetId) {
        return formGroup;
      }
    }

    // If no match is found, return null or handle accordingly
    return null;
  }

  getExchangeLammela(plannedMaterial:number): IExchangedLammela[]
  {
     return this.exchangeLammelaInstructions.find(x=>x.plannedLamellaMaterialIndex === plannedMaterial || x.plannedLamellaMaterialIndex === null)?.exchangedLamellas;
  }

  onBlockProductionClick(event: Event, glueSetId: number) {
    this.togglePlannedLamellaArrayDisabled(event.target['checked'], glueSetId);
  }

  onChangeLammelasClick(event: Event, glueSetId: number) {
    this.getExchangedLamellaInstructions(glueSetId, event.target['checked']);
  }

  onChangeLammelaPlanningthiknessClick(event: Event, glueSetId: number) {
    if (event.target['checked']) {
      this.getGluesetLamellaInstructionFormGroup(glueSetId)?.controls.plannigThikness.enable();
    } else {
      this.getGluesetLamellaInstructionFormGroup(glueSetId)?.controls.plannigThikness.disable();
    }
  }

  isUpdateModelsDisabled(glueSetId:number): boolean{
    return !this.getGluesetLamellaInstructionFormGroup(glueSetId)?.controls.changeLammelaPlanningthikness.value;
  }

  private populateBoxInfosFormArray(lamellasPlanned: IAltModelResponceDTO[], glueSetId: number): void {
    const plannedLamellas = lamellasPlanned.map((info) => this.fb.group<PlannedLamellasWithBoxInfosForm>({
      materialIndex: new FormControl<number>(info.index, [Validators.required]),
      materialDescription: new FormControl<string>(info.materialDescription),
      bufferBox: new FormControl((this.selectedMachine.maxLamellaBufferBox && this.selectedMachine.maxLamellaBufferBox > 0) ? null : this.defaultLamellaBoxValue, [Validators.required]),
      exchangedMaterialIndex : new FormControl<number>(null),
    })
    );

    const plannedLamellaArray = this.fb.array(plannedLamellas,
      { validators: plannedLamellaWithBoxInfoValidator });
    this.getGluesetLamellaInstructionFormGroup(glueSetId)?.setControl('plannedLamellasWithBoxInfos', plannedLamellaArray);
    this.togglePlannedLamellaArrayDisabled(false, glueSetId);
    this.toggleExchangedlammelaInstructions(false, glueSetId);
  }

  togglePlannedLamellaArrayDisabled(useBlockProduction: boolean, glueSetId: number) {

    this.getGluesetLamellaInstructionFormGroup(glueSetId)?.controls.useBlockProduction.setValue(useBlockProduction);

    if (useBlockProduction) {
      this.getGluesetLamellaInstructionFormGroup(glueSetId)?.controls.singleLamellaPlannedBoxValue.disable();
    } else {
      this.getGluesetLamellaInstructionFormGroup(glueSetId)?.controls.singleLamellaPlannedBoxValue.enable();
    }

    this.getGluesetLamellaInstructionFormGroup(glueSetId)?.controls.plannedLamellasWithBoxInfos.controls.forEach((element) => {
      if (useBlockProduction) {
        element.controls.bufferBox.enable();
      } else {
        element.controls.bufferBox.disable();
      }
    });
  }

  private toggleExchangedlammelaInstructions(changeLammelas : boolean, glueSetId: number) {
    this.getGluesetLamellaInstructionFormGroup(glueSetId)?.controls.changeLammelas.setValue(changeLammelas);

    this.getGluesetLamellaInstructionFormGroup(glueSetId)?.controls.plannedLamellasWithBoxInfos.controls.forEach((element) => {
      if (changeLammelas) {
        element.controls.exchangedMaterialIndex.enable();
      } else {
        element.controls.exchangedMaterialIndex.disable();
      }
    });
  }

  get gluesetLamellaInstruction() {
    return this.gluingPlanForm.get('gluesetLamellaInstruction') as FormArray;
  };

  private setLamellaBufferBoxValues(): void {
    if (this.selectedMachine?.maxLamellaBufferBox && this.selectedMachine?.maxLamellaBufferBox > 0) {
      this.lamellaBufferBoxValues = Array.from({ length: this.selectedMachine?.maxLamellaBufferBox }, (_, i) => i + 1);
    }
  }
  //#endregion


  addGluingPlan(): void {
    if (!this.gluingPlanForm.valid) {
      return;
    }

    this.spinner.show("gluelam-spinner");
    let glueplanToSave: GluingPlan;
    let formValue = this.gluingPlanForm.getRawValue();

    glueplanToSave = {
      gluingPlanID: formValue.gluingPlanID,
      name: formValue.name,
      instruction: formValue.instruction,
      beamLaminaDimentionId: formValue.beamLaminaDimentionId,
      glueSetStateId: formValue.glueSetStateId,
      dimension: null,
      plannedExecutionStartDate: formValue.plannedExecutionStartDate,
      plannedExecutionEndDate: formValue.plannedExecutionEndDate,
      machineId: formValue.machineId,
      lenghtOffset: formValue.lenghtOffset,
      length: formValue.length,
      gluePlanIndex: 0,
      glueSets: this.isShowGlueSetLamellaInstruction ? this.getGluesets() : [],
      machine: null,
      totalVolume: 0,
      beamIconId:null
    }
    const operation = this.isNew
      ? this.gluePlanService.addGluingPlan(glueplanToSave)
      : this.gluePlanService.updateGluingPlan(glueplanToSave);

    operation.pipe(takeUntil(this.destroy$))
             .subscribe({
                next: (gp) => {
                  this.processGluePlan(gp);
                },
                error: () => {
                  this.spinner.hide("gluelam-spinner");
                }
             });
  }

  closeDialog(): void {
    this.dialogRef.close({ isGluePlanModified: false });
  }

  lenghtOffsetValidator(gluePlan: GluingPlan): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      gluePlan.lenghtOffset = control.value;
      const result = this.gluelamStaticalMediatorService.validateLenghtOffset(gluePlan.lenghtOffset, gluePlan.length, this.selectedMachine);
      return result.length > 0 ? { exeedLenght: true } : null;
    };
  }

  onEndDateTimeChange(): void {
    const startDateTime = this.gluingPlanForm.get('plannedExecutionStartDate').value;
    const endDateTime = this.gluingPlanForm.get('plannedExecutionEndDate').value;

    if (endDateTime && startDateTime && endDateTime <= startDateTime) {
      this.notificationService.notifyErrorAppChanel('App.ErrorEndDateLessThenStartDate');
      this.gluingPlanForm.get('plannedExecutionEndDate').patchValue(null);
    }
  }

  isRAMConfigurationAbsent(): boolean {
    return !this.selectedMachine?.machineId || !this.selectedMachine?.pressbedRamConfigurations?.length;
  }

  updatePlanningthikness(glueSetId: number): void {
    this.plannedThiknessToUpdate[glueSetId] = this.getGluesetLamellaInstructionFormGroup(glueSetId)?.controls.plannigThikness?.value;
    this.loadLamellaInstructionsForGlueSet(glueSetId, this.plannedThiknessToUpdate[glueSetId]);
  }

  onStateValueChange(value: number): void {
    this.data.gluingPlan.glueSetStateId = value;
    this.isShowGlueSetLamellaInstruction =
      this.data.gluingPlan.glueSetStateId >= GlueSetState.RELEASED && !this.data.isStaticalScheduler;

    if (!this.isShowGlueSetLamellaInstruction) {
      this.clearLamellaInstructions();
      return;
    }

    if (this.isShowGlueSetLamellaInstruction && !this.glueSets?.length) {
      this.loadGlueSets();
      this.loadLamellaInstructions();
    }else{
      this.setLamellaInstructions(this.lammelaInstructions);
    }
  }

  private loadLamellaInstructions(): void {
    this.spinner.show();
    this.gluePlanService.getGlueSetLamellaInstructions(this.data.gluingPlan.gluingPlanID, null, null)
      .pipe(takeUntil(this.destroy$))
      .subscribe(lamellaInstructions => {
        this.spinner.hide();
        this.lammelaInstructions = lamellaInstructions;
        this.setLamellaInstructions(lamellaInstructions);
      });
  }

  private setLamellaInstructions(lamellaInstructions: IGluesetLamellaInstruction[]) {
    lamellaInstructions.forEach((instruction) => {
      let lamellaInstructions = this.fb.group<GluesetLamellaInstructionForm>({
        glueSetId: new FormControl(instruction.glueSetId),
        useBlockProduction: new FormControl(false, Validators.required),
        changeLammelas: new FormControl(false, Validators.required),
        changeLammelaPlanningthikness: new FormControl({value:false, disabled:BeamMaterialTypeHelper.isSpecialShape(instruction.beamMaterialType)}, Validators.required),
        plannigThikness: new FormControl({value:instruction.planningThikness, disabled:true}, Validators.required),
        chinkSaw: new FormControl(false, Validators.required),
        rightPlanningWidth: new FormControl(null, [Validators.pattern(RegexHelper.positiveWithOneDecimal)]),
        prePlanningWidth: new FormControl(null, [Validators.pattern(RegexHelper.positiveWithOneDecimal)]),
        plannedLamellasWithBoxInfos: this.fb.array<FormGroup<PlannedLamellasWithBoxInfosForm>>([]),
        singleLamellaPlannedBoxValue: new FormControl(
          (this.selectedMachine.maxLamellaBufferBox && this.selectedMachine.maxLamellaBufferBox > 0) ? null : this.defaultLamellaBoxValue, [Validators.required])
      });

      this.gluingPlanForm.controls.gluesetLamellaInstruction.push(lamellaInstructions);
      this.plannedThiknessToUpdate[instruction.glueSetId] = instruction.planningThikness;
      this.populateBoxInfosFormArray(instruction.plannedLamellasWithBoxInfos, instruction.glueSetId);
    });
  }


  private setGlueSetLamellaInstructions(lamellaInstructions: IGluesetLamellaInstruction[], glusetId: number) {
    lamellaInstructions.forEach((instruction) => {
      this.lammelaInstructions.find(x=>x.glueSetId === glusetId).plannedLamellasWithBoxInfos = instruction.plannedLamellasWithBoxInfos
      this.populateBoxInfosFormArray(instruction.plannedLamellasWithBoxInfos, instruction.glueSetId);
    });

    //reset form props
    this.getGluesetLamellaInstructionFormGroup(glusetId)?.controls.singleLamellaPlannedBoxValue.patchValue(null);
    this.getGluesetLamellaInstructionFormGroup(glusetId)?.controls.changeLammelaPlanningthikness.patchValue(false);
    this.getGluesetLamellaInstructionFormGroup(glusetId)?.controls.changeLammelas.patchValue(false);
    this.getGluesetLamellaInstructionFormGroup(glusetId)?.controls.plannigThikness.disable();
  }

  private loadLamellaInstructionsForGlueSet(glusetId: number, plannedThickness: number): void {
    this.spinner.show();
    this.gluePlanService.getGlueSetLamellaInstructions(this.data.gluingPlan.gluingPlanID, glusetId, plannedThickness)
      .pipe(takeUntil(this.destroy$))
      .subscribe(lamellaInstructions => {
        this.spinner.hide();
        this.setGlueSetLamellaInstructions(lamellaInstructions, glusetId);
      });
  }

  private clearLamellaInstructions(): void {
    const length = this.gluesetLamellaInstruction.length;
    for (let i = 0; i < length; i++) {
      this.gluesetLamellaInstruction.removeAt(0);
    }
  }

  private loadGlueSets(): void {
    const glueSets$ = this.bmgsService.getGlueSetByGluingPlanId(this.data.gluingPlan.gluingPlanID).pipe(takeUntil(this.destroy$));
    glueSets$.subscribe(glueSets => {
      if (glueSets && glueSets.length > 0) {
        this.glueSets = glueSets;
      } else {
        this.glueSets = [];
      }
    });
  }

  private getGluesets() {
    let gluesets: IBeamMaterialGlueSet[] = [];

    const gluesetIds = this.glueSets.map(x => x.beamMaterialGlueSetID);

    gluesetIds.forEach(id => {
        const gluesetval = this.getGluesetLamellaInstructionFormGroup(id)?.value;
        let glueset = {
            glueSetID: id,
            useBlockProduction: gluesetval.useBlockProduction,
            rightPlanningWidth: gluesetval.rightPlanningWidth,
            prePlanningWidth:gluesetval.prePlanningWidth,
            chinkSaw:gluesetval.chinkSaw,
            plannedThickness: this.plannedThiknessToUpdate[id],
            plannedLamellasWithBoxInfos: gluesetval.plannedLamellasWithBoxInfos.map((element) => ({
                materialIndex: element.materialIndex,
                bufferBox: gluesetval.useBlockProduction
                    ? element.bufferBox
                    : gluesetval.singleLamellaPlannedBoxValue,
                    exchangedMaterialIndex :  element.exchangedMaterialIndex
            }))
        } as IBeamMaterialGlueSet;

        gluesets.push(glueset);
    });

    return gluesets;
}

  private loadInitialData(): void {
    forkJoin({
      dimensions: this.dimensionService.getBeamLamminaDimentions(),
      glueSetStates: this.glueSetStateService.getGlusetStatesForPlan(this.data.gluingPlan.gluingPlanID)
    }).pipe(takeUntil(this.destroy$)).subscribe(result => {
      this.dimensions = result.dimensions;
      this.states = result.glueSetStates.filter(s => s.isValidForGluePlan && s.canChangeToState);
    });
  }

  private initializeForm(): void {
    this.isNew = this.data.isNew;

    this.gluingPlanForm = this.fb.group<CreateGluingPlanForm>({
      gluingPlanID: new FormControl(this.data.gluingPlan.gluingPlanID),
      name: new FormControl(this.data.gluingPlan.name, [Validators.required, Validators.minLength(3)]),
      instruction: new FormControl(this.data.gluingPlan.instruction),
      plannedExecutionStartDate: new FormControl(
        this.data.gluingPlan?.plannedExecutionStartDate ? new Date(this.data.gluingPlan?.plannedExecutionStartDate) : new Date(),
        [Validators.required]
      ),
      plannedExecutionEndDate: new FormControl(
        this.data.gluingPlan?.plannedExecutionEndDate
          ? new Date(this.data.gluingPlan?.plannedExecutionEndDate)
          : new Date(new Date().setHours(new Date().getHours() + this.noOfHoursToAdd)),
        [Validators.required]
      ),
      glueSetStateId: new FormControl(this.data.gluingPlan.glueSetStateId, [Validators.required]),
      machineId: new FormControl(this.data.gluingPlan.machineId, [Validators.required]),
      lenghtOffset: new FormControl(this.data.gluingPlan.lenghtOffset),
      length: new FormControl(this.data.gluingPlan.length),
      beamLaminaDimentionId: new FormControl(this.data.gluingPlan.beamLaminaDimentionId),
      gluesetLamellaInstruction: this.fb.array<FormGroup<GluesetLamellaInstructionForm>>([])
    });

    if (this.gluePlanService.isDisabledEndDate(this.data.gluingPlan)) {
      this.gluingPlanForm.get('plannedExecutionEndDate').disable();
    }

    if (this.data.isStaticalPressGroup) {
      this.gluingPlanForm.get("gluesetLamellaInstruction").disable();
    }
  }

  private applyStaticalPressGroupSettings(): void {
    const lengthOffsetControl = this.gluingPlanForm.get('lenghtOffset');
    lengthOffsetControl.setValidators([Validators.required, this.lenghtOffsetValidator(this.data.gluingPlan)]);
    this.gluingPlanForm.get('beamLaminaDimentionId').setValue(null);
    this.gluingPlanForm.get('length').disable();

    if (this.data.isStaticalScheduler && this.data.gluingPlan.glueSetStateId !== GlueSetState.RELEASED) {
      this.gluingPlanForm.disable();
      this.gluingPlanForm.get('glueSetStateId').enable();
    }
  }

  private setupFormSubscriptions(): void {
    this.gluingPlanForm.get('plannedExecutionStartDate').valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(startDate => this.calculateEndDate(startDate));
  }

  private calculateEndDate(startDate: Date): void {
    if (startDate) {
      const calculatedEndDate = new Date(new Date(startDate).setHours(new Date(startDate).getHours() + this.noOfHoursToAdd));
      this.gluingPlanForm.get('plannedExecutionEndDate').setValue(calculatedEndDate);
    }
  }

  private processGluePlan(gp: GluingPlan): void {
    gp.plannedExecutionStartDate = new Date(gp.plannedExecutionStartDate);
    gp.plannedExecutionEndDate = new Date(gp.plannedExecutionEndDate);
    this.dialogRef.close({ isGluePlanModified: true, gluePlan: gp });
    this.spinner.hide("gluelam-spinner");
  }

  private getExchangedLamellaInstructions(glueSetId: number, isChecked: boolean) {
    if (isChecked) {
      const plannedMaterialIndex = this.lammelaInstructions.find(x => x.glueSetId === glueSetId).plannedLamellasWithBoxInfos.map(x => x.index);
      this.gluePlanService.getExchangedLammelas(plannedMaterialIndex).subscribe(result => {
        this.exchangeLammelaInstructions = result;
        this.toggleExchangedlammelaInstructions(isChecked, glueSetId);
      });
    } else {
      this.toggleExchangedlammelaInstructions(isChecked, glueSetId);
      this.exchangeLammelaInstructions = [{
        plannedLamellaMaterialIndex : null,
        exchangedLamellas : [{ materialDescription: 'None', index: null }]
      }];
    }
  }
}
