<h1 translate>AoT.AotBalanceReport</h1>

<div class="bottom-space">
  <app-site-selector  [showLable]="false"
              (onSiteSelected)="onSiteChange()">
  </app-site-selector>
</div>

<div class="grid-section">
  <kendo-grid
  [kendoGridBinding]="aoTBalanceReports"
  [pageable]="true"
  [pageSize]="gridState.take"
  [skip]="gridState.skip"
  [filterable]="false"
  [loading]="loading"
  [size]="'small'"
  (cellClick)="cellClickHandler($event)">
  <kendo-grid-column field="entityGroupDescription" title="{{ 'EntityTranslation.EntityGroup' | translate }}"
    [filterable]="false">
    <ng-template kendoGridCellTemplate let-dataItem> {{ dataItem.entityGroupDescription }} </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="firstWeekNegativeForecastVolumeCount"  title="{{ periods[0].label }}" [filterable]="false">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >
      <div class="text-center"  (click)="onCellClick(dataItem, rowIndex, 0)" [ngClass]="{'negative-report' : dataItem.firstWeekNegativeForecastVolumeCount > 0,'selected-cell': selectedCellUniqueId === (dataItem.entityGroupDescription + '_' + rowIndex.toString() + '_' + 0) }">{{
        dataItem.firstWeekNegativeForecastVolumeCount }} </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="secondWeekNegativeForecastVolumeCount" title="{{ periods[1].label }}" [filterable]="false">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div class="text-center"  (click)="onCellClick(dataItem, rowIndex, 1)" [ngClass]="{'negative-report' : dataItem.secondWeekNegativeForecastVolumeCount > 0,'selected-cell': selectedCellUniqueId === (dataItem.entityGroupDescription + '_' + rowIndex.toString() + '_' + 1)}">{{
        dataItem.secondWeekNegativeForecastVolumeCount }} </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="thirdWeekNegativeForecastVolumeCount" title="{{ periods[2].label }}" [filterable]="false">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div class="text-center"  (click)="onCellClick(dataItem, rowIndex, 2)" [ngClass]="{'negative-report' : dataItem.thirdWeekNegativeForecastVolumeCount > 0,'selected-cell': selectedCellUniqueId === (dataItem.entityGroupDescription + '_' + rowIndex.toString() + '_' + 2)}">{{
        dataItem.thirdWeekNegativeForecastVolumeCount }} </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="fourthWeekNegativeForecastVolumeCount" title="{{ periods[3].label }}" [filterable]="false">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div class="text-center"  (click)="onCellClick(dataItem, rowIndex, 3)" [ngClass]="{'negative-report' : dataItem.fourthWeekNegativeForecastVolumeCount > 0,'selected-cell': selectedCellUniqueId === (dataItem.entityGroupDescription + '_' + rowIndex.toString() + '_' + 3)}">{{
        dataItem.fourthWeekNegativeForecastVolumeCount }} </div>
    </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="fifthWeekNegativeForecastVolumeCount" title="{{ periods[4].label }}" [filterable]="false">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <div class="text-center"  (click)="onCellClick(dataItem, rowIndex, 4)" [ngClass]="{'negative-report' : dataItem.fifthWeekNegativeForecastVolumeCount > 0,'selected-cell': selectedCellUniqueId === (dataItem.entityGroupDescription + '_' + rowIndex.toString() + '_' + 4)}"> {{
        dataItem.fifthWeekNegativeForecastVolumeCount }}
      </div>
    </ng-template>
  </kendo-grid-column>
</kendo-grid>

<app-aot-balance-report-detail *ngIf="showDetailGrid" [searchAoTBalanceReportCriteria]="searchAoTBalanceReportCriteria"></app-aot-balance-report-detail>
</div>
