<div class="grid-wrapper">
  <kendo-grid #grid
    [data]="beamOrderLinesGridData"
    [kendoGridSelectBy]="'rowId'"
    [pageable]="true"
    [reorderable]="true"
    [resizable]="true"
    [selectable]="true"
    [filterable]="true"
    [sortable]="true"
    [columnMenu]="false"
    [groupable]="true"
    [(selectedKeys)]="selectedIDs"
    [skip]="state.skip"
    [pageSize]="state.take"
    [sort]="state.sort"
    [filter]="state.filter"
    [group]="state.group"
    [style]="{'height': 'calc(100vh - 7em)'}"
    [loading]="loading"
    [size]="'small'"
    (dataStateChange)="dataStateChange($event)"
    (columnReorder)="saveUserGridSettings()"
    (columnResize)="saveUserGridSettings()"
    (columnVisibilityChange)="saveUserGridSettings()"
    (groupChange)="saveUserGridSettings()"
    (edit)="editHandler($event)"
    (selectionChange)="onSelect($event)">

    <ng-template kendoGridToolbarTemplate>
      <kendo-grid-column-chooser [allowHideAll]="true"></kendo-grid-column-chooser>
      <kendo-dropdownbutton
      [disabled]="selectedIDs.length < 1"
      [data]="rowActions"
      themeColor="primary">
        {{ "App.RowAction" | translate }} <b *ngIf="selectRowCount > 0"> ({{selectRowCount}})</b>
        <ng-template kendoDropDownButtonItemTemplate let-dataItem>
          <kendo-svg-icon [icon]="dataItem.svgIcon"></kendo-svg-icon> {{ dataItem.name | translate }}
        </ng-template>
      </kendo-dropdownbutton>
      <span>
        {{ lastUpdated | date:'shortTime'}}
      </span>
      <kendo-grid-spacer></kendo-grid-spacer>
      <span>{{ 'OrderViewTranslation.ShowPlanned' | translate }}</span>

      <kendo-switch
        [(ngModel)]="showPlanned"
        (valueChange)="onShowPlanedChanged($event)">
      </kendo-switch>

      <span>{{ 'OrderViewTranslation.SHOW_ONLY_WITH_NO_PLAN' | translate }}</span>

      <kendo-switch
        [(ngModel)]="showOnlyWithNoGluPlan"
        (valueChange)="onShowWithNoGluPlan($event)">
      </kendo-switch>

      <button
        [disabled]="disableOptimizerBtn"
        (click)="openOptimizationEditor()"
        style="align-self: baseline;"
        class="k-mt-2"
        kendoButton
        themeColor="primary">
        {{ "OptimizationOrderTranslation.Optimize" | translate }}
      </button>

      <button
        (click)="resetUserSettings()"
        class="k-mt-2"
        style="align-self: baseline;"
        kendoButton
        themeColor="error">
        {{ "OptimizationOrderTranslation.ResetSettings" | translate }}
      </button>

      <button
        kendoButton
        [svgIcon]="arrowRightIcon"
        themeColor="primary"
        (click)="resizeGrid(true)"
        *ngIf="!isLeftMajor">
      </button>

      <button
        kendoButton
        [svgIcon]="arrowLeftIcon"
        themeColor="primary"
        (click)="resizeGrid(false)"
        *ngIf="isLeftMajor">
      </button>

    </ng-template>

    <kendo-grid-checkbox-column
      [showSelectAll]="true"
      [includeInChooser]="false"
      [reorderable]="false"
      [resizable]="false"
      [width]="40">
    </kendo-grid-checkbox-column>

    <kendo-grid-column
      class="text-truncate"
      title="PackageID"
      field="transportPackageCode"
      [width]="100">
      <ng-template
      kendoGridGroupHeaderTemplate
      let-group
      let-field="field"
      let-value="value">
      <label class="k-form-field">
        <input
          id="selectGroup{{field}}{{value}}"
          class="k-checkbox group-check"
          (change)="selectGroup($event, group)"
          type="checkbox"
          kendoCheckBox>
        {{value}}
      </label>
    </ng-template>
    <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
      <kendo-grid-string-filter-cell  [showOperators]="false" [column]="column" [filter]="filter">
      </kendo-grid-string-filter-cell>
    </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.CustomerDesc' | translate }}"
      field="customerDesc"
      [width]="125">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell  [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.OrderComment' | translate }}"
      field="orderComment"
      [width]="125">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell  [showOperators]="false"
                                        [column]="column"
                                        [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.OrderRowComment' | translate }}"
      field="orderRowComment"
      [width]="175">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell  [showOperators]="false"
                                        [column]="column"
                                        [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.Order' | translate }}"
      field="orderID"
      [width]="75">
      <ng-template
        kendoGridCellTemplate
        let-dataItem
        let-rowIndex="rowIndex">
        <div [ngStyle]="{'color' : dataItem.Color}">{{ dataItem.orderID }}</div>
      </ng-template>
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
          <kendo-grid-string-filter-cell [showOperators]="false"
                                         [column]="column"
                                         [filter]="filter">
          </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      title="{{ 'OrderViewTranslation.BEAM_MAIN_PRODUCT' | translate }}"
      field="mainProduct"
      [width]="150">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell  [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
    title="{{ 'OrderViewTranslation.BeamMaterialType' | translate }}"
    field="beamMaterialType.name"
    [sortable]="false"
    [width]="150">
      <ng-template
       let-dataItem
       kendoGridCellTemplate>
        {{ getBeamMaterialType(dataItem.beamMaterialType.externalId)  | translate}}
      </ng-template>
      <ng-template
      kendoGridFilterCellTemplate
      let-filter
      let-column="column">
      <app-kendo-grid-drop-down-filter
          [data]="beamMaterialTypes"
          textField="text"
          valueField="key"
          [filter]="filter"
          [filterCode]="'beamMaterialExternalId'">
      </app-kendo-grid-drop-down-filter>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
        title="{{ 'OrderViewTranslation.BeamIcon' | translate }}"
        [filterable]="false"
        field="beamIconId"
        [width]="150">
        <ng-template
            kendoGridCellTemplate
            let-dataItem>
          <app-beam-icon [beamIconId]="dataItem.beamIconId" ></app-beam-icon>
          </ng-template>
    </kendo-grid-column>
    <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.SpecialFormName' | translate }}"
      field="specialFormName"
      [width]="150">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell  [showOperators]="false"
                                        [column]="column"
                                        [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.Radius' | translate }}"
      field="radius"
      [width]="75">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell  [showOperators]="false"
                                        [column]="column"
                                        [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.BEAM_PLANED_PROD_DATE' | translate }}"
      field="plannedProductionDate"
      [format]="{ date: 'short' }"
      [width]="200">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell    [showOperators]="false"
                                        [column]="column"
                                        [filter]="filter">
        </kendo-grid-date-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.BEAM_QUALITY' | translate }}"
      field="visualQuality"
      [width]="75">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell  [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.BEAM_PROFILE_CODE' | translate }}"
      field="planeProfileCode"
      [width]="75">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell  [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.BEAM_PROFILE_DESC' | translate }}"
      field="planeProfileDesc"
      [width]="75">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell  [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.OrderRow' | translate }}"
      field="orderRowID"
      [width]="75">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell  [showOperators]="false"
                                        [column]="column"
                                        [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.LatestProductionDate' | translate }}"
      field="latestProductionDate"
      [format]="{ date: 'short' }"
      [width]="175">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-date-filter-cell    [showOperators]="false"
                                        [column]="column"
                                        [filter]="filter">
        </kendo-grid-date-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.BeamLamminaDimensionId' | translate }}"
      field="BeamLamminaDimensionId"
      filter="numeric"
      [width]="100">
      <ng-template kendoGridFilterCellTemplate let-filter>
        <app-kendo-grid-drop-down-filter
                                          [data]="beamLamminaDimensions"
                                          [filter]="filter"
                                          textField="width"
                                          valueField="beamLamminaDimensionID"
                                          [filterCode]="'BeamLamminaDimensionId'"
                                          [defaultSelectedValue]="selectedBeamLaminaDimensionId">
        </app-kendo-grid-drop-down-filter>
      </ng-template>
      <ng-template
        kendoGridCellTemplate
        let-dataItem
        let-rowIndex="rowIndex">
        {{getBLDDescription(dataItem.BeamLamminaDimensionID)}}
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell  [showOperators]="false"
                                        [column]="column"
                                        [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.NumberOfLamminas' | translate }}"
      field="NumberOfLaminas"
      format="{0:0}"
      [width]="100">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
          <kendo-grid-string-filter-cell [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.ConstructionCertification' | translate }}"
      field="ConstructionCertification"
      [width]="75"
      [filterable]="true">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell  [showOperators]="false"
                                        [column]="column"
                                        [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.TrimmingLocation' | translate }}"
      field="trimmingLocation"
      [width]="150">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell  [showOperators]="false"
                                        [column]="column"
                                        [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.Species' | translate }}"
      field="species"
      [width]="75"
      [filterable]="true">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
          <kendo-grid-string-filter-cell [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.LamminaPlaneThickness' | translate }}"
      field="DefaultLamminaPlaneThickness"
      [width]="200">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell  [showOperators]="false"
                                        [column]="column"
                                        [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.Width' | translate }}"
      field="width"
      [width]="75"
      [filterable]="true">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
          <kendo-grid-numeric-filter-cell [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-numeric-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.Height' | translate }}"
      field="hight"
      [width]="75"
      [filterable]="true">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
          <kendo-grid-numeric-filter-cell [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-numeric-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.Length' | translate }}"
      field="length"
      [width]="75"
      [filterable]="true">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
          <kendo-grid-numeric-filter-cell [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-numeric-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.glueplans' | translate }}"
      field="gluingPlandIds"
      [width]="75">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell  [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      class="text-truncate"
      title="{{ 'App.Material' | translate }}"
      field="materialDescription"
      [width]="125">
      <ng-template
        kendoGridCellTemplate
        let-dataItem
        let-rowIndex="rowIndex">
        {{dataItem.materialDescription}}
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell  [showOperators]="false"
                                        [column]="column"
                                        [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.Qty' | translate }}"
      field="Qty"
      [width]="75">
      <ng-template
        kendoGridCellTemplate
        let-dataItem
        let-rowIndex="rowIndex">
        <kendo-svg-icon
          *ngIf="dataItem.QtyAlloatedOnDemand > (dataItem.transportPackageAllocation.Qty + dataItem.transportPackageAllocation.unitsExtra)"
          themeColor="warning"
          [icon]="warningTriangleIcon"
          ></kendo-svg-icon>
        <span *ngIf="dataItem.consumedBML > 0" style="color: rgb(40, 205, 40)">({{ dataItem.consumedBML}}) </span>
        {{ dataItem.QtySumUI | number}}+
        <span style="color:#007bff">{{ dataItem.QtyPlanned}}</span>
        /{{dataItem.transportPackageAllocation?.qty}}
        <span
          *ngIf="dataItem.transportPackageAllocation?.unitsExtra > 0"
          style="color:red">+{{dataItem.transportPackageAllocation?.unitsExtra }}</span>
      </ng-template>
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
        <kendo-grid-string-filter-cell  [showOperators]="false"
                                        [column]="column"
                                        [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      class="text-truncate"
      title="{{ 'OrderViewTranslation.DeafultNumberOfBeamsPerBeamMaterial' | translate }}"
      field="deafultNumberOfBeamsPerBeamMaterial"
      [width]="100">
      <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell  [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-string-filter-cell>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
        class="text-truncate"
        title="{{ 'OrderViewTranslation.RawLamellaThickness' | translate }}"
        field="rawLamellaThickness"
        [width]="100"
        [editable]="false">
        <ng-template kendoGridFilterCellTemplate let-filter let-column="column">
          <kendo-grid-string-filter-cell  [showOperators]="false"
                                          [column]="column"
                                          [filter]="filter">
          </kendo-grid-string-filter-cell>
        </ng-template>
    </kendo-grid-column>

    <kendo-grid-column
      field="specification"
      [filterable]="false"
      title="{{ 'AoT.DemandSpecification'| translate  }}"
      [width]="80">
      <ng-template kendoGridCellTemplate let-dataItem>
        <button
          kendoButton
          themeColor="primary"
          (click)="addOrViewSpecification(dataItem)">
          {{ (dataItem.demandSpecificationsCount > 0 ? 'App.View' : 'App.Add') | translate  }}
        </button>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-command-column title="{{ 'App.Action' | translate }}"
                               [width]="160"
                               [reorderable]="false"
                               [resizable]="true">
      <ng-template kendoGridCellTemplate let-isNew="isNew">
        <button kendoGridEditCommand [primary]="true">
          {{ 'App.Edit' | translate }}
        </button>
      </ng-template>
    </kendo-grid-command-column>

    <ng-template
      kendoPagerTemplate
      let-totalPages="totalPages"
      let-currentPage="currentPage">
      <kendo-pager-prev-buttons></kendo-pager-prev-buttons>
      <kendo-pager-numeric-buttons [buttonCount]="10"></kendo-pager-numeric-buttons>
      <kendo-pager-next-buttons></kendo-pager-next-buttons>
      <kendo-pager-info></kendo-pager-info>
      <kendo-pager-page-sizes [pageSizes]="pageSizes"></kendo-pager-page-sizes>
    </ng-template>
  </kendo-grid>
<app-confirmation-dialog
  (conformationDialogCloseEvent)="confirmRemoveDemand($event)"
  *ngIf="removeDemandInProgress || removeDemandByRowInProgress || removeDemandByPackageInProgress">
</app-confirmation-dialog>
<app-confirmation-dialog *ngIf="confirmedResetSettings"
  (conformationDialogCloseEvent)="confirmResetSettings($event)" [msg]="'App.RemoveSettingConfirmMessage' | translate">
</app-confirmation-dialog>
