import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DemandTypeEnum } from "@app/core/models/aot-demand-type.enum";
import { PlanningTypeEnum } from "@app/core/models/aot-planning-type.enum";
import { IDemand } from "@app/core/models/demand-model";
import { CreateDemandForm } from "@app/core/models/forms/aot/demand-form/create-demand-form.model";
import { CommonHelper } from "@app/shared/helpers/common-helper";
import { RegexHelper } from "@app/shared/helpers/regex-helper";

export class DemandFormHelper {
  static initializeForm(fb: FormBuilder): FormGroup<CreateDemandForm> {
    return fb.group<CreateDemandForm>({
      siteId: fb.control(null, Validators.required),
      demandID: fb.control(null, Validators.required),
      entityGroupCode: fb.control(null, Validators.required),
      materialIndex: fb.control(0), // Fixed syntax
      externalItemID: fb.control(null), // Fixed syntax
      qty: fb.control(0, [Validators.required, Validators.pattern(RegexHelper.onlyPostiveWithDotComma)]),
      qtyUnit: fb.control('PCS'),
      length: fb.control(null, [Validators.required, Validators.pattern(RegexHelper.onlyPostiveWithDotComma)]),
      height: fb.control(null, [Validators.required, Validators.pattern(RegexHelper.onlyPostiveWithDotComma)]),
      width: fb.control(null, [Validators.required, Validators.pattern(RegexHelper.onlyPostiveWithDotComma)]),
      species: fb.control(null),
      specialFormName: fb.control({ value: '', disabled: true }),
      radius: fb.control({ value: '', disabled: true }, [Validators.required, Validators.pattern(RegexHelper.onlyPostiveWithDotComma)]),
      orderID: fb.control(null),
      latestProductionDate: fb.control(null, Validators.required),
      beamMaterialTypeID: fb.control(null),
      planeProfileId: fb.control(null),
      defaultLamminaPlaneThickness: fb.control(null, Validators.pattern(RegexHelper.positiveWithOneDecimal)),
      demandType: fb.control(DemandTypeEnum.StockOrder), // Fixed syntax
      isActive: fb.control(null),
      planningType: fb.control(PlanningTypeEnum.Normal),
      orderRowID: fb.control(null),
      beamOrderRowID: fb.control(null),
      instruction: fb.control(null),
    });
  }

  static mapFormToDemand(formValue: any): IDemand {
    return <IDemand>{
      demandID: formValue.demandID ?? 0,
      entityGroupCode: formValue.entityGroupCode,
      materialIndex: formValue.materialIndex,
      externalItemID: formValue.externalItemID,
      qty: Number(CommonHelper.replaceCommawithDot(formValue.qty ?? -1)),
      qtyUnit: formValue.qtyUnit,
      length: formValue.length ?? 0,
      hight: formValue.height ?? 0,
      width: formValue.width ?? 0,
      species: formValue.species,
      orderID: formValue.orderID,
      orderRowID: formValue.orderRowID,
      latestProductionDate: formValue.latestProductionDate,
      demandType: formValue.demandType,
      isActive: formValue.isActive,
      siteId: formValue.siteId,
      planningType: formValue.planningType,
      specialFormName: formValue.specialFormName,
      radius: Number(formValue.radius),
      beamMaterialTypeID: formValue.beamMaterialTypeID,
      planeProfileId: formValue.planeProfileId,
      defaultLamminaPlaneThickness: formValue.defaultLamminaPlaneThickness,
      customerDesc: 'Lager'
    };
  }

  static enableFields(fields: string[], form: FormGroup): void {
    fields.forEach((field) => form.get(field)?.enable());
  }

  static resetAndDisableFields(fields: string[], form: FormGroup) {
    fields.forEach(field => {
      form.get(field)?.reset('');
      form.get(field)?.disable();
    });
  }
}
