<div class="machine-view-container">
  <div  #machineview class="machine-view">
    <svg xmlns="http://www.w3.org/2000/svg" *ngIf="machine?.machineId > 0 && ramConfiguration.length > 0" overflow="auto">
      <rect *ngFor="let ram of ramConfiguration; let i = index" [attr.width]="getWidth(ram.width)"
        [attr.x]="getWidth(ram.position)" height="100%"></rect>

      <defs>
        <clipPath *ngFor="let item of dimensions; let i = index" [attr.id]="'clip_' + item.glueplanId + '_' + item.name">
          <rect [attr.id]="'clip_' + item.glueplanId +'_'+ item.name + '_rect'" [attr.width]="item.width - 10" [attr.height]="item.height" [attr.x]="item.x" [attr.y]="item.y + 10">
          </rect>
        </clipPath>
      </defs>

      <rect  *ngFor="let item of dimensions; let i = index" [draggable]="true" [attr.width]="item.width"
        [attr.height]="item.height" [attr.x]="item.x" [attr.y]="item.y + 10" [attr.id]="item.glueplanId + '_' + item.name"
        [attr.fill]="getColor(item.state)" (xAttributeChange)="onChangeOfset($event)" rx="5px" ry="10px">
        <title>
          {{item.name}} - {{item.glueplanId}} - {{item.date | siteDateFormat }}
        </title>
      </rect>

      <g class="d-flex" *ngFor="let item of dimensions; let i = index">

        <text [attr.id]="'name_' + item.glueplanId + '_' + item.name" cursor="pointer" fill="white" font-size="1em" [attr.x]="item.x + 10"
          [attr.y]="item.y + 25" alignment-baseline="middle" [attr.clip-path]="'url(#clip_' + item.glueplanId +'_' + item.name + ')'">
          <title>
            {{item.name}} - {{item.glueplanId}} - {{item.date | siteDateFormat }}
          </title>
         {{ cropName(item.name, item.width - 200) }} - {{item.glueplanId}} - {{item.date | siteDateFormat }}
        </text>
        <svg>
          <g>
            <foreignObject [attr.x]="item.x + item.width - 60" [attr.y]="item.y + 10" [attr.width]="50" [attr.height]="50">
                <app-beam-icon [beamIconId]="item.beamIconId"></app-beam-icon>
            </foreignObject>
          </g>
        </svg>
      </g>
    </svg>
  </div>
</div>
