import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarEventType } from '@app/core/models/calendar-event-type-enum';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { IProdOrderDTO, ProdOrderSequenceReturnDTO } from '@app/core/models/prod-order';
import { ProdOrderState } from '@app/core/models/prod-order-state.enum';
import { IScheduleLineDTO } from '@app/core/models/scheduleLineDTO.model';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';
import { DeviationService } from '@app/core/services/http-services/operative/deviation.service';
import { IMachineGroupDTO } from '@app/core/services/http-services/operative/machine-group.service';
import { MachineService } from '@app/core/services/http-services/operative/machine.service';
import { ProdOrderEventService } from '@app/core/services/http-services/operative/prod-order-event.service';
import { ProdOrderService } from '@app/core/services/http-services/operative/prod-order.service';
import { ScheduleService } from '@app/core/services/http-services/operative/schedule.service';
import { MbscCalendarColor, MbscCalendarEvent, MbscResource } from '@mobiscroll/angular';
import { MbscEventConnection, MbscEventDragEvent } from '@mobiscroll/angular/dist/js/core/components/eventcalendar/eventcalendar.types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AutoSchedulerInputPopupComponent } from '@app/modules/operative/controls/auto-scheduler-input-popup/auto-scheduler-input-popup.component';
import { DialogRef, DialogService } from '@progress/kendo-angular-dialog';
import { SiteDateFormatPipe } from '@app/shared/helpers/date-format.pipe';
import { BeamIconEnum } from '@app/core/models/demand-line.model';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { BeamIconTypeEnumHelper } from '@app/shared/helpers/beam-icon-type-enum-helper';

marker([
  'BeamIconType.Straight',
  'BeamIconType.Arched',
  'BeamIconType.FishBelly',
  'BeamIconType.GableRoof',
  'BeamIconType.SpecialForm',
  'BeamIconType.StraightWithElevation'
]);

@Injectable({
  providedIn: 'root'
})
export class ProdOrderCalendarService {

  beamIconTypes: { key: BeamIconEnum, text: string }[];

  constructor(private readonly prodOrderService: ProdOrderService,
        private readonly prodOrderEventService: ProdOrderEventService,
        private readonly machineService: MachineService,
        private readonly scheduleService: ScheduleService,
        private readonly deviationService: DeviationService,
        private readonly router: Router,
        private readonly dialog: DialogService,
        private readonly localStorageService:LocalStorageService,
        private readonly siteDateFormatPipe:SiteDateFormatPipe  ) { }

  getProdOrderEvents(date: Date, to: Date, mashines: number[]): Observable<MbscCalendarEvent[]> {
    this.beamIconTypes = BeamIconTypeEnumHelper.getBeamIconTypeEnumTranslations();
    return this.prodOrderEventService.getProdOrderEvents(date, mashines, to).pipe(map((prodOrders) => {
      return prodOrders.map(po => <MbscCalendarEvent>{
          id: po.id,
          start: new Date(po.start),
          end: new Date(po.end),
          allDay: false,
          title: po.title,
          resource: po.resource,
          stateText: po.stateText,
          stateId: po.stateId,
          editable: true,
          voiletesPOChain: po.voiletesPOChain,
          eventType: CalendarEventType.ProdOrder,
          sequenceId: po.sequenceIds.length > 0 ? po.sequenceIds[0] : null,
          tooltip: `${po.title}, ${this.siteDateFormatPipe.transform(po.start, true)} - ${this.siteDateFormatPipe.transform(po.end, true)}`,
          beamIcon: po.beamIconId ? this.beamIconTypes.find(d => d.key === po.beamIconId).text : null
        });
    }
    ));
  }

  getShedule(date: Date, to: Date, mashines: number[]): Observable<MbscCalendarColor[]> {
    return this.prodOrderEventService.getSchedule(date, mashines, to).pipe(map((sls) => {
      return sls.map(sl => <MbscCalendarColor>{
        start: new Date(sl.start),
        end: new Date(sl.end),
        resource: sl.resources,
        background: '#c8fdce',
        type: 'Non'
      });
    }));
  }

  getDeviations(from: Date, to: Date, mashines: number[]): Observable<MbscCalendarEvent[]> {
    return this.prodOrderEventService.getDeviations(from, to, mashines).pipe(map(ret =>
      ret.map(de => <MbscCalendarEvent> {
        start: new Date(de.start),
        end: new Date(de.end),
        color: 'red',
        title: de.title,
        resource: de.resources,
        eventType: CalendarEventType.Deviation
      })
    ));
  }

  getResource(mashineGroupId: number = null): Observable<{ resources: MbscResource[], mashines: IMachineDTO[] }> {
    return this.machineService.getmachines().pipe(map(ms => {
      let mashines = ms.data as IMachineDTO[];

      if (mashineGroupId) {
        mashines = mashines.filter(m => m.machineGroupId === mashineGroupId);
      }

      return {
        resources: mashines.map(m => <MbscResource>{ id: m.machineId, name: m.name, color: m.colorCode }),
        mashines
      };
    }));
  }

  createMbscColor(resourcesId: number[], validScheduleLines: IScheduleLineDTO[]): MbscCalendarColor[] {
    return validScheduleLines.map(x => {
      return <MbscCalendarColor>{
        start: x.start,
        end: x.end,
        resource: resourcesId,
        background: '#c8fdce',
        type: 'Non',
        dayOfWeek: x.dayOfWeek,
        recurring: {
          repeat: 'weekly',
          weekDays: ProdOrderCalendarService.getWeekdayString(x.dayOfWeek)
        }
      };
    });
  }

  getScheduleLines(machineGroupId: number): Observable<IScheduleLineDTO[]> {
    return this.scheduleService.getScheduleLinesbyMachinegroupId(machineGroupId).pipe(map(s => s.scheduleLines));
  }

  getDeviationsForWeek(date: Date, shiftId: number[]): Observable<MbscCalendarEvent[]> {
    const start = this.getMonday(date);
    const end = this.getMonday(date);

    end.setDate(end.getDate() + 8);

    return this.deviationService.query(start, end, null, shiftId, 0, 100).pipe(map(d => {
      if (shiftId !== null && shiftId.length > 0) {
        return d.data.map(de => <MbscCalendarEvent>{
          start: de.startDate,
          end: de.endDate,
          color: 'red',
          title: de.description
        });
      } else {
        return d.data.map(de => <MbscCalendarEvent>{
          start: de.startDate,
          end: de.endDate,
          color: 'red',
          title: de.description,
          resource: de.shiftDeviation
        });
      }
    }));
  }

  getDeviationsForMg(startDate: Date,
    endDate: Date,
    mashineGroups: IMachineGroupDTO[]): Observable<MbscCalendarEvent[]> {
    const mashineGroupIds = mashineGroups.map(i => i.machineGroupId);
    return this.deviationService.getDeviationsForMg(startDate, endDate, mashineGroupIds).pipe(map(ret => {
      const devEvents: MbscCalendarEvent[] = [];

      for (const mg of mashineGroups) {
        const mge = ret.filter(d => d.mashineGroupId === mg.machineGroupId);
        const mashineIds = mg.machines.map(i => i.machineId);
        for (const de of mge) {
          devEvents.push(<MbscCalendarEvent>{
            start: de.startDate,
            end: de.endDate,
            color: 'red',
            title: de.description,
            resource: mashineIds,
            eventType: CalendarEventType.Deviation
          });
        }
      }

      return devEvents;
    }));
  }

  rescheduleProdOrder(ev: MbscEventDragEvent, autoCalculatePOOverlap: boolean = false): Observable<number[]> {
    return this.prodOrderService.rescheduleProdOrder(<IProdOrderDTO>{
      prodOrderId: ev.event.id,
      plannedExecutionStartDate: ev.event.start,
      plannedExecutionEndDate: ev.event.end,
      autoCalculatePOOverlap,
      machineId: ev.resource
    });
  }

  getConnections(sequenceId: string): Observable<MbscEventConnection[]> {
    return this.prodOrderService.getProdOrderSequence(sequenceId).pipe(map(s => {
      return this.getConnectionsResult(s);
    }));
  }

  getConnectionsResult(s: ProdOrderSequenceReturnDTO): MbscEventConnection[] {
    const c = s.prodOrderSequence.filter(d => d.dependsOfId || d.dependsOnId);
    return c.map(e => <MbscEventConnection>{
      from: e.prodOrderId,
      to: (e.dependsOfId ?? e.dependsOnId),
      color: e.colorCode,
      arrow: (e.dependsOfId ? 'to' : 'from')
    });
  }

  static getWeekdayString(number) {
    switch (number) {
      case 1:
        return 'MO';
      case 2:
        return 'TU';
      case 3:
        return 'WE';
      case 4:
        return 'TH';
      case 5:
        return 'FR';
      case 6:
        return 'SA';
      case 0:
        return 'SU';
      default:
        break;
    }
  }

  calcRefDate(mbscTimelineType: string, selectedDate: Date): Date {
    switch (mbscTimelineType) {
      case 'day':
        return selectedDate;
      case 'week':
        return this.getMonday(selectedDate);
      case 'month': {
        const d = (selectedDate as Date);
        d.setDate(1);
        return d;
      }
    }
  }

  getMonday(d): Date {
    d = new Date(d);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
    const date = new Date(d.setDate(diff));
    date.setHours(0);
    date.setMinutes(0);
    return date;
  }

  editPO(prodOrderId) {
    console.log('edit = ' + prodOrderId);
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/prod-order/${prodOrderId}`])
    );
    window.open(url, '_blank');
  }

  copyPO(prodOrderId) {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/prod-order-copy', prodOrderId])
    );
    window.open(url, '_blank');
  }

  formatDateToNumber(date: any): number {
    return new Date(date).setHours(0, 0, 0, 0);
  }

  // This is the same logic writtern in the API side to get the correct schedule lines.
  getValidScheduleLines(date: Date, scheduleLines: IScheduleLineDTO[]): IScheduleLineDTO[] {
    const validScheduleLines: IScheduleLineDTO[] = [];
    const firstday = this.getMonday(date);

    for (let index = 0; index < 7; index++) {
      const timepointer = new Date(new Date(firstday).setDate(new Date(firstday).getDate() + index));
      let scheduleLinesByDayOfWeek = scheduleLines.filter(i => i.dayOfWeek === timepointer.getDay());

      if (scheduleLinesByDayOfWeek.length > 0) {
        scheduleLinesByDayOfWeek.filter(x => x.validFrom === null && x.validTo === null).forEach(element => {
          validScheduleLines.push(element);
        });
        const scheduleLinesWithValidFromTo = scheduleLinesByDayOfWeek.filter(x => !(x.validFrom === null && x.validTo == null));
        const scheduleLineAsPerValidFromAndTo = scheduleLinesWithValidFromTo.filter(x => ((this.formatDateToNumber(timepointer) >= this.formatDateToNumber(x.validFrom) && this.formatDateToNumber(timepointer) <= this.formatDateToNumber(x.validTo))) ||
          ((this.formatDateToNumber(timepointer) >= this.formatDateToNumber(x.validFrom) && x.validTo === null)) ||
          ((x.validFrom === null && this.formatDateToNumber(timepointer) <= this.formatDateToNumber(x.validTo)))
        );

        if (scheduleLineAsPerValidFromAndTo.length > 0) {
          scheduleLinesByDayOfWeek = scheduleLineAsPerValidFromAndTo;
        } else {
          scheduleLinesByDayOfWeek = scheduleLinesByDayOfWeek.filter(x => x.validFrom === null && x.validTo === null);
        }

        scheduleLinesByDayOfWeek.forEach(element => {
          validScheduleLines.push(element);
        });
      }
    }
    return validScheduleLines;
  }

  openAutoSchedulerPopup(): DialogRef {
    const dialogRef =  this.dialog.open({
      content: AutoSchedulerInputPopupComponent,
      height: 'auto',
      width: 'auto'});
      return dialogRef;
  }

  isDeleteAllowed(stateId: any): boolean {
    return stateId > ProdOrderState.Planned;
  }

  setAutoSchedulerLocalStorage() {
    if (JSON.parse(this.localStorageService.getItem(LocalStorageService.selectedAutoScheduler)) === null) {
      this.localStorageService.setItem(LocalStorageService.selectedAutoScheduler, 'false');
    }
  }
}
