import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { DataStateChangeEvent, GridComponent, GridDataResult } from '@progress/kendo-angular-grid';
import { process, State } from '@progress/kendo-data-query';
import { SiteDateFormatPipe } from '@app/shared/helpers/date-format.pipe';
import { AoTBalanceService } from '@app/core/services/http-services/aot/aot-balance.service';
import { ISearchAoTBalanceCriteria } from '@app/core/models/searchAoTBalanceCriteria.model';
import { AoTGrouping } from '@app/core/models/aot-grouping.enum';
import { IAoTGridResultPeriodDTO } from '@app/core/models/aotGridResultPeriodDTO.model';
import { AoTSearchResultLineDTO, AoTSearchResultSumDTO, IAoTGridResultLineDTO } from '@app/core/models/aotGridResultLineDTO.model';
import { AoTOrderTypeEnum } from '@app/core/models/aot-order-type.enum';
import { IAoTBalanceDetailSearchCriteriaDTO } from '@app/core/models/aotBalanceDetailSearchCriteriaDTO.model';
import { AoTPeriodTypeEnum } from '@app/core/models/aot-period-type.enum';
import { KendoGridSetUp } from '@app/core/services/custom-services/kendo-grid-setup';
import { Subject, takeUntil } from 'rxjs';
import { SVGIcon, arrowUpIcon, arrowDownIcon } from '@progress/kendo-svg-icons';

@Component({
  selector: 'app-aot-grid-main',
  templateUrl: './aot-grid-main.component.html',
  styleUrls: ['./aot-grid-main.component.scss',
    './aot-grid-main-theme.component.scss'
  ]
})
export class AotGridMainComponent implements OnDestroy {
  gridData: GridDataResult;
  selectedCellUniqueId: string = '';
  aotBalanceSearchSelectedCriteria: ISearchAoTBalanceCriteria = null;
  loading: boolean = false;
  searchresult = <GridDataResult>{ data: [], total: 0 };
  aoTGrouping = AoTGrouping;
  periods: IAoTGridResultPeriodDTO[] = [];
  resultSum: AoTSearchResultSumDTO;
  fixedLength: number = 3;
  currentPeriodCount: number = 0;
  state: DataStateChangeEvent = this.setState();
  aotOrderType: typeof AoTOrderTypeEnum = AoTOrderTypeEnum;
  isForecastVolume: boolean = true;

  private destroy$ = new Subject<void>();

  @ViewChild('aotMainGrid') private aotMainGrid: GridComponent;

  // eslint-disable-next-line accessor-pairs
  @Input() set aotBalanceSelectedCriteriaInput(criteria: ISearchAoTBalanceCriteria) {
    if (criteria !== undefined) {
      this.searchAoTBalance(criteria);
    }
  };

  @Input() set changeForecastVolume(value: boolean) {
    if (value !== null) {
      this.isForecastVolume = value;
      this.dataStateChange(this.state);
    }
  }

  @Output() disableSearchButtonEvent = new EventEmitter<boolean>();
  @Output() onRefreshRequested = new EventEmitter<boolean>();

  // FOR DETAIL
  @Output() handleAoTMainGridRowClick = new EventEmitter<IAoTBalanceDetailSearchCriteriaDTO>();

  public arrowUpIcon:SVGIcon = arrowUpIcon;
  public arrowDownIcon:SVGIcon = arrowDownIcon;

  constructor(private readonly aoTBalanceService: AoTBalanceService,
              private readonly dateFormatPipe: SiteDateFormatPipe) {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  searchAoTBalance(criteria: ISearchAoTBalanceCriteria) {
    this.disableSearchButtonEvent.emit(true);
    this.aotBalanceSearchSelectedCriteria = criteria;
    this.currentPeriodCount = this.aotBalanceSearchSelectedCriteria.period.periodCount;
    this.aotBalanceSearchSelectedCriteria.parentGroupText = '';
    this.selectedCellUniqueId = '';
    if(this.aotBalanceSearchSelectedCriteria.siteId)
      this.callSearchAPI();
  }

  callSearchAPI() {
    const propertiesToFormat = [
      'inStock', 'overdue', 'overdueSecondary', 'beyondForecast', 'beyond',
      'beyondForecastSecondary', 'beyondSecondary', 'period1Forecast', 'period1',
      'period1ForecastSecondary', 'period1Secondary', 'period2Forecast', 'period2',
      'period2ForecastSecondary', 'period2Secondary', 'period3Forecast', 'period3',
      'period3ForecastSecondary', 'period3Secondary', 'period4Forecast', 'period4',
      'period4ForecastSecondary', 'period4Secondary', 'period5Forecast', 'period5',
      'period5ForecastSecondary', 'period5Secondary', 'period6Forecast', 'period6',
      'period6ForecastSecondary', 'period6Secondary', 'period7Forecast', 'period7',
      'period7ForecastSecondary', 'period7Secondary', 'period8Forecast', 'period8',
      'period8Secondary', 'period8ForecastSecondary', 'period9Forecast', 'period9',
      'period9ForecastSecondary', 'period9Secondary', 'period10Forecast', 'period10',
      'period10ForecastSecondary', 'period10Secondary'
    ];
    this.loading = true;
    this.aoTBalanceService.searchAoTBalance(this.aotBalanceSearchSelectedCriteria)
    .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (x) => {
          this.searchresult = <GridDataResult>{
            data: this.formatResultLinesData(x.resultLines, propertiesToFormat),
            total: 0
          };
          this.periods = x.periods;
          this.resultSum = this.formatResultSumData(x.resultSum, propertiesToFormat);
          this.state.skip = 0;
          this.loadData();
          this.disableSearchButtonEvent.emit(false);
          this.loading = false;
        },
        error: (err) => {
          this.loading = false;
          console.log(err);
        }
      });
  }

  dataStateChange(state: DataStateChangeEvent): void {
    this.state = state;
    this.loadData();
  }

  getAoTBalanceDetail(dataItem: IAoTGridResultLineDTO, periodId: number, periodVolumeUniqueId: number, colomName: string, orderType: number, isChild: boolean) {
    this.selectedCellUniqueId = (orderType.toString() + '_' + isChild.toString() + '_' + periodVolumeUniqueId + '_' + colomName + '_' + dataItem.parentGroupText);
    const aoTBalanceDetailSearchCriteriaDTO = <IAoTBalanceDetailSearchCriteriaDTO>{
      unitCode: this.aotBalanceSearchSelectedCriteria.unitCode,
      entityGroupIndex: this.aotBalanceSearchSelectedCriteria.entitygroupIndex,
      periodTypeId: this.aotBalanceSearchSelectedCriteria.period.periodType,
      groupId: this.aotBalanceSearchSelectedCriteria.group,
      periodId,
      groupValue: dataItem.groupingName,
      parentGroupText: dataItem.parentGroupText,
      colomName,
      orderType,
      siteId: this.aotBalanceSearchSelectedCriteria.siteId
    };

    this.handleAoTMainGridRowClick.emit(aoTBalanceDetailSearchCriteriaDTO);
  }

  getPeriodTitle(index: number): string {
    if ((index === undefined) || (index === null) || index >= this.periods.length) {
      return '';
    }
    return (this.aotBalanceSearchSelectedCriteria.period.periodType === AoTPeriodTypeEnum.Day)
      ? this.dateFormatPipe.transform(this.periods[index].label)
      : this.periods[index].label;
  }

  getColomName(colomName: string): string {
    if ((colomName !== undefined) && (colomName !== null)) {
      return colomName;
    }
    return null;
  }

  onProdOrderCreation(d: boolean) {
    this.onRefreshRequested.emit(d);
  }

  showPeriodColumn(index: number) {
    return index < this.periods.length;
  }

   getColor(value: number): string {
    if (this.isForecastVolume) {
      return value < 0 ? 'red' : 'black';
    }
  }

   isAboveMax(dataItem: any, valuetocompare: number): boolean {
    if (this.isForecastVolume && dataItem.targetMaxStock !== null) {
      return valuetocompare > dataItem.targetMaxStock;
    }
  }

   isBelowMin(dataItem: any, valuetocompare: number): boolean {
    if (this.isForecastVolume && dataItem.targetMinStock !== null) {
      return valuetocompare < dataItem.targetMinStock;
    }
  }

  disableContextMenu(contextMenuId: number, isChild: boolean) {
    if (contextMenuId === 1) {
      if (this.aotBalanceSearchSelectedCriteria.group === 0 || isChild) {
        return false;
      }
    }
    return true;
  }

  loadChild(e) {
    const selectedParentItem = e.dataItem as AoTSearchResultLineDTO;
    this.aotBalanceSearchSelectedCriteria.parentGroupText = selectedParentItem.groupingName;
    if (selectedParentItem.childGrid === undefined) {
      this.aoTBalanceService.searchChildAoTBalance(this.aotBalanceSearchSelectedCriteria)
      .pipe(takeUntil(this.destroy$))
        .subscribe(d => {
          selectedParentItem.childGrid = d.resultLines;
        });
    }
  }

  private collapseAoTMainGridRow() {
    this.searchresult.data.forEach((_, index) => {
      this.aotMainGrid.collapseRow(index);
    });
  }

  setState(): DataStateChangeEvent {
    return {
      skip: 0,
      take: KendoGridSetUp.pageSize,
      filter: {
        logic: 'and',
        filters: []
      },
      sort: []
    };
  }

  showSecondaryColumn() {
    return this.aotBalanceSearchSelectedCriteria.orderType === AoTOrderTypeEnum['Comparison'];
  }

  private formatResultLinesData(resultLines: AoTSearchResultLineDTO[], propertiesToFormat: string[]): AoTSearchResultLineDTO[] {
    resultLines.forEach(line => {
      propertiesToFormat.forEach(property => {
        if (typeof line[property] === 'number') {
          line[property] = Number(line[property].toFixed(this.fixedLength));
        }
      });
    });

    return resultLines;
  }

  private formatResultSumData(resultSum: AoTSearchResultSumDTO, propertiesToFormat: string[]): AoTSearchResultSumDTO {
    propertiesToFormat.forEach(property => {
      if (typeof resultSum[property] === 'number') {
        resultSum[property] = Number(resultSum[property].toFixed(this.fixedLength));
      }
    });
    return resultSum;
  }

  private loadData() {
    const d = process(this.searchresult.data, this.state);
    this.gridData = d;
    this.getFilterData();
    this.collapseAoTMainGridRow();
  }

  private getFilterData() {
    const defaultStateFilter: State = {
      filter: {
        logic: 'and',
        filters: []
      }
    };
    defaultStateFilter.filter = this.state.filter;

    const sum: AoTSearchResultSumDTO = {} as AoTSearchResultSumDTO;

    const filterData = process(this.searchresult.data, defaultStateFilter);
    filterData.data.forEach(d => {
      sum.stock = (sum.stock ?? 0) + d.inStock;
      sum.overdue = (sum.overdue ?? 0) + d.overdue;
      sum.overdueSecondary = (sum.overdueSecondary ?? 0) + d.overdueSecondary;
      sum.overdueForecast = (sum.overdueForecast ?? 0) + d.overdueForecast;
      sum.overdueForecastSecondary = (sum.overdueForecastSecondary ?? 0) + d.overdueForecastSecondary;
      sum.beyondForecast = (sum.beyondForecast ?? 0) + d.beyondForecast;
      sum.beyond = (sum.beyond ?? 0) + d.beyond;
      sum.beyondForecastSecondary = (sum.beyondForecastSecondary ?? 0) + d.beyondForecastSecondary;
      sum.beyondSecondary = (sum.beyondSecondary ?? 0) + d.beyondSecondary;
      sum.period1Forecast = (sum.period1Forecast ?? 0) + d.period1Forecast;
      sum.period1 = (sum.period1 ?? 0) + d.period1;
      sum.period1ForecastSecondary = (sum.period1ForecastSecondary ?? 0) + d.period1ForecastSecondary;
      sum.period1Secondary = (sum.period1Secondary ?? 0) + d.period1Secondary;
      sum.period2Forecast = (sum.period2Forecast ?? 0) + d.period2Forecast;
      sum.period2 = (sum.period2 ?? 0) + d.period2;
      sum.period2ForecastSecondary = (sum.period2ForecastSecondary ?? 0) + d.period2ForecastSecondary;
      sum.period2Secondary = (sum.period2Secondary ?? 0) + d.period2Secondary;
      sum.period3Forecast = (sum.period3Forecast ?? 0) + d.period3Forecast;
      sum.period3 = (sum.period3 ?? 0) + d.period3;
      sum.period3ForecastSecondary = (sum.period3ForecastSecondary ?? 0) + d.period3ForecastSecondary;
      sum.period3Secondary = (sum.period3Secondary ?? 0) + d.period3Secondary;
      sum.period4Forecast = (sum.period4Forecast ?? 0) + d.period4Forecast;
      sum.period4 = (sum.period4 ?? 0) + d.period4;
      sum.period4ForecastSecondary = (sum.period4ForecastSecondary ?? 0) + d.period4ForecastSecondary;
      sum.period4Secondary = (sum.period4Secondary ?? 0) + d.period4Secondary;
      sum.period5Forecast = (sum.period5Forecast ?? 0) + d.period5Forecast;
      sum.period5 = (sum.period5 ?? 0) + d.period5;
      sum.period5ForecastSecondary = (sum.period5ForecastSecondary ?? 0) + d.period5ForecastSecondary;
      sum.period5Secondary = (sum.period5Secondary ?? 0) + d.period5Secondary;
      sum.period6Forecast = (sum.period6Forecast ?? 0) + d.period6Forecast;
      sum.period6 = (sum.period6 ?? 0) + d.period6;
      sum.period6ForecastSecondary = (sum.period6ForecastSecondary ?? 0) + d.period6ForecastSecondary;
      sum.period6Secondary = (sum.period6Secondary ?? 0) + d.period6Secondary;
      sum.period7Forecast = (sum.period7Forecast ?? 0) + d.period7Forecast;
      sum.period7 = (sum.period7 ?? 0) + d.period7;
      sum.period7ForecastSecondary = (sum.period7ForecastSecondary ?? 0) + d.period7ForecastSecondary;
      sum.period7Secondary = (sum.period7Secondary ?? 0) + d.period7Secondary;
      sum.period8Forecast = (sum.period8Forecast ?? 0) + d.period8Forecast;
      sum.period8 = (sum.period8 ?? 0) + d.period8;
      sum.period8ForecastSecondary = (sum.period8ForecastSecondary ?? 0) + d.period8ForecastSecondary;
      sum.period8Secondary = (sum.period8Secondary ?? 0) + d.period8Secondary;
      sum.period9 = (sum.period9 ?? 0) + d.period9;
      sum.period9Forecast = (sum.period9Forecast ?? 0) + d.period9Forecast;
      sum.period9ForecastSecondary = (sum.period9ForecastSecondary ?? 0) + d.period9ForecastSecondary;
      sum.period9Secondary = (sum.period9Secondary ?? 0) + d.period9Secondary;
      sum.period10Forecast = (sum.period10Forecast ?? 0) + d.period10Forecast;
      sum.period10 = (sum.period10 ?? 0) + d.period10;
      sum.period10ForecastSecondary = (sum.period10ForecastSecondary ?? 0) + d.period10ForecastSecondary;
      sum.period10Secondary = (sum.period10Secondary ?? 0) + d.period10Secondary;
    });
    this.resultSum = sum;
  }


}
