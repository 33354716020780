import { Injectable } from "@angular/core";
import { AbstractControl, ValidatorFn } from "@angular/forms";
import { Demandline } from "@app/core/models/demand-line.model";

 @Injectable({
   providedIn: 'root'
 })

 export class VaildateOptimizerService {
 public validateHeight(beamOrderLines:Demandline[]): ValidatorFn {
    return (control: AbstractControl) => {
      for (const bml of beamOrderLines) {
        if (control?.value < bml.hight) {
          return { error: true };
        }
      }
      return null;
    };
  }

  public validateLength(beamOrderLines:Demandline[]): ValidatorFn {
    return (control: AbstractControl) => {
      for (const bml of beamOrderLines) {
        if (control?.value < bml.length) {
          return { error: true };
        }
      }
      return null;
    };
  }
}
