import { Component, OnInit, ViewChild } from '@angular/core';
import {
  MbscCalendarEvent, MbscEventcalendarOptions, MbscPopup, MbscPopupOptions, MbscResource, Notifications, setOptions, locale, MbscLocale,
  MbscCalendarColor, MbscEventConnection, MbscPageLoadingEvent
} from '@mobiscroll/angular';
import { LocalStorageService } from '@app/core/services/custom-services/localstorage.service';
import { FormControl, FormGroup } from '@angular/forms';
import { Subject, Subscription, forkJoin } from 'rxjs';
import { CalendarEventType } from '@app/core/models/calendar-event-type-enum';
import { DateType } from '@mobiscroll/angular/dist/js/core/util/datetime';
import { IMachineGroupDTO } from '@app/core/services/http-services/operative/machine-group.service';
import { ProdOrderService } from '@app/core/services/http-services/operative/prod-order.service';
import { IMachineDTO } from '@app/core/models/machineDTO';
import { UserRoleEnum } from '@app/shared/helpers/role-based-access.helper';
import { takeUntil } from 'rxjs/operators';
import { ProdOrderCalendarService } from '@app/modules/operative/services/prod-order-calendar.service';
import { ProdOrderRemoveDialogService } from '@app/modules/operative/services/prod-order-edit.service';
import { ProdOrderCustomService } from '@app/core/services/custom-services/prod-order-custom.service';
import { DialogCloseResult } from '@progress/kendo-angular-dialog';
import { SVGIcon, warningTriangleIcon } from '@progress/kendo-svg-icons';
import { CreateEventFilterForm } from '@app/core/models/forms/operative/prod-order/prod-order-calendar/create-event-filter-form.model';

setOptions({
  theme: 'material',
  themeVariant: 'light'
});

@Component({
  selector: 'app-prod-order-calendar-ng',
  templateUrl: './prod-order-calendar-ng.component.html',
  styleUrls: ['./prod-order-calendar-ng.component.css']
})
export class ProdOrderCalendarNgComponent implements OnInit {
  public eventFilterForm: FormGroup = new FormGroup<CreateEventFilterForm>({
    demandId: new FormControl<number|null>(null),
    overlapPO: new FormControl<boolean>(false)
  });

  deniedRoles = [UserRoleEnum.Operator];
  // Demand
  public demandId: string;

  // ProdOrders

  // Mashines
  public selectedMachineGroups: IMachineGroupDTO[] = [];
  public mashineIds: Set<number> = new Set<number>();

  // Dates
  public selectedDate: DateType = new Date();
  public refDate: DateType = new Date();
  public firstDate: Date = null;
  public lastDate: Date = null;
  public loading: boolean = true;

  // Edit Popup

  @ViewChild('popup', { static: false }) editProdOrderPopup!: MbscPopup;
  editProdOrderAnchor: HTMLElement | undefined;
  editProdOrderOptions: MbscPopupOptions = {
    display: 'bottom',
    contentPadding: false,
    fullScreen: true,
    responsive: {
      medium: {
        display: 'anchored',
        width: 300,
        fullScreen: false,
        touchUi: false
      }
    }
  };

  // Observables
  public loadScheduleSubs: Subscription;

  // Mbsc
  public mbscSelectedEvent!: MbscCalendarEvent;
  public isDeleteAllowed: boolean;
  public mbscEvents: MbscCalendarEvent[] = [];
  public mbscConnections: MbscEventConnection[] = [];
  public mbscResources: MbscResource[] = [];
  public mbscTimelineType: any = 'week';
  public mbscColors: MbscCalendarColor[] = [];
  public mbscLocale: MbscLocale = locale['en'];
  public slotDuration: number = 24 * 60;
  public isEditDisabled: boolean;

  private destroy$: Subject<void> = new Subject<void>();

  public mbscOptions: MbscEventcalendarOptions = {
    data: this.mbscEvents,
    firstDay: 1,
    dragToMove: true,
    dragTimeStep: 1,
    onSelectedDateChange: (ev) => {
      console.log('onSelectedDateChange');
      this.selectedDate = ev.date;
      this.correctRefDate();
    },
    onEventDragEnd: (ev) => {
      console.log('onEventDragEnd');
      this.loading = true;
      this.prodOrderCalendar.rescheduleProdOrder(ev, this.eventFilterForm.controls.overlapPO.value).subscribe(
        ret => {
          this.notify.snackbar({ message: `Sucsess ${ret.length} updated!` });
          this.loadSchedule();
        },
        _ => this.loadSchedule()
      );
    },
    onEventClick: (ev) => {
      this.mbscSelectedEvent = ev.event;
      this.isDeleteAllowed = this.prodOrderCalendar.isDeleteAllowed(ev.event.stateId);
      this.editProdOrderAnchor = ev.domEvent.currentTarget;
      this.mbscConnections = [];
      this.isEditDisabled = this.prodOrderCustomService.checkAccessForClosedPO(ev.event.stateId);

      // if selected event type is of prodOrder, open the popup
      if (this.mbscSelectedEvent.eventType === CalendarEventType.ProdOrder) {
        this.editProdOrderPopup.open();
      }

      if (ev.event.sequenceId) {
        setTimeout(() => {
          this.prodOrderCalendar.getConnections(ev.event.sequenceId).subscribe(con => {
            this.mbscConnections = con;
          });
        });
      }
    }
  };

  public warningTriangleIcon:SVGIcon = warningTriangleIcon;

  constructor(public prodOrderCalendar: ProdOrderCalendarService
    , private notify: Notifications
    , public localStorageService: LocalStorageService
    , public prodOrderService: ProdOrderService
    , private prodOrderRemoveService: ProdOrderRemoveDialogService
    , private prodOrderCustomService:ProdOrderCustomService) {
    this.mbscLocale = locale[this.localStorageService.getItem(LocalStorageService.selectedLocale)];
  }

  ngOnInit(): void {
    (this.selectedDate as Date).setHours(0);
    (this.selectedDate as Date).setMinutes(0);
    (this.refDate as Date).setHours(0);
    (this.refDate as Date).setMinutes(0);

    this.eventFilterForm.controls.demandId.valueChanges.subscribe(id => {
      if (id) {
        this.goToSequence(id);
      }
    });

    this.prodOrderCalendar.setAutoSchedulerLocalStorage();
    this.eventFilterForm.controls.overlapPO.setValue(JSON.parse(this.localStorageService.getItem(LocalStorageService.selectedAutoScheduler)));

    this.localStorageService.localeChangedSub.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.mbscLocale = locale[this.localStorageService.getItem(LocalStorageService.selectedLocale)];
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  populateMashineResource(date: Date, machineGroups: IMachineGroupDTO[]): void {
    const res: MbscResource[] = [];
    for (const mg of machineGroups) {
      mg.machines.forEach(m => {
        this.mashineIds.add(m.machineId);
      });

      for (const m of mg.machines) {
        res.push(this.genMbscResourceFromMschine(m));
      }
    }

    this.mbscResources = res;
  }

  private genMbscResourceFromMschine(m:IMachineDTO):MbscResource {
    return <MbscResource>{
      id: m.machineId,
      name: m.name,
      color: m.colorCode
    };
  }

  onPageLoading(args: MbscPageLoadingEvent) {
    this.firstDate = args.firstDay;
    this.lastDate = args.lastDay;

    setTimeout(() => {
      this.loadSchedule();
    });
  }

  loadSchedule() {
    this.loading = true;
    console.log('loadSchedule');

    if (this.selectedMachineGroups?.length === 0) {
      this.loading = false;
      console.log('No Mashines Selected'); // This normally happes during initial loding prevets duplicate calls
      return;
    }

    if (this.loadScheduleSubs) {
      this.loading = false;
      console.warn('Duplicate trigger!');
      return;
    }

    const machineGroupsIds = this.selectedMachineGroups.map(mg => mg.machineGroupId);

    this.loadScheduleSubs = forkJoin([
      this.prodOrderCalendar.getProdOrderEvents(this.firstDate, this.lastDate, machineGroupsIds),
      this.prodOrderCalendar.getDeviations(this.firstDate, this.lastDate, machineGroupsIds),
      this.prodOrderCalendar.getShedule(this.firstDate, this.lastDate, machineGroupsIds)
    ]).subscribe(resp => {
      // Prodorder Events and  deviations added
      this.loading = false;
      this.mbscEvents = resp[0].concat(resp[1]);
      this.mbscColors = resp[2];
      this.loading = false;
      this.loadScheduleSubs = null;
      this.refDate = this.firstDate;
      this.selectedDate = this.firstDate;
      this.notify.snackbar({ message: 'Loaded' });
    });
  }

  goToSequence(demandID: number) {
    console.log('setSelectedDemand');
    this.prodOrderService.getProdOrderSequenceFromDemand(demandID).subscribe(ret => {
      let firstDate = null;

      for (const s of ret.prodOrderSequence) {
        if (firstDate == null || firstDate > s.poStartDate) {
          firstDate = s.poStartDate;
        }
      }

      if (firstDate) {
        this.selectedDate = firstDate;
        this.correctRefDate();
      }

      this.mbscConnections = this.prodOrderCalendar.getConnectionsResult(ret);
    });
  }

  deleteEvent(prodOrderId: number) {
    this.editProdOrderPopup.close();
    if (prodOrderId > 0) {
      this.prodOrderRemoveService.showConfirmation([prodOrderId]).subscribe((r) => {
        if (r) {
          this.mbscEvents = this.mbscEvents.filter(item => item.id !== prodOrderId);
        }
      });
    }
  }

  popupCloseEvent(closePopup: boolean) {
    if (closePopup) {
      this.editProdOrderPopup.close();
    }
  }

  setSelectedMachineGroup(machineGroup: IMachineGroupDTO[]) {
    console.log('setSelectedMachineGroup');
    this.selectedMachineGroups = machineGroup;
    this.populateMashineResource(new Date(), this.selectedMachineGroups);
    this.loadSchedule();
  }

  public onOverlapPO(value: boolean) {
    this.localStorageService.setItem(LocalStorageService.selectedAutoScheduler, value.toString());
  }

  openAutoSchedulerPopup() {
      let dialogRef = this.prodOrderCalendar.openAutoSchedulerPopup();
      dialogRef.result.subscribe((d) => {
        if (d instanceof DialogCloseResult) {
          dialogRef.close();
        }else{
          this.loadSchedule();
        }
      });
  }

  changeSlotDuration(slotDuration: number) {
    this.slotDuration = slotDuration;
  }

  correctRefDate(): void {
    if (this.selectedDate < this.firstDate || this.selectedDate > this.lastDate) {
      this.refDate = this.prodOrderCalendar.calcRefDate(this.mbscTimelineType, this.selectedDate as Date);
    }
  };
}
