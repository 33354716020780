<ngx-spinner
  name="gluelam-spinner"
  type="ball-scale-multiple"
  [fullScreen]="true">
</ngx-spinner>

<form
  class="formcls"
  id="gluingPlanForm"
  (ngSubmit)="addGluingPlan()"
  [formGroup]="gluingPlanForm">
  <div class="k-d-flex">
    <kendo-splitter
      orientation="horizontal"
      style="height: 100%">

      <kendo-splitter-pane style="width: 60%">
        <div class="pane-content-left">

          <kendo-formfield>
            <kendo-label
              text="{{ 'GluePlan.Name' | translate }}">
            </kendo-label>
            <kendo-textbox
              formControlName="name">
            </kendo-textbox>
          </kendo-formfield>

          <kendo-formfield>
            <kendo-label
              text="{{ 'GluePlan.Instruction' | translate }}">
            </kendo-label>
            <kendo-textarea
              formControlName="instruction"
              [rows]="6">
            </kendo-textarea>
          </kendo-formfield>

          <app-machine-selector-dropdown
            [isStaticalPressGroup]="data?.isStaticalPressGroup"
            [isContinuousPressGroup]="data?.isContinuousPressGroup"
            formControlName="machineId"
            [machineId]="data?.gluingPlan?.machineId"
            (selectedMachine)="onMachienChange($event)">
          </app-machine-selector-dropdown>

          <kendo-formfield
            *ngIf="data?.isStaticalPressGroup">
            <kendo-label
              text="{{ 'GluePlan.EdgeOffset' | translate }}">
            </kendo-label>

            <kendo-numerictextbox
              *ngIf="isRAMConfigurationAbsent()"
              format="n"
              formControlName="lenghtOffset">
            </kendo-numerictextbox>

            <kendo-dropdownlist
              *ngIf="!isRAMConfigurationAbsent()"
              formControlName="lenghtOffset"
              [valuePrimitive]="true"
              [data]="selectedMachine?.pressbedRamConfigurations"
              [textField]="'position'"
              [valueField]="'position'">
              <ng-template
                kendoDropDownListItemTemplate
                let-dataItem>
                #{{ dataItem?.index }} - {{ dataItem.position }}
              </ng-template>
            </kendo-dropdownlist>

            <kendo-formerror
              *ngIf="gluingPlanForm.controls.lenghtOffset.errors?.exeedLenght"
              translate
              [translateParams]="{maxLength: selectedMachine?.maxPressLength}">
              GlueSet.WARNING_GLUEPLAN_LENGTH_EXCEEDED_MAX_PRESSBED_LENGTH
            </kendo-formerror>
          </kendo-formfield>

          <kendo-formfield
            *ngIf="data?.isStaticalPressGroup">
            <kendo-label
              text="{{ 'AoT.Lenght' | translate }}">
            </kendo-label>
            <kendo-numerictextbox
              [readonly]="true"
              format="n"
              formControlName="length">
            </kendo-numerictextbox>
          </kendo-formfield>

          <kendo-formfield>
            <kendo-label
              text="{{ 'GluePlan.PlannedExecutionStartDate' | translate }}">
            </kendo-label>
            <kendo-datetimepicker
              formControlName="plannedExecutionStartDate">
            </kendo-datetimepicker>
          </kendo-formfield>

          <kendo-formfield>
            <kendo-label
              text="{{ 'GluePlan.PlannedExecutionEndDate' | translate }}">
            </kendo-label>
            <kendo-datetimepicker
              formControlName="plannedExecutionEndDate"
              (valueChange)="onEndDateTimeChange()">
            </kendo-datetimepicker>
          </kendo-formfield>

          <kendo-formfield
            *ngIf="data?.isContinuousPressGroup">
            <kendo-label
              translate>
              GluePlan.Dimension
            </kendo-label>
            <kendo-dropdownlist
              formControlName="beamLaminaDimentionId"
              [valuePrimitive]="true"
              [data]="dimensions"
              [textField]="'width'"
              [readonly]="!this.data.isNew"
              [valueField]="'beamLamminaDimensionID'">
            </kendo-dropdownlist>
          </kendo-formfield>
         <kendo-formfield>
            <kendo-label
              text="{{ 'GluePlan.SelectState' | translate }}">
            </kendo-label>
            <kendo-dropdownlist
              formControlName="glueSetStateId"
              (valueChange)="onStateValueChange($event)"
              [valuePrimitive]="true"
              [data]="states"
              [textField]="'name'"
              [valueField]="'glueSetStateId'">
              <ng-template
                kendoDropDownListItemTemplate
                let-dataItem>
                {{ ("GluePlan." + dataItem.name.toUpperCase()) | translate }}
              </ng-template>
            </kendo-dropdownlist>
          </kendo-formfield>

        </div>
      </kendo-splitter-pane>

      <kendo-splitter-pane [resizable]="false" style="width: 40%">
        <div *ngIf="isShowGlueSetLamellaInstruction"
          formArrayName="gluesetLamellaInstruction"
          class="pane-content-right">
            <div>
              <kendo-tabstrip [scrollable]="true"
                (tabSelect)="onSelect($event)">
                <kendo-tabstrip-tab
                  *ngFor="let glueset of glueSets; index as i; trackBy: trackByIndex"
                  [selected]="selectedTabIndex == i">
                  <ng-template
                    kendoTabTitle>
                    <h4> {{ 'GluePlan.GS' | translate }} - {{ glueset?.beamMaterialGlueSetID }} </h4>
                  </ng-template>
                  <ng-template
                    kendoTabContent>
                    <ng-container *ngIf="getGluesetLamellaInstructionFormGroup(glueset?.beamMaterialGlueSetID)">
                      <div
                        [formGroup]="getGluesetLamellaInstructionFormGroup(glueset?.beamMaterialGlueSetID)">
                        <kendo-formfield>
                          <div class="k-d-flex k-flex-row">
                            <kendo-checkbox
                              #blockProduction
                              formControlName="useBlockProduction"
                              type="checkbox"
                              (click)="onBlockProductionClick($event, glueset?.beamMaterialGlueSetID)"
                              >
                            </kendo-checkbox>
                            <kendo-label
                              class="k-checkbox-label"
                              [for]="blockProduction"
                              text="{{ 'GluePlan.BlockProduction' | translate }}">
                            </kendo-label>
                          </div>
                        </kendo-formfield>
                        <kendo-formfield>
                          <div class="k-d-flex k-mt-1 k-flex-row">
                            <kendo-checkbox
                              #changeLammelas
                              formControlName="changeLammelas"
                              type="checkbox"
                              (click)="onChangeLammelasClick($event, glueset?.beamMaterialGlueSetID)"
                              >
                            </kendo-checkbox>
                            <kendo-label
                              class="k-checkbox-label"
                              [for]="changeLammelas"
                              text="{{ 'GluePlan.ChangeLammelas' | translate }}">
                            </kendo-label>
                          </div>
                        </kendo-formfield>
                        <div class="k-d-flex k-mt-1 k-flex-row">
                          <kendo-formfield  class="w-50">
                            <div class="k-d-flex">
                              <kendo-checkbox
                                  #changeLammelaPlanningthikness
                                  formControlName="changeLammelaPlanningthikness"
                                  type="checkbox"
                                  (click)="onChangeLammelaPlanningthiknessClick($event, glueset?.beamMaterialGlueSetID)">
                              </kendo-checkbox>
                              <kendo-label class="k-checkbox-label" [for]="changeLammelaPlanningthikness"
                                text="{{ 'GluePlan.ChangeLammelaPlanningthikness' | translate }}">
                              </kendo-label>
                            </div>
                          </kendo-formfield>
                          <kendo-formfield class="w-25 k-pr-xs">
                            <kendo-numerictextbox
                                formControlName="plannigThikness"
                                placeholder="{{ 'GluePlan.Planningthikness' | translate }}">
                            </kendo-numerictextbox>
                          </kendo-formfield>
                          <div class="w-25 k-m-auto">
                            <button [disabled]="isUpdateModelsDisabled(glueset?.beamMaterialGlueSetID)"
                                    (click)="updatePlanningthikness(glueset?.beamMaterialGlueSetID)"
                                    class="k-button-md k-w-full" kendoButton themeColor="primary"  type="button">
                              {{ 'GluePlan.Apply' | translate }}
                            </button>
                          </div>
                        </div>
                        <div class="k-w-full">
                          <div class="k-w-full">
                            <kendo-gridlayout [gap]="{cols:3, rows:6}" [cols]="[{ width: '40%' }, { width:'40%' }, { width: '20%' }]">
                              <kendo-gridlayout-item [row]="1" [col]="1">
                                <div><h3> {{ 'GluePlan.LamellasPlanned' | translate }} </h3></div>
                              </kendo-gridlayout-item>
                              <kendo-gridlayout-item [row]="1" [col]="2">
                                <div><h3> {{ 'GluePlan.LamellasExchanged' | translate }} </h3></div>
                              </kendo-gridlayout-item>
                              <kendo-gridlayout-item class="k-m-auto k-w-full" [row]="1" [col]="3">
                                <div class="k-d-flex k-justify-content-space-between">
                                    <kendo-dropdownlist
                                     class="k-w-full"
                                      [data]="lamellaBufferBoxValues"
                                      formControlName="singleLamellaPlannedBoxValue"
                                      [valuePrimitive]="true"
                                      (valueChange)="valueChange($event, glueset?.beamMaterialGlueSetID)">
                                   </kendo-dropdownlist>
                                </div>
                              </kendo-gridlayout-item>
                              <ng-container formArrayName="plannedLamellasWithBoxInfos"
                                *ngFor="let lamellaGroup of getGluesetLamellaInstructionFormGroup(glueset?.beamMaterialGlueSetID).controls.plannedLamellasWithBoxInfos.controls; let i = index; trackBy: trackByIndex">
                                <ng-container [formGroupName]="i">
                                  <kendo-gridlayout-item [row]="i+2" [col]="1">
                                    <kendo-label class="k-d-flex k-align-items-center">
                                      {{ lamellaGroup.get('materialDescription').value }}
                                    </kendo-label>
                                  </kendo-gridlayout-item>
                                  <kendo-gridlayout-item [row]="i+2" [col]="2">
                                    <kendo-formfield>
                                      <kendo-dropdownlist
                                        class="k-w-full"
                                        [data]="getExchangeLammela(lamellaGroup.get('materialIndex').value)"
                                        [valuePrimitive]="true"
                                        formControlName="exchangedMaterialIndex"
                                        [textField]="'materialDescription'"
                                        [valueField]="'index'"
                                        >
                                      </kendo-dropdownlist>
                                    </kendo-formfield>
                                  </kendo-gridlayout-item>
                                  <kendo-gridlayout-item [row]="i+2" [col]="3">
                                    <kendo-formfield>
                                      <kendo-dropdownlist
                                        class="k-w-full"
                                        [data]="lamellaBufferBoxValues"
                                        formControlName="bufferBox"
                                        [valuePrimitive]="true">
                                      </kendo-dropdownlist>
                                    </kendo-formfield>
                                  </kendo-gridlayout-item>
                                </ng-container>
                              </ng-container>
                            </kendo-gridlayout>
                            <div class="k-flex-column">
                              <div class="k-d-flex k-justify-content-end k-mt-sm"
                                *ngIf="getGluesetLamellaInstructionFormGroup(glueset?.beamMaterialGlueSetID)?.get('plannedLamellasWithBoxInfos').getError('invalidActualLamellas')">
                                <small class="error">{{ 'GluePlan.invalidActualLamellas' | translate}}</small>
                              </div>
                              <div class="k-d-flex k-justify-content-end k-mt-sm"
                                *ngIf="getGluesetLamellaInstructionFormGroup(glueset?.beamMaterialGlueSetID)?.get('plannedLamellasWithBoxInfos').getError('invalidBoxValues')">
                                <small class="error">{{ 'GluePlan.invalidBoxValues' | translate}} </small>
                              </div>
                            </div>
                          <div class="k-d-flex k-mt-xl">
                          <kendo-formfield>
                            <div class="k-d-flex k-flex-row">
                              <kendo-checkbox
                                #chinkSaw
                                formControlName="chinkSaw"
                                type="checkbox">
                              </kendo-checkbox>
                              <kendo-label
                                class="k-checkbox-label"
                                [for]="chinkSaw"
                                text="{{ 'GluePlan.ChinkSaw' | translate }}">
                              </kendo-label>
                            </div>
                          </kendo-formfield>
                        </div>
                        <div class="k-mt-2">
                          <kendo-formfield>
                            <kendo-label >{{ 'GluePlan.RightPlaningWidth' | translate}}</kendo-label>
                            <kendo-textbox  formControlName="rightPlanningWidth">
                            </kendo-textbox>
                          </kendo-formfield>
                        </div>
                          <div class="k-mt-4">
                          <kendo-formfield>
                            <kendo-label >{{ 'GluePlan.PrePlaningWidth' | translate}}</kendo-label>
                            <kendo-textbox   formControlName="prePlanningWidth">
                            </kendo-textbox>
                          </kendo-formfield>
                        </div>
                        </div>
                      </div>
                      </div>
                    </ng-container>
                  </ng-template>
                </kendo-tabstrip-tab>
              </kendo-tabstrip>
            </div>
            <div>
            </div>
            <div class="k-d-flex  k-justify-content-end">
              <button
                class="k-mr-1"
                (click)="previous()"
                [disabled]="this.selectedTabIndex == 0"
                kendoButton
                type="button"
                themeColor="primary">
                {{ 'App.Previous' | translate }}
              </button>
              <button
                class="k-mr-1"
                (click)="next()"
                [disabled]="isNextDisabled()"
                kendoButton
                type="button"
                themeColor="primary">
                {{ 'App.Next' | translate }}
              </button>
          </div>
        </div>
      </kendo-splitter-pane>

    </kendo-splitter>
  </div>
  <kendo-dialog-actions layout="end">
    <button
    kendoButton
    themeColor="error"
    type="button"
    (click)="closeDialog()"
  >
    {{ 'App.Close' | translate }}
  </button>
  <div style="flex-grow: 1;"></div>
  <button
    kendoButton
    themeColor="primary"
    form="gluingPlanForm"
    type="submit"
    *ngIf="isNew"
    [disabled]="!gluingPlanForm.valid"
  >
    {{ 'App.Add' | translate }}
  </button>
  <button
    kendoButton
    themeColor="primary"
    form="gluingPlanForm"
    type="submit"
    *ngIf="!isNew"
    [disabled]="!gluingPlanForm.valid"
  >
    {{ 'App.Update' | translate }}
  </button>
</kendo-dialog-actions>
</form>


