import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject, firstValueFrom } from 'rxjs';
import { BeamMaterialTypeService } from '@app/core/services/http-services/gluelam/beam-material-type.service';
import { IBeamMaterialType } from '@app/core/models/beam-material-type.model';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { Demandline } from '@app/core/models/demand-line.model';
import { BeamMaterialType } from '@app/core/models/beam-material-type-enum';
import { BeamMaterialTypeEnumHelper } from '@app/shared/helpers/beam-material-type-enum-helper';
import { BeamMaterialTypeHelper } from '@app/shared/helpers/beam-material-type.helper';

marker('App.UnitCode');

@Component({
  selector: 'app-select-beam-material-type',
  template: `
            <kendo-formfield>
            <kendo-label text="{{ 'OrderViewTranslation.BeamMaterialType' | translate }}"></kendo-label>
            <kendo-dropdownlist
              [data]="beamMaterialTypes"
              valueField="beamMaterialTypeID"
              textField="name"
              (valueChange)="onChangedVal($event)"
              [valuePrimitive]="true"
              [loading]="loading"
              [disabled]="isDisabled"
              [(value)]="selectedTypeId"
            >
            <ng-template kendoDropDownListValueTemplate let-dataItem>
              {{ getBeamMaterialTypeText(selectedTypeId) | translate }}
            </ng-template>
            <ng-template kendoDropDownListItemTemplate let-dataItem>
               {{ getBeamMaterialTypeText(dataItem.beamMaterialTypeID) | translate }}
            </ng-template>
            </kendo-dropdownlist>
          </kendo-formfield>
`,
  styleUrls: ['./select-beam-material-type.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SelectBeamMaterialTypeComponent
    }
  ]
})
export class SelectBeamMaterialTypeComponent implements OnInit, ControlValueAccessor, OnDestroy {
  beamMaterialTypes: IBeamMaterialType[] = [];
  selectedTypeId: number;
  loading: boolean = true;
  beamMaterialTypesEnum: { key: BeamMaterialType, text: string }[];

  @Input() isDisabled = false;
  @Input() showLable:boolean = false;
  @Input() disableTextbox:boolean = false;
  @Input() hiddenTypeIds:number[] = [];
  @Input() isManualBeam:boolean = false;
  @Input() demandLines: Demandline[] = [];
  @Output() selectedBeamMaterialType: EventEmitter<IBeamMaterialType> = new EventEmitter<IBeamMaterialType>();

  private onChanged: Function = () => { };
  private onTouched: Function = () => { };
  private destroy$ = new Subject<void>();

  constructor(private readonly beamMaterialTypeService: BeamMaterialTypeService) { }

  async ngOnInit() {
    this.beamMaterialTypesEnum = BeamMaterialTypeEnumHelper.getBeamMaterialTypeEnumTranslations();
    this.beamMaterialTypes = await firstValueFrom(this.beamMaterialTypeService.query());
    this.isManualBeam ?  this.filterManualBeamMaterialTypes() : this.onChangedVal(this.selectedTypeId);

    this.loading = false
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onChangedVal(selectedTypeId:number) {
    this.selectedTypeId = selectedTypeId;
    this.onTouched();
    this.onChanged(selectedTypeId);

    const bmt = this.beamMaterialTypes?.find(x => x.beamMaterialTypeID === selectedTypeId);
    this.selectedBeamMaterialType.emit(bmt);
  }

  writeValue(id: number): void {
    this.selectedTypeId = id;
  }

  registerOnChange(onChange: any): void {
    this.onChanged = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  getBeamMaterialTypeText(beamMaterialTypeID: number) {
    if (this.beamMaterialTypes && beamMaterialTypeID > 0) {
      let externalId = this.beamMaterialTypes?.find(x => x.beamMaterialTypeID === beamMaterialTypeID)?.externalId;
      if (externalId != null)
        return this.beamMaterialTypesEnum.find(x => x.key === externalId).text;
    }
    return "";
  }

  private filterManualBeamMaterialTypes() {
    this.beamMaterialTypes = this.beamMaterialTypes?.filter(type => this.isSelectableShape(type));
    this.setBeamMaterialTypeId();
  }

  private isSelectableShape(bm: IBeamMaterialType): boolean {
    return BeamMaterialTypeHelper.isManualShape(bm) || BeamMaterialTypeHelper.isUndefinedShape(bm);
  }

  private setBeamMaterialTypeId() {
    const beamMaterialTypeId = this.getBeamMaterialTypeId();
    this.onChangedVal(beamMaterialTypeId);
  }

  private getBeamMaterialTypeId(): number {
    const uniqueBeamMaterialTypeId = [...new Set(this.demandLines.map(item => item.beamMaterialType.beamMaterialTypeID))];

    if (uniqueBeamMaterialTypeId.length === 1 && this.beamMaterialTypes?.some(x => x.beamMaterialTypeID === uniqueBeamMaterialTypeId[0]))
      return uniqueBeamMaterialTypeId[0];

    return this.beamMaterialTypes?.filter(x => BeamMaterialTypeHelper.isUndefinedShape(x))[0].beamMaterialTypeID;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
