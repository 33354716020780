<kendo-grid #aotMainGrid
            [data]="gridData"
            style="width: 100%;flex-grow: 1;"
            [selectable]="true"
            [reorderable]="true"
            [resizable]="true"
            [filterable]="true"
            [filter]="state.filter"
            [pageable]="true"
            [pageSize]="state.take"
            [skip]="state.skip"
            (dataStateChange)="dataStateChange($event)"
            (detailExpand)="loadChild($event)"
            [height]="880"
            [loading]="loading"
            [sortable]= "true"
            [sort]="state.sort"
            [size]="'small'"
>

  <kendo-grid-column field="groupingLable" title="{{ 'AoT.Grouping' | translate }}">
        <ng-template
          kendoGridFilterCellTemplate
          let-filter
          let-column="column">
          <kendo-grid-string-filter-cell
              [showOperators]="false"
              [column]="column"
              [filter]="filter">
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container [ngTemplateOutlet]="renderGroupTemplate"
            [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: false }">
          </ng-container>
        </ng-template>
  </kendo-grid-column>

  <kendo-grid-column [filterable]="false" field="inStock" title="{{ 'AoT.Stock' | translate }}">
        <ng-template
          kendoGridFilterCellTemplate
          let-filter
          let-column="column">
          <kendo-grid-string-filter-cell
              [showOperators]="false"
              [column]="column"
              [filter]="filter">
          </kendo-grid-string-filter-cell>
        </ng-template>
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container [ngTemplateOutlet]="renderValueTemplate"
            [ngTemplateOutletContext]="{ $implicit: dataItem, value: dataItem.inStock, isChild: false }">
          </ng-container>
        </ng-template>
        <ng-template kendoGridFooterTemplate>
          <div class="text-right"> {{ resultSum?.stock | number : '1.3' }} </div>
        </ng-template>
  </kendo-grid-column>

  <kendo-grid-column-group  title="{{ 'AoT.Overdue' | translate }}">
    <kendo-grid-column [filterable]="false" title="{{  'AoT.Main' | translate}}"
    field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'overdueForecast' : 'overdue') }}">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
                      [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: false, periodId: periods[0].periodId , uniqueId: dataItem.firstPeriodUniqueId, firstDateInPeriod: dataItem.firstPeriodDate
                                                  , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.overdueForecast : dataItem.overdue)
                                                  , colomName: 'overdue', showContextMenu: true, orderType:aotOrderType.Main }">
         </ng-container>
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        <div class="text-right"> {{ (aotBalanceSearchSelectedCriteria.showForecast ? resultSum?.overdueForecast :
          resultSum?.overdue) | number : '1.3' }} </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [filterable]="false" *ngIf="showSecondaryColumn()" title="{{ 'AoT.Comparison' | translate }}"
    field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'overdueForecastSecondary' : 'overdueSecondary') }}">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
                      [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: false, periodId: periods[0].periodId , uniqueId: dataItem.firstPeriodUniqueId, firstDateInPeriod: dataItem.firstPeriodDate
                                                  , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.overdueForecastSecondary : dataItem.overdueSecondary)
                                                  , colomName: 'overdue', showContextMenu: true, orderType:aotOrderType.Comparison }">
         </ng-container>
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        <div class="text-right"> {{ (aotBalanceSearchSelectedCriteria.showForecast ?
          resultSum?.overdueForecastSecondary : resultSum?.overdueSecondary) | number : '1.3' }} </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group *ngIf="showPeriodColumn(0)" title="{{ getPeriodTitle(0) }}">
    <kendo-grid-column [filterable]="false" *ngIf="showPeriodColumn(0)" title="{{  'AoT.Main' | translate}}"
      field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period1Forecast' : 'period1') }}">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
          [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: false, periodId: dataItem.firstPeriodId , uniqueId: dataItem.firstPeriodUniqueId, firstDateInPeriod: dataItem.firstPeriodDate
                                                  , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period1Forecast : dataItem.period1), showContextMenu: true, orderType: aotOrderType.Main }">
        </ng-container>
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        <div class="text-right"> {{ (aotBalanceSearchSelectedCriteria.showForecast ?
          resultSum?.period1Forecast : resultSum?.period1) | number : '1.3' }} </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [filterable]="false" *ngIf="showPeriodColumn(0) && showSecondaryColumn()" title="{{ 'AoT.Comparison' | translate }}"
      field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period1ForecastSecondary' : 'period1Secondary') }}">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
          [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: false, periodId: dataItem.firstPeriodId , uniqueId: dataItem.firstPeriodUniqueId, firstDateInPeriod: dataItem.firstPeriodDate
                                                  , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period1ForecastSecondary : dataItem.period1Secondary), showContextMenu: true , orderType:aotOrderType.Comparison }">
        </ng-container>
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        <div class="text-right"> {{ (aotBalanceSearchSelectedCriteria.showForecast ?
          resultSum?.period1ForecastSecondary : resultSum?.period1Secondary) | number : '1.3' }}
        </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group *ngIf="showPeriodColumn(1)" title="{{ getPeriodTitle(1) }}">
    <kendo-grid-column [filterable]="false" *ngIf="showPeriodColumn(1)" title="{{ 'AoT.Main' | translate }}"
      field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period2Forecast' : 'period2') }}">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
          [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: false, periodId: dataItem.secondPeriodId , uniqueId: dataItem.secondPeriodUniqueId, firstDateInPeriod: dataItem.secondPeriodDate
                                                  , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period2Forecast : dataItem.period2), showContextMenu: true, orderType:aotOrderType.Main }">
        </ng-container>
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        <div class="text-right"> {{ (aotBalanceSearchSelectedCriteria.showForecast ?
          resultSum?.period2Forecast : resultSum?.period2) | number : '1.3' }} </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column  [filterable]="false" *ngIf="showPeriodColumn(1) && showSecondaryColumn()" title="{{'AoT.Comparison' | translate  }}"
      field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period2ForecastSecondary' : 'period2Secondary') }}">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
          [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: false, periodId: dataItem.secondPeriodId , uniqueId: dataItem.secondPeriodUniqueId, firstDateInPeriod: dataItem.secondPeriodDate
                                                  , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period2ForecastSecondary : dataItem.period2Secondary), showContextMenu: true, orderType:aotOrderType.Comparison}">
        </ng-container>
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        <div class="text-right"> {{ (aotBalanceSearchSelectedCriteria.showForecast ?
          resultSum?.period2ForecastSecondary : resultSum?.period2Secondary) | number : '1.3' }}
        </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group *ngIf="showPeriodColumn(2)" title="{{ getPeriodTitle(2) }}">
    <kendo-grid-column [filterable]="false" *ngIf="showPeriodColumn(2)" title="{{ 'AoT.Main' | translate }}"
      field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period3Forecast' : 'period3') }}">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
          [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: false, periodId: dataItem.thirdPeriodId , uniqueId: dataItem.thirdPeriodUniqueId, firstDateInPeriod: dataItem.thirdPeriodDate
                                                  , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period3Forecast : dataItem.period3), showContextMenu: true, orderType:aotOrderType.Main}">
        </ng-container>
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        <div class="text-right"> {{ (aotBalanceSearchSelectedCriteria.showForecast ?
          resultSum?.period3Forecast : resultSum?.period3) | number : '1.3' }} </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [filterable]="false" *ngIf="showPeriodColumn(2) && showSecondaryColumn()" title="{{'AoT.Comparison' | translate  }}"
      field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period3ForecastSecondary' : 'period3Secondary') }}">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
          [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: false, periodId: dataItem.thirdPeriodId , uniqueId: dataItem.thirdPeriodUniqueId, firstDateInPeriod: dataItem.thirdPeriodDate
                                                  , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period3ForecastSecondary : dataItem.period3Secondary), showContextMenu: true, orderType:aotOrderType.Comparison}">
        </ng-container>
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        <div class="text-right"> {{ (aotBalanceSearchSelectedCriteria.showForecast ?
          resultSum?.period3ForecastSecondary : resultSum?.period3Secondary) | number : '1.3' }}
        </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group *ngIf="showPeriodColumn(3)" title="{{ getPeriodTitle(3) }}">
    <kendo-grid-column [filterable]="false" *ngIf="showPeriodColumn(3)" title="{{ 'AoT.Main' | translate }}"
      field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period4Forecast' : 'period4') }}">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
          [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: false, periodId: dataItem.fourthPeriodId , uniqueId: dataItem.fourthPeriodUniqueId, firstDateInPeriod: dataItem.fourthPeriodDate
                                                  , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period4Forecast : dataItem.period4), showContextMenu: true, orderType:aotOrderType.Main}">
        </ng-container>
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        <div class="text-right"> {{ (aotBalanceSearchSelectedCriteria.showForecast ?
          resultSum?.period4Forecast : resultSum?.period4) | number : '1.3' }} </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [filterable]="false" *ngIf="showPeriodColumn(3) && showSecondaryColumn()" title="{{ 'AoT.Comparison' | translate  }}"
      field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period4ForecastSecondary' : 'period4Secondary') }}">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
          [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: false, periodId: dataItem.fourthPeriodId , uniqueId: dataItem.fourthPeriodUniqueId, firstDateInPeriod: dataItem.fourthPeriodDate
                                                  , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period4ForecastSecondary : dataItem.period4Secondary), showContextMenu: true, orderType:aotOrderType.Comparison}">
        </ng-container>
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        <div class="text-right"> {{ (aotBalanceSearchSelectedCriteria.showForecast ?
          resultSum?.period4ForecastSecondary : resultSum?.period4Secondary) | number : '1.3' }}
        </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group *ngIf="showPeriodColumn(4)" title="{{ getPeriodTitle(4) }}">
    <kendo-grid-column [filterable]="false" *ngIf="showPeriodColumn(4)" title="{{ 'AoT.Main' | translate }}"
      field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period5Forecast' : 'period5') }}">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
          [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: false, periodId: dataItem.fifthPeriodId , uniqueId: dataItem.fifthPeriodUniqueId, firstDateInPeriod: dataItem.fifthPeriodDate
                                                  , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period5Forecast : dataItem.period5), showContextMenu: true, orderType:aotOrderType.Main}">
        </ng-container>
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        <div class="text-right"> {{ (aotBalanceSearchSelectedCriteria.showForecast ?
          resultSum?.period5Forecast : resultSum?.period5) | number : '1.3' }} </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [filterable]="false" *ngIf="showPeriodColumn(4) && showSecondaryColumn()" title="{{ 'AoT.Comparison' | translate  }}"
      field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period5ForecastSecondary' : 'period5Secondary') }}">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
          [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: false, periodId: dataItem.fifthPeriodId , uniqueId: dataItem.fifthPeriodUniqueId, firstDateInPeriod: dataItem.fifthPeriodDate
                                                  , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period5ForecastSecondary : dataItem.period5Secondary), showContextMenu: true, orderType:aotOrderType.Comparison}">
        </ng-container>
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        <div class="text-right"> {{ (aotBalanceSearchSelectedCriteria.showForecast ?
          resultSum?.period5ForecastSecondary : resultSum?.period5Secondary) | number : '1.3' }}
        </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group *ngIf="showPeriodColumn(5)" title="{{ getPeriodTitle(5) }}">
    <kendo-grid-column [filterable]="false" *ngIf="showPeriodColumn(5)" title="{{ 'AoT.Main' | translate }}"
      field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period6Forecast' : 'period6') }}">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
          [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: false, periodId: dataItem.sixthPeriodId , uniqueId: dataItem.sixthPeriodUniqueId, firstDateInPeriod: dataItem.sixthPeriodDate
                                                  , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period6Forecast : dataItem.period6), showContextMenu: true, orderType:aotOrderType.Main }">
        </ng-container>
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        <div class="text-right"> {{ (aotBalanceSearchSelectedCriteria.showForecast ?
          resultSum?.period6Forecast : resultSum?.period6) | number : '1.3' }} </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [filterable]="false" *ngIf="showPeriodColumn(5) && showSecondaryColumn()" title="{{ 'AoT.Comparison' | translate  }}"
      field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period6ForecastSecondary' : 'period6Secondary') }}">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
          [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: false, periodId: dataItem.sixthPeriodId , uniqueId: dataItem.sixthPeriodUniqueId, firstDateInPeriod: dataItem.sixthPeriodDate
                                                  , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period6ForecastSecondary : dataItem.period6Secondary), showContextMenu: true, orderType:aotOrderType.Comparison}">
        </ng-container>
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        <div class="text-right"> {{ (aotBalanceSearchSelectedCriteria.showForecast ?
          resultSum?.period6ForecastSecondary : resultSum?.period6Secondary) | number : '1.3' }}
        </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group *ngIf="showPeriodColumn(6)" title="{{ getPeriodTitle(6) }}">
    <kendo-grid-column [filterable]="false" *ngIf="showPeriodColumn(6)" title="{{ 'AoT.Main' | translate}}"
      field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period7Forecast' : 'period7') }}">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
          [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: false, periodId: dataItem.seventhPeriodId , uniqueId: dataItem.seventhPeriodUniqueId, firstDateInPeriod: dataItem.seventhPeriodDate
                                                  , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period7Forecast : dataItem.period7), showContextMenu: true, orderType:aotOrderType.Main }">
        </ng-container>
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        <div class="text-right"> {{ (aotBalanceSearchSelectedCriteria.showForecast ?
          resultSum?.period7Forecast : resultSum?.period7) | number : '1.3' }} </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [filterable]="false" *ngIf="showPeriodColumn(6) && showSecondaryColumn()" title="{{ 'AoT.Comparison' | translate }}"
      field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period7ForecastSecondary' : 'period7Secondary') }}">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
          [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: false, periodId: dataItem.seventhPeriodId , uniqueId: dataItem.seventhPeriodUniqueId, firstDateInPeriod: dataItem.seventhPeriodDate
                                                  , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period7ForecastSecondary : dataItem.period7Secondary), showContextMenu: true, orderType:aotOrderType.Comparison}">
        </ng-container>
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        <div class="text-right"> {{ (aotBalanceSearchSelectedCriteria.showForecast ?
          resultSum?.period7ForecastSecondary : resultSum?.period7Secondary) | number : '1.3' }}
        </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group *ngIf="showPeriodColumn(7)" title="{{ getPeriodTitle(7) }}">
    <kendo-grid-column [filterable]="false" *ngIf="showPeriodColumn(7)" title="{{ 'AoT.Main' | translate }}"
      field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period8Forecast' : 'period8') }}">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
          [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: false, periodId: dataItem.eighthPeriodId , uniqueId: dataItem.eighthPeriodUniqueId, firstDateInPeriod: dataItem.eighthPeriodDate
                                                  , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period8Forecast : dataItem.period8), showContextMenu: true, orderType:aotOrderType.Main }">
        </ng-container>
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        <div class="text-right"> {{ (aotBalanceSearchSelectedCriteria.showForecast ?
          resultSum?.period8Forecast : resultSum?.period8) | number : '1.3' }} </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [filterable]="false" *ngIf="showPeriodColumn(7) && showSecondaryColumn()" title="{{  'AoT.Comparison' | translate  }}"
      field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period8ForecastSecondary' : 'period8Secondary') }}">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
          [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: false, periodId: dataItem.eighthPeriodId , uniqueId: dataItem.eighthPeriodUniqueId, firstDateInPeriod: dataItem.eighthPeriodDate
                                                  , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period8ForecastSecondary : dataItem.period8Secondary), showContextMenu: true, orderType:aotOrderType.Comparison }">
        </ng-container>
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        <div class="text-right"> {{ (aotBalanceSearchSelectedCriteria.showForecast ?
          resultSum?.period8ForecastSecondary : resultSum?.period8Secondary) | number : '1.3' }}
        </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group *ngIf="showPeriodColumn(8)" title="{{ getPeriodTitle(8) }}">
    <kendo-grid-column [filterable]="false" *ngIf="showPeriodColumn(8)" title="{{ 'AoT.Main' | translate }}"
      field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period9Forecast' : 'period9') }}">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
          [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: false, periodId: dataItem.ninthPeriodId , uniqueId: dataItem.ninthPeriodUniqueId, firstDateInPeriod: dataItem.ninthPeriodDate
                                                  , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period9Forecast : dataItem.period9), showContextMenu: true, orderType:aotOrderType.Main }">
        </ng-container>
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        <div class="text-right"> {{ (aotBalanceSearchSelectedCriteria.showForecast ?
          resultSum?.period9Forecast : resultSum?.period9) | number : '1.3' }} </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [filterable]="false" *ngIf="showPeriodColumn(8) && showSecondaryColumn()" title="{{ 'AoT.Comparison' | translate }}"
      field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period9ForecastSecondary' : 'period9Secondary') }}">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
          [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: false, periodId: dataItem.ninthPeriodId , uniqueId: dataItem.ninthPeriodUniqueId, firstDateInPeriod: dataItem.ninthPeriodDate
                                                  , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period9ForecastSecondary : dataItem.period9Secondary), showContextMenu: true, orderType:aotOrderType.Comparison }">
        </ng-container>
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        <div class="text-right"> {{ (aotBalanceSearchSelectedCriteria.showForecast ?
          resultSum?.period9ForecastSecondary : resultSum?.period9Secondary) | number : '1.3' }}
        </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group *ngIf="showPeriodColumn(9)" title="{{ getPeriodTitle(9) }}">
    <kendo-grid-column [filterable]="false" *ngIf="showPeriodColumn(9)" title="{{ 'AoT.Main' | translate }}"
      field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period10Forecast' : 'period10') }}">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
          [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: false, periodId: dataItem.tenthPeriodId , uniqueId: dataItem.tenthPeriodUniqueId, firstDateInPeriod: dataItem.tenthPeriodDate
                                                  , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period10Forecast : dataItem.period10), showContextMenu: true, orderType:aotOrderType.Main }">
        </ng-container>
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        <div class="text-right"> {{ (aotBalanceSearchSelectedCriteria.showForecast ?
          resultSum?.period10Forecast : resultSum?.period10) | number : '1.3' }} </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [filterable]="false" *ngIf="showPeriodColumn(9) && showSecondaryColumn()" title="{{ 'AoT.Comparison' | translate  }}"
      field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period10ForecastSecondary' : 'period10Secondary') }}">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
          [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: false, periodId: dataItem.tenthPeriodId , uniqueId: dataItem.tenthPeriodUniqueId, firstDateInPeriod: dataItem.tenthPeriodDate
                                                  , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period10ForecastSecondary : dataItem.period10Secondary), showContextMenu: true, orderType:aotOrderType.Comparison}">
        </ng-container>
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        <div class="text-right"> {{ (aotBalanceSearchSelectedCriteria.showForecast ?
          resultSum?.period10ForecastSecondary : resultSum?.period10Secondary) | number : '1.3' }}
        </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <kendo-grid-column-group title="{{ 'AoT.Beyond' | translate }}">
    <kendo-grid-column [filterable]="false"
      field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'beyondForecast' : 'beyond') }}"
      title="{{ 'AoT.Main' | translate }}">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
          [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: false, periodId: periods[currentPeriodCount-1].periodId , uniqueId: dataItem.firstPeriodUniqueId, firstDateInPeriod: dataItem.firstPeriodDate
                                                  , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.beyondForecast : dataItem.beyond), colomName: 'beyond', showContextMenu: true, orderType:aotOrderType.Main }">
        </ng-container>
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        <div class="text-right"> {{ (aotBalanceSearchSelectedCriteria.showForecast ? resultSum?.beyondForecast :
          resultSum?.beyond) | number : '1.3' }} </div>
      </ng-template>
    </kendo-grid-column>

    <kendo-grid-column [filterable]="false" *ngIf="showSecondaryColumn()"
      field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'beyondForecastSecondary' : 'beyondSecondary') }}"
      title="{{ 'AoT.Comparison' | translate }}">
      <ng-template
        kendoGridFilterCellTemplate
        let-filter
        let-column="column">
        <kendo-grid-string-filter-cell
            [showOperators]="false"
            [column]="column"
            [filter]="filter">
        </kendo-grid-string-filter-cell>
      </ng-template>
      <ng-template kendoGridCellTemplate let-dataItem>
        <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
          [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: false, periodId: periods[currentPeriodCount-1].periodId , uniqueId: dataItem.firstPeriodUniqueId, firstDateInPeriod: dataItem.firstPeriodDate
                                                  , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.beyondForecastSecondary : dataItem.beyondSecondary), colomName: 'beyond', showContextMenu: true, orderType:aotOrderType.Comparison }">
        </ng-container>
      </ng-template>
      <ng-template kendoGridFooterTemplate>
        <div class="text-right"> {{ (aotBalanceSearchSelectedCriteria.showForecast ?
          resultSum?.beyondForecastSecondary : resultSum?.beyondSecondary) | number : '1.3' }} </div>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid-column-group>

  <ng-template kendoGridDetailTemplate let-dataItem *ngIf="aotBalanceSearchSelectedCriteria?.group !== 0">

    <kendo-grid [data]="dataItem.childGrid" [loading]="(dataItem.childGrid === undefined)" [hideHeader]="true" [height]="650">

      <kendo-grid-column field="groupingName" title="{{ 'AoT.Grouping' | translate }}">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container [ngTemplateOutlet]="renderGroupTemplate"
            [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: true }">
          </ng-container>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column field="inStock" title="{{ 'AoT.Stock' | translate }}">
        <ng-template kendoGridCellTemplate let-dataItem>
          <ng-container [ngTemplateOutlet]="renderValueTemplate"
            [ngTemplateOutletContext]="{ $implicit: dataItem, value: dataItem.inStock, isChild: true }">
          </ng-container>
        </ng-template>
      </kendo-grid-column>

      <kendo-grid-column-group  title="{{ 'AoT.Overdue' | translate }}">
        <kendo-grid-column title="{{  'AoT.Main' | translate}}"
        field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'overdueForecast' : 'overdue') }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
                          [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: true, periodId: periods[0].periodId , uniqueId: dataItem.firstPeriodUniqueId, firstDateInPeriod: dataItem.firstPeriodDate
                                                         , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.overdueForecast : dataItem.overdue)
                                                         , colomName: 'overdue', showContextMenu: true, orderType:aotOrderType.Main  }">
          </ng-container>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column *ngIf="showSecondaryColumn()" title="{{ 'AoT.Comparison' | translate }}"
        field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'overdueForecastSecondary' : 'overdueSecondary') }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
                          [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: true, periodId: periods[0].periodId , uniqueId: dataItem.firstPeriodUniqueId, firstDateInPeriod: dataItem.firstPeriodDate
                                                      , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.overdueForecastSecondary : dataItem.overdueSecondary)
                                                      , colomName: 'overdue', showContextMenu: true, orderType:aotOrderType.Comparison }">
            </ng-container>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>

      <kendo-grid-column-group *ngIf="showPeriodColumn(0)" title="{{ getPeriodTitle(0) }}">
        <kendo-grid-column *ngIf="showPeriodColumn(0)" title="{{ 'AoT.Main' | translate }}"
          field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period1Forecast' : 'period1') }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
              [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: true, periodId: dataItem.firstPeriodId , uniqueId: dataItem.firstPeriodUniqueId, firstDateInPeriod: dataItem.firstPeriodDate
                                                        , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period1Forecast : dataItem.period1), showContextMenu: true, orderType:aotOrderType.Main }">
            </ng-container>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column *ngIf="showPeriodColumn(0) && showSecondaryColumn()" title="{{'AoT.Comparison' | translate }}"
          field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period1ForecastSecondary' : 'period1Secondary') }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
              [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: true, periodId: dataItem.firstPeriodId , uniqueId: dataItem.firstPeriodUniqueId, firstDateInPeriod: dataItem.firstPeriodDate
                                                        , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period1ForecastSecondary : dataItem.period1Secondary), showContextMenu: true , orderType:aotOrderType.Comparison}">
            </ng-container>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>

      <kendo-grid-column-group *ngIf="showPeriodColumn(1)" title="{{ getPeriodTitle(1) }}">
        <kendo-grid-column *ngIf="showPeriodColumn(1)" title="{{ 'AoT.Main' | translate }}"
          field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period2Forecast' : 'period2') }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
              [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: true, periodId: dataItem.secondPeriodId, uniqueId: dataItem.secondPeriodUniqueId, firstDateInPeriod: dataItem.secondPeriodDate
                                                        , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period2Forecast : dataItem.period2), showContextMenu: true, orderType:aotOrderType.Main }">
            </ng-container>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column *ngIf="showPeriodColumn(1) && showSecondaryColumn()" title="{{ 'AoT.Comparison' | translate  }}"
          field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period2ForecastSecondary' : 'period2Secondary') }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
              [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: true, periodId: dataItem.secondPeriodId, uniqueId: dataItem.secondPeriodUniqueId, firstDateInPeriod: dataItem.secondPeriodDate
                                                        , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period2ForecastSecondary : dataItem.period2Secondary), showContextMenu: true, orderType:aotOrderType.Comparison }">
            </ng-container>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>

      <kendo-grid-column-group *ngIf="showPeriodColumn(2)" title="{{ getPeriodTitle(2) }}">
        <kendo-grid-column *ngIf="showPeriodColumn(2)" title="{{'AoT.Main' | translate }}"
          field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period3Forecast' : 'period3') }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
              [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: true, periodId: dataItem.thirdPeriodId , uniqueId: dataItem.thirdPeriodUniqueId, firstDateInPeriod: dataItem.thirdPeriodDate
                                                        , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period3Forecast : dataItem.period3), showContextMenu: true , orderType:aotOrderType.Main}">
            </ng-container>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column *ngIf="showPeriodColumn(2) && showSecondaryColumn()" title="{{ 'AoT.Comparison' | translate }}"
          field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period3ForecastSecondary' : 'period3Secondary') }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
              [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: true, periodId: dataItem.thirdPeriodId , uniqueId: dataItem.thirdPeriodUniqueId, firstDateInPeriod: dataItem.thirdPeriodDate
                                                        , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period3ForecastSecondary : dataItem.period3Secondary), showContextMenu: true, orderType:aotOrderType.Comparison}">
            </ng-container>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>

      <kendo-grid-column-group *ngIf="showPeriodColumn(3)" title="{{ getPeriodTitle(3) }}">
        <kendo-grid-column *ngIf="showPeriodColumn(3)" title="{{ 'AoT.Main' | translate }}"
          field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period4Forecast' : 'period4') }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
              [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: true, periodId: dataItem.fourthPeriodId , uniqueId: dataItem.fourthPeriodUniqueId, firstDateInPeriod: dataItem.fourthPeriodDate
                                                        , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period4Forecast : dataItem.period4), showContextMenu: true, orderType:aotOrderType.Main }">
            </ng-container>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column *ngIf="showPeriodColumn(3) && showSecondaryColumn()" title="{{ 'AoT.Comparison' | translate  }}"
          field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period4ForecastSecondary' : 'period4Secondary') }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
              [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: true, periodId: dataItem.fourthPeriodId , uniqueId: dataItem.fourthPeriodUniqueId, firstDateInPeriod: dataItem.fourthPeriodDate
                                                        , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period4ForecastSecondary : dataItem.period4Secondary), showContextMenu: true, orderType:aotOrderType.Comparison}">
            </ng-container>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>

      <kendo-grid-column-group *ngIf="showPeriodColumn(4)" title="{{ getPeriodTitle(4) }}">
        <kendo-grid-column *ngIf="showPeriodColumn(4)" title="{{ 'AoT.Main' | translate }}"
          field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period5Forecast' : 'period5') }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
              [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: true, periodId: dataItem.fifthPeriodId , uniqueId: dataItem.fifthPeriodUniqueId, firstDateInPeriod: dataItem.fifthPeriodDate
                                                        , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period5Forecast : dataItem.period5), showContextMenu: true, orderType:aotOrderType.Main }">
            </ng-container>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column *ngIf="showPeriodColumn(4) && showSecondaryColumn()" title="{{ 'AoT.Comparison' | translate }}"
          field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period5ForecastSecondary' : 'period5Secondary') }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
              [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: true, periodId: dataItem.fifthPeriodId , uniqueId: dataItem.fifthPeriodUniqueId, firstDateInPeriod: dataItem.fifthPeriodDate
                                                        , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period5ForecastSecondary : dataItem.period5Secondary), showContextMenu: true, orderType:aotOrderType.Comparison}">
            </ng-container>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>

      <kendo-grid-column-group *ngIf="showPeriodColumn(5)" title="{{ getPeriodTitle(5) }}">
        <kendo-grid-column *ngIf="showPeriodColumn(5)" title="{{ 'AoT.Main' | translate }}"
          field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period6Forecast' : 'period6') }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
              [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: true, periodId: dataItem.sixthPeriodId , uniqueId: dataItem.sixthPeriodUniqueId, firstDateInPeriod: dataItem.sixthPeriodDate
                                                        , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period6Forecast : dataItem.period6), showContextMenu: true, orderType:aotOrderType.Main }">
            </ng-container>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column *ngIf="showPeriodColumn(5) && showSecondaryColumn()" title="{{ 'AoT.Comparison' | translate }}"
          field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period6ForecastSecondary' : 'period6Secondary') }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
              [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: true, periodId: dataItem.sixthPeriodId , uniqueId: dataItem.sixthPeriodUniqueId, firstDateInPeriod: dataItem.sixthPeriodDate
                                                        , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period6Forecast : dataItem.period6), showContextMenu: true, orderType:aotOrderType.Comparison}">
            </ng-container>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>

      <kendo-grid-column-group *ngIf="showPeriodColumn(6)" title="{{ getPeriodTitle(6) }}">
        <kendo-grid-column *ngIf="showPeriodColumn(6)" title="{{ 'AoT.Main' | translate }}"
          field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period7Forecast' : 'period7') }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
              [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: true, periodId: dataItem.seventhPeriodId , uniqueId: dataItem.seventhPeriodUniqueId, firstDateInPeriod: dataItem.seventhPeriodDate
                                                        , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period7Forecast : dataItem.period7), showContextMenu: true, orderType:aotOrderType.Main }">
            </ng-container>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column *ngIf="showPeriodColumn(6) && showSecondaryColumn()" title="{{ 'AoT.Comparison' | translate  }}"
          field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period7ForecastSecondary' : 'period7Secondary') }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
              [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: true, periodId: dataItem.seventhPeriodId , uniqueId: dataItem.seventhPeriodUniqueId, firstDateInPeriod: dataItem.seventhPeriodDate
                                                        , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period7ForecastSecondary : dataItem.period7Secondary), showContextMenu: true, orderType:aotOrderType.Comparison}">
            </ng-container>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>

      <kendo-grid-column-group *ngIf="showPeriodColumn(7)" title="{{ getPeriodTitle(7) }}">
        <kendo-grid-column *ngIf="showPeriodColumn(7)" title="{{ 'AoT.Main' | translate }}"
          field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period8Forecast' : 'period8') }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
              [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: true, periodId: dataItem.eighthPeriodId , uniqueId: dataItem.eighthPeriodUniqueId, firstDateInPeriod: dataItem.eighthPeriodDate
                                                        , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period8Forecast : dataItem.period8), showContextMenu: true, orderType:aotOrderType.Main }">
            </ng-container>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column *ngIf="showPeriodColumn(7) && showSecondaryColumn()" title="{{'AoT.Comparison' | translate}}"
          field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period8ForecastSecondary' : 'period8Secondary') }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
              [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: true, periodId: dataItem.eighthPeriodId , uniqueId: dataItem.eighthPeriodUniqueId, firstDateInPeriod: dataItem.eighthPeriodDate
                                                        , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period8ForecastSecondary : dataItem.period8Secondary), showContextMenu: true, orderType:aotOrderType.Comparison}">
            </ng-container>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>

      <kendo-grid-column-group *ngIf="showPeriodColumn(8)" title="{{ getPeriodTitle(8) }}">
        <kendo-grid-column *ngIf="showPeriodColumn(8)" title="{{ 'AoT.Main' | translate }}"
          field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period9Forecast' : 'period9') }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
              [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: true, periodId: dataItem.ninthPeriodId , uniqueId: dataItem.ninthPeriodUniqueId, firstDateInPeriod: dataItem.ninthPeriodDate
                                                        , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period9Forecast : dataItem.period9), showContextMenu: true, orderType:aotOrderType.Main}">
            </ng-container>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column *ngIf="showPeriodColumn(8) && showSecondaryColumn()" title="{{'AoT.Comparison' | translate }}"
          field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period9ForecastSecondary' : 'period9Secondary') }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
              [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: true, periodId: dataItem.ninthPeriodId , uniqueId: dataItem.ninthPeriodUniqueId, firstDateInPeriod: dataItem.ninthPeriodDate
                                                        , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period9ForecastSecondary : dataItem.period9Secondary), showContextMenu: true, orderType:aotOrderType.Comparison}">
            </ng-container>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>

      <kendo-grid-column-group *ngIf="showPeriodColumn(9)" title="{{ getPeriodTitle(9) }}">
        <kendo-grid-column *ngIf="showPeriodColumn(9)" title="{{ 'AoT.Main' | translate }}"
          field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period10Forecast' : 'period10') }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
              [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: true, periodId: dataItem.tenthPeriodId , uniqueId: dataItem.tenthPeriodUniqueId, firstDateInPeriod: dataItem.tenthPeriodDate
                                                        , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period10Forecast : dataItem.period10), showContextMenu: true, orderType:aotOrderType.Main }">
            </ng-container>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column *ngIf="showPeriodColumn(9) && showSecondaryColumn()" title="{{ 'AoT.Comparison' | translate }}"
          field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'period10ForecastSecondary' : 'period10Secondary') }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
              [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: true, periodId: dataItem.tenthPeriodId , uniqueId: dataItem.tenthPeriodUniqueId, firstDateInPeriod: dataItem.tenthPeriodDate
                                                        , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.period10ForecastSecondary : dataItem.period10Secondary), showContextMenu: true, orderType:aotOrderType.Comparison }">
            </ng-container>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>

      <kendo-grid-column-group title="{{ 'AoT.Beyond' | translate }}">
        <kendo-grid-column
          field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'beyondForecast' : 'beyond') }}"
          title="{{ 'AoT.Main' | translate}}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
              [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: true, periodId: periods[currentPeriodCount-1].periodId , uniqueId: dataItem.firstPeriodUniqueId, firstDateInPeriod: dataItem.firstPeriodDate
                                                         , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.beyondForecast : dataItem.beyond), colomName: 'beyond', showContextMenu: true, orderType:aotOrderType.Main }">
            </ng-container>
          </ng-template>
        </kendo-grid-column>

        <kendo-grid-column *ngIf="showSecondaryColumn()"
          field="{{ (aotBalanceSearchSelectedCriteria.showForecast ? 'beyondForecastSecondary' : 'beyondSecondary') }}"
          title="{{  'AoT.Comparison' | translate  }}">
          <ng-template kendoGridCellTemplate let-dataItem>
            <ng-container [ngTemplateOutlet]="itemPeriodColumnTemplate"
              [ngTemplateOutletContext]="{ $implicit: dataItem, isChild: true, periodId: periods[currentPeriodCount-1].periodId , uniqueId: dataItem.firstPeriodUniqueId, firstDateInPeriod: dataItem.firstPeriodDate
                                                         , value: (aotBalanceSearchSelectedCriteria.showForecast ? dataItem.beyondForecastSecondary : dataItem.beyondSecondary), colomName: 'beyond', showContextMenu: true, orderType:aotOrderType.Comparison }">
            </ng-container>
          </ng-template>
        </kendo-grid-column>
      </kendo-grid-column-group>
    </kendo-grid>
  </ng-template>

</kendo-grid>


<ng-template #renderGroupTemplate let-dataItem let-isChild="isChild">
  <div kendoTooltip>
    <span title="{{
                    ((aotBalanceSearchSelectedCriteria.group === aoTGrouping.Material && !isChild)
                        || (aotBalanceSearchSelectedCriteria.group !== aoTGrouping.Material && isChild
                     ))
                     ? (('Min Stock: ' + (dataItem.targetMinStock ?? '-')) +  ('  Max Stock: ' + (dataItem.targetMaxStock ?? '-' )))
                     : ''
                  }}">
      {{dataItem.groupingLable}}
    </span>
  </div>
</ng-template>


<ng-template #itemPeriodColumnTemplate
  let-dataItem let-isChild="isChild"
  let-periodId="periodId"
  let-uniqueId="uniqueId"
  let-value="value"
  let-firstDateInPeriod="firstDateInPeriod"
  let-colomName="colomName"
  let-showContextMenu="showContextMenu"
  let-orderType="orderType">
  <div id="{{ orderType.toString() + '_' + isChild.toString()  + '_' + uniqueId  + '_' + getColomName(colomName) + '_' + (dataItem?.parentGroupText ?? '')}}"
    (click)="getAoTBalanceDetail(dataItem, periodId, uniqueId, getColomName(colomName), orderType, isChild);"
    [ngClass]="{'selected-cell': (selectedCellUniqueId ===  (orderType.toString() + '_' + isChild.toString()  + '_' + uniqueId  + '_' + getColomName(colomName) + '_' + (dataItem?.parentGroupText ?? '')))}"
    #target>
    <ng-container [ngTemplateOutlet]="renderValueTemplate"
      [ngTemplateOutletContext]="{ $implicit: dataItem, value: value , isChild: isChild }">
    </ng-container>
  </div>
  <app-aot-grid-context-menu
    *ngIf="showContextMenu"
    [target]="target"
    [entityIndex]="dataItem.materialIndex"
    [startDateTime]="firstDateInPeriod"
    [quantity]="value"
    [unitCode]="aotBalanceSearchSelectedCriteria.unitCode"
    [siteId]="aotBalanceSearchSelectedCriteria.siteId"
    (onRefreshRequested)="onProdOrderCreation($event)"
  >
  </app-aot-grid-context-menu>
</ng-template>


<ng-template #renderValueTemplate let-dataItem let-value="value" let-isChild="isChild">
  <div class="text-right" [style.color]="((aotBalanceSearchSelectedCriteria.group === aoTGrouping.Material && !isChild) || (aotBalanceSearchSelectedCriteria.group !== aoTGrouping.Material && isChild))
                        ? getColor(value) : 'black'">
       <kendo-svg-icon *ngIf="value != null && isAboveMax(dataItem, value) && aotBalanceSearchSelectedCriteria.stockLimits" [icon]="arrowUpIcon" themeColor="success"></kendo-svg-icon>
       <kendo-svg-icon *ngIf="value != null && isBelowMin(dataItem, value) && aotBalanceSearchSelectedCriteria.stockLimits" [icon]="arrowDownIcon" themeColor="error"></kendo-svg-icon>
     {{ value == null ? 'N/A' : value | number : '1.3'}}
  </div>
</ng-template>
