<div>
  <button kendoButton #anchor (click)="onToggle()" [disabled]="isInstructionsCount">
    {{"AoT.Instructions" | translate}} ({{instructionsCount}})
  </button>
</div>
<kendo-popup [anchor]="anchor" (anchorViewportLeave)="showPopup = false" *ngIf="showPopup">
      <div class="content main_table" style="width: 600px;">
      <div style="display: flex; flex-direction: column;">
        <table class="tbl" style="width: 100%;">
          <tbody>
            <tr>
              <th><b>{{"App.Name" | translate}}</b></th>
              <th><b>{{"GluePlanPrint.Value" | translate}}</b></th>
            </tr>
            <tr *ngFor="let instruction of _instructions | keyvalue">
              <td class="tb-pad" >{{instruction.key}}</td>
              <td class="tb-pad" >{{instruction.value}}</td>
            </tr>
          </tbody>
        </table>
      </div>
      </div>
</kendo-popup>
